.stars__rating-starts {
  --percent: calc(var(--rating) / 5 * 100%);
  --defaultColor: var(--defaultColor);

  display: inline-block;
  font-family: Times;
  line-height: 1;
}

.stars__rating-starts::before {
  content: "\f005\f005\f005\f005\f005";
  font: normal normal normal 14px/1 FontAwesome;
  letter-spacing: 3px;
  background: linear-gradient(
    90deg,
    #089bac var(--percent),
    var(--defaultColor) var(--percent)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.5rem;
}

body[dir="rtl"] .stars__rating-starts {
  transform: scaleX(-1);
}

/* 
.stars__rating-starts_booth {
  --percent: calc(var(--rating) / 5 * 100%);

  display: inline-block;
  font-family: Times;
  line-height: 1;
  border: #fff 1px solid;
}

.stars__rating-starts_booth::before {
  content: "\f005\f005\f005\f005\f005";
  font: normal normal normal 14px/1 FontAwesome;
  letter-spacing: 3px;
  background: linear-gradient(
    90deg,
    #ffffff var(--percent),
    transparent var(--percent)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.5rem;
} */
