.tax-list {
  padding: 2rem 0;
}

.tax-list__container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.tax-list__table-name {
  width: 150px;
  white-space: pre-wrap;
}
.note_table {
  white-space: pre-line;
}
