.card {
  background-color: #fff;
  border-radius: 25px;
  gap: 2rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  display: flex;
  overflow: hidden;
  min-height: 450px;
  cursor: pointer;
}
body[dir="rtl"] .card {
  direction: rtl;
}

.header-content,
.bottom-content {
  display: flex;

  flex-direction: column;
  gap: 12px;
}
.title_cat_dep {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.bottom-content {
  padding-top: 0;
  min-height: 150px;
}
.img-container {
  position: relative;
  width: 100%;
  height: 250px;
  border-radius: 15px 15px 0 0;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
}

.img-badge {
  position: absolute;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
}
.self_pacedBadge {
  background-color: #03a2b2;
  color: #fff;
  font-size: 10px;
  padding: 7px 10px;
  border-radius: 8px;
}
.lang_level {
  display: flex;
  gap: 10px;
}
.lang {
  padding: 8px 15px;
  border: 1px solid #cecece;
  border-radius: 8px;
  font-size: 13px;
  color: #979797;
}
.lang span {
  color: #03a2b2;
}
.level {
  padding: 8px 15px;
  border: 1px solid #cecece;
  border-radius: 8px;
  font-size: 13px;
  color: #979797;
}
.level span {
  color: #13b729;
}
.offer-badge {
  position: absolute;
  top: 5rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 10px 10px;
  background-color: #df4b43;
  color: #fff;
  border-radius: 20px;
}
body[dir="rtl"] .offer-badge {
  left: 18px;
}
body[dir="ltr"] .offer-badge {
  right: 18px;
}

.offer-badge-number {
  font-size: 12px;
  font-weight: bold;
}

.offer-badge-off {
  font-size: 1.2rem;
  font-weight: bold;
}

.img {
  width: 100%;
  height: 100%;
  /* border-radius: 15px; */
}

.title-price-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 5px;
  /* border-bottom: 1px solid #e9e7e7; */
  /* padding-bottom: 15px; */
}

.desc-container {
  /* margin-top: 5px; */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  min-height: 42px;
}

/* desc-container and all its children */
.desc-container,
.desc-container > *,
.desc-container > * > * {
  color: #acabab !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.price-container {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
  background-color: #fcfcfc;
  border: 1px solid #f2f2f2;
  padding: 6px 15px 0px;
  border-radius: 15px;
  max-width: 100%;
}

.price-number {
  /* line-height: 0.8; */
  font-size: 14px;
}

.old-price {
  color: #777777;
  font-size: 14px;
  text-decoration: line-through;
}

.box-w {
  max-width: 70%;
}

.price-label {
  color: #012327;
  font-size: 10px;
  /* text-align: center; */
}

.price {
  padding: 8px 14px;
  border-radius: 12px;
  border: 1px solid;
  /* font-size: 13px; */
  font-weight: 600;
}
.price span {
  font-size: 8px;
}
.freeMode {
  border-color: #ee5353;
  color: #ee5353;
  font-size: 13px;
}

.cur {
  font-size: 8px !important;
  color: #acabab;
}

.date-badge {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.details_price {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #cecece;
  padding-top: 10px;
}
.date-seat-hour {
  margin-top: auto;
  display: flex;
  gap: 10px;
  font-size: 12px;
  align-items: center;

  margin: 5px 0;
}

.date-seat-hour span {
  color: #858585;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.badge-style {
  border-radius: 6px !important;
  padding-top: 5px;
  padding-bottom: 8px;
  height: fit-content;
}

.badge-font {
  font-size: 12px;
}

.star-font-size::before {
  font-size: 1rem !important;
}

.flip-star {
  transform: rotateY(180deg);
}

.user-container {
  display: flex;
  gap: 2rem;
  align-items: center;
}
.trainers-name {
  font-size: 13px;
  font-weight: 500;
  color: #012327;
}
.trainers-tag {
  font-size: 12px;
  color: #acabab;
}
.user {
  display: flex;
  align-items: center;
  gap: 10px;
}

.user-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-inline-end: -20px;
  border: 1px solid #e4e2e2;
}

.user-img-more {
  width: 40px;
  height: 40px;
  font-size: 14px;
  color: #777777;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.badge-black {
  color: #8599b6 !important;
  background-color: #8599b638 !important;
}

.badge-yellow {
  color: #c28d51 !important;
  background-color: #c28d5138 !important;
}

.badge-green {
  color: #24b951 !important;
  background-color: #24b95138 !important;
}

.iconscontainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  color: #6d6d6d;
}

.progress-container {
  display: flex;
  align-items: center;
  gap: 0.4rem;
}
.progress_icon {
  padding: 10px;
  border-radius: 50%;
  border: 1px solid #cecece;
}
.progress_icon img {
  width: 20px;
}
.progress_title {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  /* font-weight: 600; */
  color: #979797;
}
.progress_bar_title {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.progress {
  display: flex;
  width: 100%;
  /* margin: 10px auto; */
  height: 7px;
  border-radius: 6px;
  border-width: 1px;
  border-color: #e4e2e2;
}

.progress-bar {
  border-radius: 6px;
  background-color: #1dde50;
}

.watch-progress-bar {
  border-radius: 6px;
  background-color: #dede1e;
}

@media (max-width: 767px) {
  .card {
    max-width: 380px;
    width: 340px;
  }

  .date-badge {
    flex-direction: column;
    gap: 20px;
  }
}
