.tracks {
  margin: 30px 0;
}

.head_box {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
}

.head_lable {
  font-size: 22px;
}
.head_view {
  font-size: 16px;
  display: flex;
}

.arrow_seeall {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}

body[dir="rtl"] .arrow_seeall {
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  margin-top: -2px;
}

.arrow_icon {
  width: 8px;
  margin: 3px 10px 0;
}

.tracks_grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
}

.tracks_card {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #efefef;
  flex-direction: column;
  padding: 50px 20px;
  border-radius: 10px;
}

.tracks_icon {
  font-size: 18px;
  width: 50px;
  margin-bottom: 5px;
  color: #000;
  opacity: 38%;
}

.tracks_section_name {
  font-size: 18px;
}

.tracks_count {
  font-size: 16px;
  color: #000;
  opacity: 38%;
}

@media (max-width: 1199px) {
  .head_lable {
    font-size: 18px;
  }
  .head_view {
    font-size: 14px;
  }
  .tracks_card {
    padding: 40px 15px;
  }
  .tracks_icon {
    width: 30px;
  }
  .tracks_section_name {
    font-size: 18px;
  }
  .tracks_count {
    font-size: 14px;
  }
}

@media (max-width: 991px) {
  .head_lable {
    font-size: 16px;
  }
  .tracks_card {
    padding: 40px 15px;
  }
  .tracks_icon {
    font-size: 14px;
  }
  .tracks_section_name {
    font-size: 14px;
  }
  .tracks_count {
    font-size: 12px;
  }
}

@media (max-width: 767px) {
  .tracks_grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
    gap: 30px;
  }
  .tracks_card {
    padding: 40px 15px;
  }
  .tracks_icon {
    font-size: 12px;
  }
  .tracks_section_name {
    font-size: 12px;
  }
}
