.react-datepicker__navigation--years {
  background: none !important;
  line-height: 1.7rem !important;
  text-align: center !important;
  cursor: pointer !important;
  padding: 0 !important;
  border: 0.45rem solid transparent !important;
  z-index: 1 !important;
  height: 10px !important;
  width: 10px !important;
  text-indent: -999em !important;
  overflow: hidden !important;
  position: relative !important;
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.react-datepicker__navigation--years.react-datepicker__navigation--years-upcoming {
  top: -4px !important;
  border-bottom-color: #ccc !important;
}

.react-datepicker__navigation--years.react-datepicker__navigation--years-previous {
  top: 4px !important;
  border-top-color: #ccc !important;
}
