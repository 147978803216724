.privateChatPopup {
  position: absolute;
  top: 10rem;
  left: 50%;
  transform: translateX(-50%);
  width: 500px;
  background-color: #fff;
  border: 1px solid #eaeaea;
  border-radius: 1.5rem;
  z-index: 100;
}
@media (max-width: 500px) {
  .privateChatPopup {
    width: 350px;
  }
}

.privateChatPopupContent {
  padding: 1.5rem 1.5rem 0 1.5rem;
}

.privateChatPopupHeader {
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #1cacb7;
  color: #fff;
  border-radius: 1.2rem 1.2rem 0 0;
}

.closeIcon {
  cursor: pointer;
  width: 2rem;
  height: 2rem;
}

.closeIcon img {
  width: 100%;
  height: 100%;
}

/* change the options style */

.custom-select {
  width: 100%;
  margin-bottom: 1rem;
  position: relative;
  cursor: pointer;
  border: 1px solid #eaeaea;
  /* border-radius: 0.5rem 0.5rem 0 0; */
  padding: 0.7rem 1rem;
}

.custom-select__selected {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* arrow */
.custom-select__selected::after {
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #000;
  margin-left: 0.5rem;
}

.custom-select__list {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  border: 1px solid #eaeaea;
  border-radius: 0 0 0.5rem 0.5rem;
  z-index: 100;
  display: none;
}

.custom-select__list.open {
  display: block;
}

.custom-select__list li {
  padding: 0.7rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.custom-select__list li:hover {
  background-color: #f5f5f5;
}

.new-message {
  width: 0.5rem;
  height: 0.5rem;
  background-color: #1cacb7;
  border-radius: 50%;
}
