.container-fluid {
  max-width: 1280px;
  padding: 0 20px;
}
/* .container-fluid {
  flex-direction: column;
  width: 100%;
  display: none;
} */

.inputfile {
  /* visibility: hidden etc. wont work */
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.inputfile:focus + label {
  /* keyboard navigation */
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}

.inputfile + label * {
  pointer-events: none;
}

.labelFile {
  padding: 1% 3% 1% 3%;
  background-color: white;
  border: 2px solid #006d77;
  border-radius: 10px;
  color: #006d77;
  font-weight: bold;
  font-size: 15px;
  cursor: pointer;
}

.container_buttons {
  display: flex;
  justify-content: space-around;
  width: 50%;
}

@media (max-width: 768px) {
  .container-fluid {
    padding: 0 15px;
  }

  .links {
    font-size: 13px !important;
    height: 50px !important;
    margin-top: 10px;
  }
  .profile_click_here {
    font-size: 14px;
  }
}
@media (max-width: 991px) {
  .links {
    font-size: 14px;
  }
}
/* .profiles {
  border: none;
  height: 3px;
  color: #ccc;
  background-color: #ccc;
  text-align: left;
  margin-top: -23px;
} */

.profile_click_here {
  font-size: 16px;
}

/* .nav.nav-tabs.profile {
  justify-content: center;
  align-items: center;
} */

.profile {
  margin: 20px 0;
  display: flex;
}

/* .profiles {
  border: none;
  height: 3px;
  color: #ccc;
  background-color: #ccc;
  text-align: left;
  margin-top: -23px;
} */

.pri_profile {
  color: #555 !important;
}

.pri_profile a {
  color: #555 !important;
}

.links {
  display: flex;
  background-color: transparent;
}

.links.active {
  padding-bottom: 15px;
  border-bottom: 3px solid #18b1b9;
}

.margin_tabs {
  padding: 0 20px;
}

.profile_click_here {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 0 20px;
  width: 100%;
  padding: 20px;
  background-color: #e9e9e9;
  border-radius: 15px;
}

.click_here {
  padding: 0 5px;
  color: #006d77;
}

.card-body {
  padding: 0;
}

.overview_add_top {
  font-size: 30px;
}

@media (max-width: 1199px) {
  .overview_add_top {
    font-size: 25px;
  }
}

@media (max-width: 991px) {
  .overview_add_top {
    font-size: 18px;
  }
}

@media (max-width: 767px) {
  .overview_add_top {
    font-size: 16px;
  }
}
