.breadcrumbs {
  padding-top: 30px;
  padding-bottom: 30px;
}

.chat_list {
  padding: 2rem 0;
}

.chat_list__container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.chat_list__actions {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.chat_list__actions-btn {
  background: none;
  border: none;
  padding: 0;
}

.error {
  font-size: 13;
  font-weight: bold;
  display: block;
  padding-bottom: 10px;
}

.textarea {
  width: 100%;
  border: 1px solid #d3d3d3;
  padding: 10px;
  border-radius: 4px;
}

.divWrapText {
  width: 100%;
}

.wrapText {
  white-space: break-spaces;
}

.chat_list__actions-btn[disabled] {
  opacity: 0.3;
  cursor: not-allowed;
}

.chat-list__table-name {
  width: 150px;
  white-space: pre-wrap;
}

.m-40 {
  margin: 40px 0;
}

.m-50 {
  margin-bottom: 50px;
}

.d-flex-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 12px 0;
}

.input-label {
  font-size: 17px;
  margin-bottom: 4px;
  color: #5c5c5c;
}

.modal-button {
  display: flex !important;
  justify-content: flex-end !important;
}

.loading__container {
  padding: 20px 0;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 767px) {
  .wrapText {
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
