.head_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;
}
.overview_add {
  display: flex;
  flex-direction: column;
}

.overview_add_top {
  font-size: 30px;
}
.overview_add_bottom {
  font-size: 15px;
  color: #474747;
}
.overview_add_bottom {
  font-size: 16px;
}

.overview_list {
  font-size: 18px;
  max-width: 100%;
  word-break: break-word;
}

.overview_list ul {
  padding-inline-start: 18px;
}

.overview_button_div {
  display: flex;
  /* width: 30%; */
  width: 100%;
}

.overview_button {
  margin-top: 20px;
  background-color: #006d77;
  color: #fff;
  padding: 8px 0px;
  border: none;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  width: 25%;
}

@media (max-width: 1199px) {
  .overview_add_top {
    font-size: 25px;
  }

  .overview_list {
    font-size: 16px;
    max-width: 100%;
    word-break: break-word;
  }
}

@media (max-width: 991px) {
  .overview_add_top {
    font-size: 18px;
  }
  .overview_add_bottom {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .overview_add_top {
    font-size: 16px;
  }
  .overview_add_bottom {
    font-size: 12px;
  }
  .overview_list {
    font-size: 14px;
    max-width: 100%;
    word-break: break-word;
  }
  .overview_button {
    width: 100%;
  }
}
