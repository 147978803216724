.objectives_container {
  padding: 90px 50px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  background-color: #fff;
}

.header_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.header_row h2 {
  font-size: 32px;
  color: #046c77;
  font-weight: bold;
}

.header_row .objectives_actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.objectives_list__actions {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
@media (max-width: 1280px) {
  .objectives_list__actions {
    justify-content: space-between;
  }
}

.objectives_list__actions_btn {
  background: none;
  border: none;
  padding: 0;
}

.main-box__head__action {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #24b3b9;
  width: 50px;
  height: 50px;
  border-radius: 6px;
  cursor: pointer;
}

.main-box__head__action-add {
  background-color: #046c77;
  border: none;
}
.main-box__head__action-pdf {
  background-color: #b91414;
  border: none;
}
.main-box__head__action-xls {
  background-color: #138d5f;
  border: none;
}

.main-box__head__action__icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.note_table {
  white-space: pre-line !important;
  text-align: center;
}

.tax-list {
  padding: 2rem 0;
}

.tax-list__container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.tax-list__table-name {
  width: 150px;
  white-space: pre-wrap;
}
