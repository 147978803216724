.upload-modal {
  width: 100%;
  max-width: 400px;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 15px;
  height: 275px;
  overflow-y: auto;
}

.upload-modal-title {
  font-weight: 600;
  font-size: 1.2rem;
}

.upload-box {
  display: flex;
  flex-direction: column;
  padding: 35px 50px;
  justify-content: space-between;
  width: 350px;
  height: 200px;
  border-radius: 10px;
  background-color: #fff;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.upload-button {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
}

.upload-modal-button {
  width: 70px;
  padding: 5px 25px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.upload-yes {
  background-color: #d20101;
  color: #fff;
}

.inputfile {
  /* visibility: hidden etc. wont work */
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.inputfile:focus + label {
  /* keyboard navigation */
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}

.inputfile + label * {
  pointer-events: none;
}

.labelFile {
  padding: 1% 3% 1% 3%;
  background-color: white;
  border: 1px solid #006d77;
  border-radius: 6px;
  color: #006d77;
  font-weight: bold;
  font-size: 1rem;
  cursor: pointer;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.upload-modal-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 150px;
}

.containerContentFile {
  display: flex;
  justify-content: center;
  align-items: center;
}

.containerButtonFile {
  display: flex;
  justify-content: center;
  align-items: center;
}
