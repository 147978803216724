.register_label {
  display: flex;
  font-size: 16px;
  padding: 30px 0 15px 0;
}

.warning_msg {
  background-color: #fffed4;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  width: 60%;
  justify-content: space-between;
  align-items: center;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.warning_msg p {
  margin: 0 20px;
  display: flex;
  align-items: center;
}

.warning_msg img {
  width: 35px;
}

.batch_program {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  width: 60%;
  height: 100%;
  padding: 20px;
  margin-top: 30px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  box-shadow: 1px 1px 10px #cccccc;
}

.checkBox_input {
  margin: 20px 0 5px;
  display: flex;
  align-items: center;
}

.checkBox_input label {
  margin: 0 10px;
}

.register_btn {
  width: 170px;
  margin: 10px;
  height: 50px;
  font-size: 14px;
  background-color: #006d77;
  color: #fff;
  border-radius: 15px;
  outline: none;
  box-shadow: none;
  overflow: hidden;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.register_btn:disabled {
  background-color: rgba(0, 53, 58, 0.6);
}

.skeleton {
  width: 60%;
  height: 300px;
  margin-top: 20px;
}

@media (max-width: 991px) {
  .batch_program,
  .warning_msg {
    width: 100%;
  }

  .skeleton {
    width: 100%;
  }  
}
