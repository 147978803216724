.mainNav {
  display: flex;
  justify-content: space-between;
}

.navbar_logo_main {
  display: flex;
  gap: 10px;
  align-items: center;
}
.hamburger-pointer {
  cursor: pointer;
  margin: 0 10px;
}
.flex_menu {
  display: flex;
  align-items: center !important;
}
.image_logo_new {
  margin-inline-end: 20px;
  max-width: 40px;
  width: 40px;
}
@media (max-width: 556px) {
  .image_logo_new {
    display: none;
  }
}

.backdrop_main {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1001;
  background: rgba(0, 0, 0, 0.5);
}
.header_shadow {
  height: 111px;
  justify-content: center;
  align-items: center;
  display: flex;
}
