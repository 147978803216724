.courses-list {
  padding: 2rem 0;
}

.courses-list__container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.courses-list__table-name {
  width: 300px;
  white-space: pre-wrap;
}

.courses-list__table__actions {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
}

.courses-list__table__actions-btn {
  background: none;
  border: none;
  padding: 0;
  width: 1.6rem;
  height: 1.6rem;
}
.courses-list__table__actions-btn:first-of-type {
  width: 1.4rem;
  height: 1.4rem;
}

.courses-list__table__actions-btn img {
  width: 100%;
  height: 100%;
}

.courses-list__table__actions-btn[disabled] {
  opacity: 0.3;
  cursor: not-allowed;
}

.courses-list__table-trainers {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.courses-list__table-trainers-item {
  color: #777777;
  position: relative;
}

.courses-list__table-trainers-item:not(:last-of-type)::after {
  content: "";
  position: absolute;
  top: 8px;
  left: -0.5rem;
  width: 1px;
  height: 60%;
  background: #777777;
}

.clone-course-modal {
  width: 100%;
  max-width: 900px;
}

.clone-course-modal__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.clone-course-modal__header img {
  cursor: pointer;
}

.clone-course-modal__form__submit__container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
}

.clone-course-modal__form__submit__button {
  padding: 1rem 8rem;
  margin-top: 2rem;
}

.clone-course-modal__form__fields {
  margin: 0 -15px;
}

.clone-course-modal__form__field {
  margin-bottom: 1.4rem;
}

.note_table {
  white-space: pre-line;
}
