.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5555;
}

.modal {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  width: 750px;
  height: 650px;
  overflow-y: scroll;
  padding: 35px 30px 15px;
  font-family: inherit;
}

.modal-two-input {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  width: 750px;
  overflow: hidden;
  padding: 35px 30px 15px;
  font-family: inherit;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid #e3e3e3;
}

.modal-header h3 {
  color: #046c77;
  font-size: 26px;
  margin-bottom: 0;
}

.close-button {
  border: none;
  background: none;
  cursor: pointer;
}

.close-icon {
  color: red;
  border: 2px solid red;
  border-radius: 50%;
  padding: 7px 9px;
}

.modal-content {
  padding-top: 20px;
  padding-bottom: 20px;
}

.icon-button svg {
  font-size: 18px;
}

.d-flex-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 12px 0;
}

.btn-copy {
  padding: 15px 30px;
  border-radius: 7px;
  background-color: #046c77;
  color: #fff;
  cursor: pointer;
  margin-top: 15px;
}

.input-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.input-label {
  font-size: 17px;
  margin-bottom: 4px;
  color: #5c5c5c;
}

.input-field {
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
  padding: 12px 18px;
  color: #858585;
}

.input-field-date {
  border: 1px solid #e3e3e3;
  padding: 12px 18px;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
  color: #e3e3e3;
}

.input-with-icon {
  display: flex;
  align-items: center;
}

.icon-button {
  background: none;
  border: none;
  cursor: pointer;
}

.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline {
  height: 150px !important;
}

.m-20 {
  margin: 20px 0;
}

.modal-button {
  display: flex !important;
  justify-content: flex-end !important;
}

@media (max-width: 1023px) {
  .modal {
    width: 500px;
    height: 550px;
  }

  .modal-two-input {
    width: 500px;
  }
}

@media (max-width: 767px) {
  .modal {
    width: 300px;
  }

  .modal-two-input {
    width: 300px;
  }
}
