.coupon-list {
  padding: 2rem 0;
}

.coupon-list__container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.coupon-list__table-name {
  width: 150px;
  white-space: pre-wrap;
  text-align: center;
}
.note_table {
  white-space: pre-line;
}
.departments-trainees__table-certificate {
  border-radius: 6px;
  height: 30px;
  padding: 0 15px;
}
.departments-trainees__table-certificate-no {
  background-color: rgb(232, 145, 145, 0.6);
  color: #e22020;
}

.departments-trainees__table-certificate-yes {
  background-color: rgb(193, 206, 226, 0.6);
  color: #8599b6;
}
