.card_cont {
  padding: 20px;
  border-radius: 20px;
  display: flex;
  /* flex-direction: column; */
  /* align-items: flex-start; */
  /* justify-content: space-evenly; */
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  width: 400px;
  gap: 30px;
}
.Num_card {
  font-size: 36px;
}
.label_card {
  color: #707070;
}
@media (max-width: 1000px) {
  .card_cont {
    width: 100%;
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }
  .Num_card {
    text-align: center;
  }
}
