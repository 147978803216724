.titleCoureses {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 20px;
}
.titleFont {
  font-size: 35px;
  font-weight: 600;
}

.carsolDiv {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 20px;
}
