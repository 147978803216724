.input-field {
  width: 100%;
}
.input-field input {
  width: 100%;
  height: 50px;
  padding: 0 1rem;
  border: 1px solid #ebebeb;
  background-color: #ffffff;
  border-radius: 6px;
  color: #777777;
}

.input-field input::placeholder {
  color: #b1b1b1;
}

.input-field input[disabled] {
  cursor: not-allowed;
  background-color: #f2f2f2;
}
.input-field input:read-only {
  cursor: text;
  background-color: #f2f2f2;
}

.input-field label {
  color: #777777;
}

.input-field input::-webkit-outer-spin-button,
.input-field input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input-field input[type="number"] {
  -moz-appearance: textfield;
}

.input-field-error {
  margin: 0 !important;
  padding: 0 !important;
  height: 10px !important;
}

.input-field-note {
  color: #b1b1b1;
  font-size: 12px;
  margin-top: 0.4rem;
}

.input-field-noteLogin {
  color: #b1b1b1;
  font-size: 16px;
  margin-bottom: 0.4rem;
}

.radio-label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 0.5rem 0;
}

.input-field-icon-container {
  position: relative;
  cursor: pointer;
}

.input-field-icon-container img {
  position: absolute;
  bottom: 0.7rem;
  width: 1.5rem;
  height: 1.5rem;
}

body[dir="rtl"] .input-field-icon-container img {
  left: 0.5rem;
}

body[dir="ltr"] .input-field-icon-container img {
  right: 0.5rem;
}
