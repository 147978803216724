.trainer_container {
  display: flex;
  gap: 1rem;
  flex-direction: row;
}
.trainer_image {
  min-width: 4rem;
  max-width: 4rem;
  height: 4rem;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid #c7c7c7;
  cursor: pointer;
}

.trainer_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.trainer_info {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}

.trainer_name {
  font-size: 1rem;
  font-weight: 600;
  color: #707070;
}
.star_font_size::before {
  font-size: 1rem;
}

.trainer_description {
  font-size: 0.9rem;
  color: #878787;
}
.rating-modal__container {
  min-height: 25rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.loading__container {
  padding: 20px 0;
  height: 25rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
