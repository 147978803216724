.CalenderSection {
  padding: 0 20px;
  display: flex;
  width: 100%;
  gap: 20px;
  height: fit-content;
  padding-top: 50px;
}

@media (max-width: 960px) {
  .CalenderSection {
    flex-wrap: wrap;
  }
}
.CalenderCont {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 20px;
  border-radius: 6px;
  height: fit-content;
}
.hasEvents {
  background-color: #029499;
  color: #fff !important;
}
.selected {
  border: 3px solid #a2ebee !important;
}
.daycell {
  cursor: pointer;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 40px;
  max-width: 40px;
  height: 40px;
  margin: 2px;
  border: 2px solid transparent;
  border-radius: 30%;
  color: #979797;
}
.disabled {
  opacity: 0.5;
  background: none;
  cursor: auto;
}
.daycell:not(.disabled):not(.hasEvents):hover {
  background-color: #0294994b;
}
.eventCardsContainer {
  display: flex;
  gap: 10px;
  flex-direction: column;
  height: 400px;
  overflow-y: auto;
  padding: 0px 0;
}
.dayEvents {
  display: flex;
  /* gap: 5px; */
  flex-direction: column;
  /* justify-content: space-between; */
  width: 100%;
}
.badgesCont {
  display: flex;
  margin-inline-end: 10px;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
}
@media (max-width: 1024px) {
  .badgesCont {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 8px;
  }
}
.badge {
  padding: 5px;
  width: 12px;
  height: 12px;
  border-radius: 4px;
}
.eventCard {
  background-color: #fff;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 15px 30px;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  position: relative;
}
.eventImg {
  width: 80px;
  height: 80px;
  border-radius: 6px;
}
.typeText {
  font-size: 12px;
  color: #797979;
  margin-inline-end: 6px;
  white-space: nowrap;
}
.iconText {
  font-size: 12px !important;
  color: #b7b7b7;
  /* width: 70px; */
}
.iconscontainer {
  display: flex;
  gap: 5px;
  align-items: center;
}
.eventDetails {
  display: flex;
  gap: 8px;
}
.eventTitle {
  font-size: 16px;
  font-weight: bold;
}
.eventdesc {
  font-size: 12px;
  color: #979797;
  padding-inline-end: 22px;
}
.eventContent {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.readMore {
  color: #029499;
  cursor: pointer;
}
.type {
  position: absolute;
  width: 12px;
  height: 100%;
  inset-inline-start: 0;
  /* z-index: 2; */
  /* border-radius: 0px 6px 6px 0px; */
}

body[dir="rtl"] .type {
  border-radius: 0px 6px 6px 0px;
}

body[dir="ltr"] .type {
  border-radius: 6px 0px 0px 6px;
}

.forum {
  background: #ff942c;
}
.academic-calendar {
  background: #5aeb67;
}
.conference {
  background: #00c0ff;
}
.seminar {
  background: #d790bb;
}
.other {
  background: #8bbacf;
}
.lecture {
  background: #34eb74;
}
.meeting {
  background: #34baeb;
}
.celebration {
  background: #eb9c34;
}
.loading__container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  margin: auto;
}
