.loading-fade {
  text-align: center;
  animation: fadeIn 1s infinite alternate;
  -webkit-animation: fadeIn 0.5s infinite alternate;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loading-fade img {
  /* width: 70%; */
  margin-bottom: 10px;
}

@keyframes fadeIn {
  from {
    opacity: 0.1;
  }
}
.loading_img_logo {
  height: 150px;
  width: 50px;
}
