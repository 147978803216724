.notifications-box {
  min-width: 31.5rem !important;
  top: 0;
  border-radius: 1rem !important;
  z-index: 9999 !important;
}

body[dir="ltr"] .notifications-box {
  left: -30rem;
  right: unset;
}

body[dir="rtl"] .notifications-box {
  right: -30rem;
  left: unset;
}

.notification-header {
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.notification-header h4 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #024f92;
}

.notification-count {
  background: #f2f2f2;
  border-radius: 50%;
  padding: 0.2rem 0.7rem;
  display: flex;

  align-items: center;
  justify-content: center;
}

.notification-count span {
  color: #03a2b2;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.2rem;
}

.notifications {
  padding: 1rem 2rem;
  max-height: 400px;
  overflow-y: auto;
}
.notification::-webkit-scrollbar-thumb {
  background: #006f79 !important;
  border-radius: 2rem;
}

.notification {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  border-bottom: 1px solid #ddd;
}

.notification-divider {
  width: 90%;
  height: 1px;
  margin: 0 auto;
  background: #ddd;
}

.notification.new {
  background-color: #eef4f5;
  border-radius: 1rem;
}

.notification-content {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.notification-img {
  width: 3rem;
  min-width: 3rem;
  height: 3rem;
  min-height: 3rem;
  border-radius: 9999px;
  background: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notification-img img {
  width: 1.6rem;
  height: 1.6rem;
}

.notification-content p {
  font-size: 1rem;
  color: #6c757d;
  text-align: start;
}

/* body[dir="ltr"] .notification-content p {
  direction: ltr;
}

body[dir="rtl"] .notification-content p {
  direction: rtl;
} */

.notification.new .notification-content p {
  color: #00254a;
  font-weight: 600;
}

.notification-time {
  font-size: 0.7rem;
  color: #6c757d;
  white-space: nowrap;
  color: #024f92;
  /* direction: ltr; */
}

.no-notification {
  padding: 1rem 2rem;
  min-height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.no-notification p {
  font-size: 1rem;
  color: #6c757d;
}

@media (max-width: 767px) {
  .notifications-box {
    min-width: 10.5rem !important;
    width: 18.5rem;
    top: 0 !important;
    border-radius: 1rem !important;
    z-index: 9999 !important;
  }
  body[dir="ltr"] .notifications-box {
    left: -200% !important;
    right: unset !important;
  }

  body[dir="rtl"] .notifications-box {
    right: unset !important;
    left: unset !important;
  }

  .notification-content p {
    font-size: 12px !important;
    color: #6c757d;
  }
  .notification-img img {
    width: 1rem;
    height: 1rem;
  }
  .notification-img {
    width: 1.5rem;
    min-width: 1.5rem;
    height: 1.5rem;
    min-height: 1.5rem;
    border-radius: 9999px;
    background: #f2f2f2;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .notifications {
    padding: 1rem 0rem;
    max-height: 400px;
    overflow-y: auto;
  }
}
@media (max-width: 520px) {
  /* .notifications-box { */
  /* min-width: 92vw !important; */
  /* margin-left: auto !important;
    margin-right: auto !important;
  }*/
  body[dir="rtl"] .notifications-box {
    left: -30px !important;
    right: unset;
  }
}
.hovering {
  transform: scale(1.05) translateY(3px);
  /* transform: translateY(-5px); */
  border-radius: 16px;
  transition: 350ms ease-in-out;
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}
