.manage-specialties {
	padding: 2rem 0;
}

.manage-specialties__container,
.manage-specialties__form {
	display: flex;
	flex-direction: column;
	gap: 2rem;
}

.manage-specialties__form__fields {
	margin: 0 -15px;
}

.manage-specialties__form__field {
	margin-bottom: 2rem;
}

.manage-specialties__form__field label {
	color: #777777;
}

.manage-specialties__form-error {
	margin: 0 !important;
	padding: 0 !important;
}
