.admin_label {
  font-size: 18px;
  display: flex;
  margin: 0;
}
.goals-not-found,
.list-goals,
.add-disc-board {
  box-shadow: 1px 1px 11px #d1d1d1;
  border-radius: 10px;
  padding-bottom: 35px;
}
.goals-not-found {
  text-align: center;
}
.goals-not-found img {
  width: 115px;
  padding: 20px 0;
}
.goals-not-found p {
  color: rgb(137 137 137);
  text-align: center;
  margin: 0px;
  padding-top: 29px;
}
.btn-add-goals,
.btn-add-goals:hover,
.btn-add-goals:active {
  background: rgb(18, 110, 119);
  border: 1px solid rgb(18, 110, 119);
  color: rgb(255, 255, 255);
  border-radius: 10px;
  padding: 5px 29px;
}
.btn-add-goals i {
  font-size: 12px;
  padding: 0 5px;
}

/** Add Edu **/
.add-disc-board {
  max-width: 100%;
  word-break: break-word;
}
.add-disc-board h4 {
  padding: 20px 30px;
}
.add-disc-board form {
}
.add-disc-board form label {
  display: block;
  color: #6b6b6b;
}
.add-disc-board form input {
  border: none;
  border-bottom-color: #c5c5c5;
  border-bottom-style: solid;
  border-block-width: 1px;
  width: 93%;
  background: transparent;
}
.add-disc-board form .btn_submit {
  display: inline-block;
  align-items: center;
  justify-content: center;
  min-width: 130px;
  padding: 5px 10px;
  background-color: #006d77;
  border-radius: 15px;
  color: #fff;
  font-size: 16px;
  border: none;
  outline: none;
  margin: 0 10px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  cursor: pointer;
}
body[dir="rtl"] .add-disc-board form .fa-trash {
  transform: translate(20px, 0px);
}
.add-disc-board form .fa-trash {
  color: #cc0000;
  font-size: 12px;
  transform: translate(20px, 0px);
}
.add-disc-board form .btn-remove {
  border: none;
  background: transparent;
  cursor: pointer;
}
.list-goals {
  padding: 20px;
  margin-bottom: 20px;
  max-width: 100%;
  word-wrap: break-word;
}
.list-goals h3 {
  padding-bottom: 30px;
}

@media (min-width: 767px) {
  .add-disc-board {
    width: 70%;
  }
}

/* Review Discussion */
.icon-disc {
  text-align: right;
  flex: 1;
}
.icon-disc a,
.icon-disc a:hover,
.icon-disc a:active {
  font-size: 14;
  color: #333 !important;
}
.icon-disc .btn-edit,
.icon-disc .btn-edit:hover {
  background: #006d77;
  color: #fff !important;
  padding: 10px;
  font-size: 12px;
  border-radius: 10px;
  margin: 0 15px;
}
.icon-disc .btn-edit i {
  color: #fff;
}

body[dir="rtl"] .icon-disc {
  text-align: left;
}
/* .replay  */
.review-discussion.training-course-comments-container {
  padding: 0 30px;
}
.training-course-comments-container {
  padding-top: 40px;
  padding-left: 140px;
  padding-right: 140px;
}

.training-course-comment-outline {
  border-color: #c1c1c1;
  border-radius: 13px;
  border-width: 1px;
  border-style: solid;
  margin-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.training-course-comment-header {
  display: flex;
  flex-direction: row;
  height: 70px;
  align-items: center;
}

.training-course-comment-outline.replay {
  border-radius: 4px;
  border-color: #eee;
  padding: 20px 0;
  margin-top: 10px;
}
.training-course-comment-outline.replay .training-course-comment-header {
  padding: 0 0 30px 0;
}
.replay .training-course-comment-header {
  height: auto;
  padding: 20px 0 0 0;
}
.training-course-commenter {
  padding-right: initial;
  padding-left: 10px;
}

body[dir="rtl"] .training-course-commenter {
  padding-right: 10px;
  padding-left: initial;
}

.training-course-comment-body {
  padding: 5px 66px;
  max-width: 100%;
  word-wrap: break-word;
}

.training-course-comment-footer {
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.training-course-comment-like-icon {
  width: 15px;
  margin: 0px 10px;
}

.training-course-comment-like-text {
  font-size: 13px;
}

.courses_card_icon {
  background-image: url("../../../../../assets/image/smallDoc.png");
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 10px;
  object-fit: cover;
  background-position: center;
  background-size: cover;
}

.add-discussions {
  margin-bottom: 20px;
}

.text-comment {
  max-width: 100%;
  word-break: break-word;
}

@media (max-width: 991px) {
  .training-course-comments-container {
    padding-left: 90px;
    padding-right: 90px;
  }
}

@media (max-width: 767px) {
  .training-course-comments-container {
    padding-left: 40px;
    padding-right: 40px;
  }
  .add-discussions-text-editor {
    width: 100% !important;
  }
}
@media (min-width: 767px) {
  .disc-content {
    width: 70%;
    flex: none !important;
  }
}
