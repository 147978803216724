.course-details-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.min-hight-78 {
  min-height: calc(78vh - 20px);
}

.training-course-syllabus-container {
  display: flex;
  flex-direction: column;
}

.training-course-syllabus-section {
  position: relative;
  flex: 2;
}
