.warning-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}

.border-8 {
  border-radius: 8px;
}

.modal-w {
  width: 400px;
}

.modal-buttons {
  display: flex;
  gap: 15px;
}

.modal-buttons button {
  min-width: 150px;
  text-align: center;
  justify-content: center;
}

.warning-img {
  width: 100px;
}

.warning-img img {
  width: 100%;
}

.description {
  font-size: 18px;
  text-align: center;
}

.link {
  color: #046c77;
  font-weight: bold;
}

.warning-text {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.4rem;
}

.sub-label {
  color: #046c77;
  font-weight: bold;
}

@media (max-width: 991px) {
  .modal-w {
    width: 400px;
  }

  .modal-buttons button {
    width: 100px;
    min-width: 100px;
  }
}

@media (max-width: 767px) {
  .modal-w {
    width: 300px;
  }

  .modal-buttons button {
    width: 100px;
    min-width: 100px;
  }
}
