.auth_forms_login {
  background-color: rgb(255 255 255) !important;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column !important;
  width: 100%;
  color: #000;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  overflow: hidden;
  padding-top: 20px;
}

.auth_forms_login input {
  color: #000;
}

.error_login {
  display: flex;
  width: 100%;
  justify-content: center;
  color: #942b35;
  height: 15px;
  margin-bottom: 10px;
}

.auth_btn {
  width: 100%;
  /* border-radius: 20px; */
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  color: #fff;
  background-color: #025299;
}

.auth-home-login {
  display: flex;
  justify-content: center;
  width: 100%;
}
.logo_qassem_login {
  height: 120px;
  width: 350px;
  max-width: 50px;
  cursor: pointer;
}

.auth-home-login svg {
  width: 30px;
  height: 30px;
  margin-top: -75px;
  margin-bottom: 75px;
}

.auth_form_form {
  padding: 0 40px;
}

.forgotPasswordBtn {
  color: #24b3b9;
  font-size: 14px;
  text-decoration: underline;
  background-color: transparent;
  border-width: 0;
  cursor: pointer;
}
.auth_form_flex_col {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  /* gap: 1rem; */
}
/* @media (max-width: 991px) {
  .auth_forms_login {
    width: 100%;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }
  .auth_forms_login {
    border-radius: 0;
  }
} */
.small_note {
  font-size: 14px;
  color: #b1b1b1;
}
@media (max-width: 767px) {
  .auth_label {
    font-size: 20px;
    font-weight: bold;
    padding: 0 10px;
  }

  .auth_form_form {
    padding: 0 15px;
  }
}
@media (max-width: 480px) {
  .auth_forms_login {
    gap: 1rem;
  }
}
.auth_forms_reg {
  background-color: rgb(255 255 255) !important;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column !important;
  width: 100%;
  height: 100%;
  color: #000;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  padding-top: 20px;
  padding-bottom: 30px;
}
@media (max-width: 991px) {
  .auth_forms_reg {
    width: 100%;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }
  .auth_forms_login {
    border-radius: 0;
  }

  .input-name-register {
    flex-direction: column;
  }

  .input-name-register > div {
    width: 100%;
  }
}
@media (max-width: 991px) {
  .auth_forms_reg {
    width: 100%;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }
}

@media (max-width: 767px) {
  .auth_forms_reg {
    justify-content: flex-start;
  }

  .auth_label {
    font-size: 20px;
    font-weight: bold;
    padding: 0 10px;
  }

  .auth_form_form {
    padding: 0 15px;
  }
}
.Trainer_traine_icon {
  width: 65px;
  height: 65px;
}
.Trainer_trainee_cont {
  background-color: rgba(0, 255, 255, 0.137);
  border-radius: 6px;
  padding: 35px 70px;
  border: 1px solid #c0bebe7e;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin: 15px 0 35px 0;
}
.trainer_trainee_box {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 20px;
  gap: 30px;
  flex-wrap: wrap;
}
.auth_label_registar {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 5px; */
}
.auth_label_registar span {
  color: #b5b5b5;
}

.select_role_back {
  padding-bottom: 10px;
  cursor: pointer;
  color: #025299 !important;
}
.small_note_Back {
  font-size: 14px;
  color: #025299 !important;
}
