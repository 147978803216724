.create_tax {
  padding: 2rem 0;
}

.create_tax__container,
.create_tax__form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.create_tax__form__fields {
  margin: 0 -15px;
}

.create_tax__form__field {
  margin-bottom: 2rem;
}

.create_tax__form__field label {
  color: #777777;
}

.create_tax__form__field-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
}

.create_tax__form__field-actions button {
  background-color: #046c77;
  border-radius: 6px;
  border: none;
  padding: 0 1rem;
  height: 50px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.create_tax__form__field-actions-submit {
  width: 250px;
}
.create_tax__form__field-actions-submit[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}
.create_tax__form__field-actions-view {
  width: 50px;
}

.create_tax__form-error {
  margin: 0 !important;
  padding: 0 !important;
}

.create_tax__form__field-mode {
  color: #777777;
}
.select-field {
  width: 100%;
}

.select-field select {
  width: 100%;
  height: 50px;
  padding: 0 1rem;
  border: 1px solid #ebebeb;
  background-color: #ffffff;
  border-radius: 6px;
  color: #777777;
}

.select-field label {
  color: #777;
  font-weight: 400;
  /* line-height: 45px; */
}

.select-field-error {
  margin: 0 !important;
  padding: 0 !important;
  height: 10px !important;
}

@media only screen and (max-width: 480px) {
  .create_tax__form__field-actions-submit {
    width: 100%;
  }
}

.input-label {
  font-size: 17px;
  margin-bottom: 4px;
  color: #5c5c5c;
}
.d-flex-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin: 12px 0; */
  margin-bottom: 20px;
}
.m-40 {
  margin-bottom: 60px;
}
.textarea {
  width: 100%;
  border: 1px solid #d3d3d3;
  padding: 10px;
  border-radius: 4px;
}
