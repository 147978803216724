.user-card {
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  padding-bottom: 20px;
  width: 270px;
  height: 100%;
  display: flex;
  /* width: 100%; */
  min-height: 383px;
}

.card-content {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
  gap: 15px;
  justify-content: space-between;
}

.img {
  width: 100%;
  height: 235px;
  object-fit: inherit;
  border-radius: 10px;
}

.flip-star {
  /* transform: rotateY(180deg); */
  display: flex;
  justify-content: center;
}

.star-font-size::before {
  content: "\f005\f005\f005\f005\f005";
  font: normal normal normal 14px/1 FontAwesome;
  letter-spacing: 3px;
  background: linear-gradient(
    90deg,
    #24b3b9 var(--percent),
    var(--defaultColor) var(--percent)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1rem;
}

.postion-title {
  color: #6d6d6d;
  font-size: 16px;
  display: -webkit-box;
  max-width: 270px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.description {
  color: #959595;
  font-size: 12px;
  width: 270px;
  overflow: hidden;
  -webkit-box-orient: vertical;
  display: block;
  display: -webkit-box;
  overflow: hidden !important;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
}

.name {
  text-align: center;
}
.job_title {
  height: 14px;
  text-align: center;
  font-size: 14px;
  color: #03a2b2;
  font-weight: 600;
  /* height: 50px; */
}
.nums {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  color: #046c77;
  padding: 10px 0;
}

.pip {
  color: #dadada;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;
}
.star-dep-cat {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  margin: 6px 0;
}
.star-dep-cat p {
  font-size: 10px;
  color: #046c77;
  text-align: center;
  max-width: 120px;
}
.num_courses {
  display: flex;
  gap: 15px;
  background-color: #14afc0;
  padding: 10px 25px;
  border-radius: 20px;
  justify-content: center;
  color: #fff;
  margin: 0 auto;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}
.numContainer {
  display: flex;
  gap: 12px;
  color: #ddd;
}
