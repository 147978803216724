.tabs {
	display: flex;
	align-items: center;
	gap: 1rem;
	padding: 0;
	margin: 0;
	overflow-x: auto;
	width: 100%;
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.tabs::-webkit-scrollbar {
	display: none;
}

.tabs__item {
	color: #c8c8c8;
	cursor: pointer;
	padding: 5px 0;
	white-space: nowrap;
}
.tabs__item-active {
	color: #777777;
	border-bottom: 3px solid #046c77;
}
