.container-fluid {
    max-width: 1280px;
    padding: 0 20px;
}

.training-course-body {
    margin-top: 20px;
}

@media (max-width: 768px) {
    .container-fluid {
        padding: 0 15px;
    }
}
