.tajah-container-fluid {
  position: relative;
  padding: 0px;
  max-width: 1280px;
  margin-right: auto;
  margin-left: auto;
}

.tajah-container-area {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  margin-top: 40px;
  padding: 50px 0;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 2px 2px 10px #ddd;
  -webkit-border-radius: 10px;
}

.tajah-container-area-label {
  display: flex;
  width: 90%;
  margin-bottom: 30px;
  justify-content: space-between;
  align-items: center;
}

.tajah-action-button {
  background-color: rgb(0, 109, 119);
  border-color: rgb(0, 109, 119);
  margin-left: 10px;
  margin-right: 10px;
}

.tajah-action-button:hover {
  background-color: rgb(0, 109, 119);
  border-color: rgb(0, 109, 119);
}

.tajah-chat-area {
  margin-top: 20px;
  width: 90%;
  max-height: 420px;
  overflow-y: auto;
  max-width: 100%;
  word-break: break-word;
}

.tajah-chat-bubble {
  min-height: 200px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tajah-chat-bubble:not(:first-child) {
  margin-top: 20px;
}

.tajah-owner-message {
  background-color: #f5f5f5;
}

.tajah-other-message {
  background-color: #d7ecee;
}

.tajah-message-creator-details {
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-right-width: 1px;
  border-right-color: rgba(0, 0, 0, 0.2);
  border-right-style: solid;
  min-height: 150px;
  height: 90%;
}

body[dir="rtl"] .tajah-message-creator-details {
  border-right-style: none;
  border-left-width: 1px;
  border-left-color: rgba(0, 0, 0, 0.2);
  border-left-style: solid;
}

.tajah-message-content {
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  width: 80%;
}

.tajah-conversation-box {
  width: 90%;
  margin-top: 20px;
}

.tajah-container-area-textarea {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
}

.tajah-button-area {
  margin-top: 20px;
  width: 90%;
  display: flex;
  flex-direction: row;
}

.tajah-submit-button:first-child {
  margin-left: 0;
}

body[dir="rtl"] .tajah-submit-button:first-child {
  margin-right: 0;
}

.admin_label {
  font-size: 18px;
  display: flex;
  margin: 30px 0;
}

.view-ticket {
  margin-bottom: 20px;
}
.type-ticket:disabled {
  background-color: #f2f2f2;
}

.type-ticket-priority {
  align-items: flex-end;
}

.ticket-course {
  background-color: #f2f2f2;
  padding: 0.625rem;
  border-radius: 0.25rem;
  color: rgb(107 114 128 / 1);
  border: 1px solid rgb(229 231 235 / 1);
}
.ticket_view-replies-body img {
  width: 100%;
}

.priority-btn[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.ticket-view__transfers__list {
  list-style: auto;
  padding: 0 1rem;
}

.ticket-view__transfers__list-item span {
  margin: 0 0.5rem;
}
.ticket-transfers__field {
  width: 100%;
  white-space: pre-wrap;
  max-width: 150px;
  display: inline-block;
}

.ticket-transfers-modal {
  width: 800px;
}
@media (max-width: 991px) {
  .admin_label {
    font-size: 16px !important;
  }
}

@media (max-width: 768px) {
  .admin_label {
    font-size: 14px !important;
  }

  .view-ticket .admin_label {
    margin: 0;
  }
  .view-ticket__wilderman {
    align-items: flex-end;
    gap: 20px;
  }
  .view-ticket__wilderman__left {
    flex-wrap: wrap;
  }

  .view-ticket__wilderman__left-selection {
    width: 100% !important;
    margin: 0 !important;
    margin-top: 20px !important;
  }
  .view-ticket__wilderman__right button {
    margin: 0;
  }
  .tajah-message-creator-details {
    width: 40%;
  }

  .tajah-message-content {
    width: 60%;
  }
  .ticket-transfers-modal {
    width: 100%;
  }
}

@media only screen and (max-width: 480px) {
  .ticket__view-actions {
    gap: 0.5rem;
    flex-wrap: wrap;
  }
  .ticket__view-actions > button {
    margin: 0 !important;
    width: calc(50% - 0.25rem);
    padding: 0.5rem;
  }
}
@media only screen and (max-width: 380px) {
  .view-ticket__wilderman__left {
    font-size: 11px;
  }
}
.training-course-back {
  display: flex;
  gap: 3px;
  cursor: pointer;
  min-width: fit-content;
}

.training-course-back-icon {
  width: 20px;
  height: 20px;
  margin-top: 0.4rem;
}

.training-course-back-icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

body[dir="ltr"] .training-course-back-icon img {
  transform: rotate(180deg);
}

.training-course-back-text {
  font-size: 16px;

  color: #24b3b9;
  padding-bottom: 0.4rem;
}
