.program__payment__list {
  padding: 2rem 0;
}

.program__payment__list__container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.program__payment__modal {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.program__payment__modal {
  width: 100%;
  max-width: 900px;
}

.program__payment__modal__header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.program__payment__modal__header__title {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.program__payment__modal__header__note {
  background-color: #e22020;
  color: #ffffff;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  font-size: 0.8rem;
}

.program__payment__modal__header img {
  cursor: pointer;
}

.program__payment__modal__form__submit__container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
}

.program__payment__modal__form__submit__button {
  padding: 1rem 8rem;
  margin-top: 2rem;
}

.program__payment__modal__form__fields {
  margin: 0 -15px;
}

.program__payment__modal__form__field {
  margin-bottom: 1.4rem;
}

.courses-list__table__actions {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
}
