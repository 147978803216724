.tajah_card_title_container {
    display: flex;
    width: 90%;
    margin-bottom: 30px;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
  
  .tajah_card_title_container_actions {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  
  .tajah_filter_item_label_actionable {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
  }
  
  .tajah_filter_item_label_action {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
  }
  
  .tajah_filter_item_label_action_clear {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: red;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .tajah_table_vertical_movement {
    position: absolute;
    transition: top 0.5s;
  }
  
  .tajah_table_fade_change {
    transition: opacity 0.5s;
  }
  
  .table_bank_tran .table {
    width: 100%;
  }
  
  .table_bank_tran td,
  .table_bank_tran th {
    text-align: center;
  }
  
  .admin_filter_input {
    display: flex;
    background-color: transparent;
    color: #000;
    -webkit-backdrop-filter: blur(100px);
    backdrop-filter: blur(100px);
    position: relative;
    width: 100%;
    height: 38px;
    border-color: #cccccc;
    box-shadow: none;
    border-style: solid;
    border-width: 0.5px;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .tajah_card_title_container {
    display: flex;
    width: 90%;
    margin-bottom: 30px;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
  
  .tajah_card_title_container_actions {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  
  .tajah_filter_item_label_actionable {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
  }
  
  .tajah_filter_item_label_action {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
  }
  
  .tajah_filter_item_label_action_clear {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: red;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .btn-bg-filter {
    background-color: #18b1b9;
  }
  
  .edit-per{
    right: 10px
  }
  body[dir = 'rtl'] .edit-per{
    left: 10px;
    right: unset

  }