.slick-arrow {
  top: -2.8rem;
}
.slick-prev {
  right: 2rem !important;
  left: unset !important;
}

.slick-next {
  left: unset !important;
  right: 0;
}

.slick-arrow::before {
  color: #046c77;
}

body[dir="rtl"] .slick-prev {
  right: unset !important;
  left: 0 !important;
}
body[dir="rtl"] .slick-prev::before {
  content: "←";
}

body[dir="rtl"] .slick-next {
  left: 2rem !important;
  right: unset !important;
}

body[dir="rtl"] .slick-next::before {
  content: "→";
}
.shared-slider .slick-arrow.slick-next,
.shared-slider .slick-arrow.slick-prev {
  transform: rotate(0);
}

.slick-custom-btn {
  height: 2rem;
  width: 2rem;
  /* padding: 0 20px; */
  border-radius: 6px;
  border: 2px solid #24B3B9;
}

.slick-custom-btn.next {
  right: 0 !important
}
.slick-custom-btn.prev {
  right: 2.5rem !important
}

body[dir="rtl"] .slick-custom-btn.next {
  left: 2.5rem !important;
}
body[dir="rtl"] .slick-custom-btn.prev {
  right: unset !important;
}


.slick-custom-btn::before {
  content: unset !important;
}