.containerUsersList {
  background-color: #fbfbfb;
  padding: 20px;
  border-radius: 6px;
  height: 70vh;
  overflow-x: none;
  overflow-y: auto;
}

.left,
.right {
  display: block;
  position: relative;
  border-radius: 10px;
  display: inline-block;
  margin: 5px;
  padding: 10px;
  word-break: break-word;
}

.left {
  float: left;
  margin-left: 20px;
  background-color: white;
  color: #707070;
}
.right {
  float: right;
  margin-right: 20px;
}

.breadcrumb {
  margin: 30px 0;
}

.loading__container {
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  height: 50px;
  align-items: center;
  position: absolute;
  bottom: 20px;
  width: 95%;
}

.input-disable {
  background-color: #fafafa;
}

.input {
  width: 100%;
  height: 100%;
  font-size: 17px;
  padding: 20px;
  display: flex;
  align-items: center;
  word-break: break-word;
}

.icon {
  color: white;
  height: 40px !important;
  width: 40px !important;
  background-color: #066d77;
  padding: 10px;
  margin: 0 5px;
  border-radius: 4px;
  rotate: 180deg;
}

.disabledicon {
  color: white;
  height: 40px !important;
  width: 40px !important;
  background-color: #c7c7c7;
  padding: 10px;
  margin: 0 5px;
  border-radius: 4px;
  rotate: 180deg;
  cursor: not-allowed;
}

.header {
  display: flex;
  justify-content: space-between;
}

.btns {
  display: flex;
  gap: 10px;
}

.toggleBG {
  display: flex;
  background: #efefef;
  align-items: center;
  height: 36px;
}

.togglelabel {
  margin: 0 15px;
  margin-bottom: 3px;
  color: #9c9c9c;
  font-size: 14px;
}

.d-flex {
  display: flex;
}

.livechat {
  border-width: 1px;
  border-color: #dbe5ed;
  border-style: solid;
  min-height: 200px;
  border-radius: 6px;
  position: relative;
  background: #fff;
}

.livechat-card {
  margin-bottom: 50px;
  max-height: 60vh;
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;
}

@media (max-width: 1279px) {
}

@media (max-width: 1023px) {
  .containerUsersList {
    height: 40vh;
    margin-bottom: 10px;
    padding: 10px;
  }

  .header {
    flex-direction: column;
  }
  .d-flex {
    display: unset;
  }
  .btns {
    margin-top: 20px;
    display: grid;
    text-align: center;
    grid-template-columns: repeat(2, auto);
    align-items: center;
    justify-content: center;
  }
  .togglelabel {
    font-size: 10px;
  }

  .customBtn {
    font-size: 14px;
    justify-content: center;
  }

  .rmPadding {
    padding: 0 !important;
  }

  .input-container {
    bottom: 0px;
    width: 100%;
  }
}

@media (max-width: 767px) {
  .d-flex {
    display: unset;
  }
  .btns {
    margin-top: 20px;
    display: grid;
    text-align: center;
    grid-template-columns: repeat(2, auto);
    align-items: center;
    justify-content: center;
  }
  .togglelabel {
    font-size: 10px;
  }

  .customBtn {
    font-size: 14px;
    justify-content: center;
  }

  .rmPadding {
    padding: 0 !important;
  }

  .input-container {
    bottom: 0px;
    width: 100%;
  }
}
/*{*/
/*    box-sizing: border-box ;*/
/*}*/

/* Button used to open the chat form - fixed at the bottom of the page */
.open-button {
  background-color: #555;
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  opacity: 0.8;
  position: fixed;
  bottom: 23px;
  right: 28px;
  width: 280px;
}

/* The popup chat - hidden by default */
.form-popup {
  display: none;
  position: relative;
  bottom: 0;
  right: 15px;
  border: 3px solid #f1f1f1;
  z-index: 0;
}

/* Add styles to the form container */
.form-container-chat {
  max-width: 410px;
  z-index: 10000;
  padding: 6px;
  border-radius: 4px;
  background-color: #fff;
  position: fixed;
  bottom: 100px;
  right: 20px;
  min-height: 300px;
}

/* Full-width textarea */
.form-container-chat textarea {
  width: 100%;
  padding: 15px;
  margin: 5px 0 22px 0;
  border: none;
  background: #f1f1f1;
  resize: none;
}

.form-container-chat::before {
  z-index: 10;
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  margin-left: 0;
  bottom: -2px;
  left: 0;
  right: 2px;
  box-sizing: border-box;
  border: 12px solid #fff;
  border-color: transparent #fff #fff transparent;
  transform-origin: 0 0;
  transform: rotate(45deg);
}

/* When the textarea gets focus, do something */
.form-container-chat textarea:focus {
  background-color: #ddd;
  outline: none;
}

/* Set a style for the submit/login button */
.form-container-chat .btn {
  background-color: #04aa6d;
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  width: 100%;
  margin-bottom: 10px;
  opacity: 0.8;
}

/* Add a red background color to the cancel button */
.form-container-chat .cancel {
  background-color: red;
}

/* Add some hover effects to buttons */
.form-container-chat .btn:hover,
.open-button:hover {
  opacity: 1;
}

.left,
.right {
  display: block;
  position: relative;
  border-radius: 10px;
  display: inline-block;
  word-break: break-word;
}

.left {
  float: left;
  margin-left: 10px;
  color: #000;
}

.right {
  float: right;
  margin-right: 10px;
  color: #000;
}

.date {
  font-size: 10px;
  color: #bdbdbd;
}

.chat-circle {
  position: fixed;
  right: 15px;
  bottom: 20px;
  width: 60px;
  height: 60px;
  background: #006d77;
  border: 3px solid white;
  box-shadow: 3px 6px 10px rgb(178, 178, 178);
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000;
}

body[dir="ltr"] .chat-circle {
  left: 20px;
}

.chat-bubble-header {
  height: 40px;
  border-bottom: solid;
  border-width: 1px;
  width: 100%;
  height: 100%;
  border-color: #dbe5ed;
  margin-bottom: 10px;
  border-radius: 20px;
  padding: 10px;
  background-color: #e4fffc;
}

@media only screen and (max-width: 768px) {
  .form-container-chat {
    display: block;
    width: calc(100% - 30px);
    left: 15px;
  }
}

.live-chat-container {
  width: 80%;
  padding: 15px 10px;
  background-color: #fff;
  box-shadow: 3px 6px 10px rgb(225, 225, 225);
  border-radius: 16px;
  margin: 15px 10px;
  display: flex;
  align-items: center;
}

.admin_img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 5px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.live-chat-container::before {
  z-index: 10000;
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  margin-left: 0;
  bottom: -4px;
  left: 26%;
  right: unset;
  box-sizing: border-box;
  border: 7px solid #fff;
  border-color: transparent #fff #fff transparent;
  transform-origin: 0 0;
  transform: rotate(45deg);
}

.content-container {
  display: flex;
  align-items: center;
}
