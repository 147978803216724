.payment-details-modal {
	background-color: rgb(33, 33, 33, 0.45);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1000;
	display: flex;
	align-items: center;
	justify-content: center;
}

.payment-details-modal__container {
	background-color: #ffffff;
	width: 45rem;
	height: 90vh;
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
	border-radius: 15px;
	padding: 1.5rem;
	position: relative;
}

.payment-details-modal__header {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.payment-details-modal-title {
	color: #046c77;
	font-size: 30px;
	font-weight: 500;
}

.payment-details-modal-value {
	display: flex;
	align-items: center;
	gap: 1rem;
}
.payment-details-modal-value-label {
	font-size: 18px;
	color: #046c77;
}
.payment-details-modal-value-box {
	background-color: #046c77;
	color: #ffffff;
	padding: 0 1rem;
	height: 50px;
	font-size: 30px;
	border-radius: 6px;
}

.payment-details-modal-value-box sub {
	font-size: 20px;
}
.payment-details-modal__close {
	position: absolute;
	right: 1.5rem;
	left: auto;
	top: 1.5rem;
	cursor: pointer;
}

body[dir="rtl"] .payment-details-modal__close {
	left: 1.5rem;
	right: auto;
}

.payment-details-modal__field {
	margin-bottom: 1rem;
}
.payment-details-modal__image {
	position: relative;
}
.payment-details-modal__image img {
	width: 100%;
}

.payment-details-modal__image-download {
	position: absolute;
	left: 10px;
	top: 10px;
	width: 25px;
	background: #e9ecef;
	height: 25px;
	border-radius: 3px;
}
.payment-details-modal__content {
	/* max-height: 300px; */
	overflow-y: auto;
}

/**** custom scrollbar ****/
.payment-details-modal__content::-webkit-scrollbar {
	width: 8px;
}
.payment-details-modal__content::-webkit-scrollbar-track {
	background-color: #f5f5f5;
	border-radius: 10px;
}

.payment-details-modal__content::-webkit-scrollbar-thumb {
	background-color: #046c77;
	border-radius: 4px;
}
.payment-details-modal__content::-webkit-scrollbar-thumb:hover {
	background-color: #046c77;
}

.payment-details-modal__actions {
	margin-top: 1rem;
	display: flex;
	align-items: center;
	gap: 1rem;
}

.payment-details-modal__actions-btn {
	width: 50%;
	background: transparent;
	border-radius: 6px;
	height: 50px;
	padding: 0 1rem;
}
.payment-details-modal__actions-btn[disabled] {
	opacity: 0.5;
	cursor: not-allowed;
}

.payment-details-modal__actions-btn.primary {
	background-color: #046c77;
	color: #ffffff;
}

.payment-details-modal__actions-btn.reject {
	border: 1px solid #ef2929;
	color: #ef2929;
}

@media only screen and (max-width: 768px) {
	.payment-details-modal__container {
		width: 90%;
	}
}

@media only screen and (max-width: 580px) {
	.payment-details-modal-title,
	.payment-details-modal-value-box {
		font-size: 20px;
	}
	.payment-details-modal-value-box sub {
		font-size: 16px;
	}
	.payment-details-modal-value {
		justify-content: space-between;
	}
	.payment-details-modal-value-box {
		height: 40px;
	}
}
