.GroupCard {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: space-between; */
  gap: 10px;
  border-radius: 16px;
  background-color: rgb(255, 255, 255);
  border: 1px solid #025299;
  padding: 20px;
  min-height: 250px;
  min-width: 190px;
  cursor: pointer;
}
.groupImg {
  height: 90px;
  width: 90px;
  border-radius: 50%;
  overflow: hidden;
}
.name_desc {
  text-align: center;
  max-width: 120px;
}
.name_desc p {
  font-size: 18px;
  font-weight: 600;
}
.name_desc span {
  font-size: 12px;
  color: #777777;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.CheckboxCont {
  display: flex;
  justify-content: flex-start;
}
