.training_header_card_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  width: 100%;
  border-radius: 10px;
  border: 1px solid #c7c7c7;
  overflow: hidden;
}

.training_header_card_img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.training_header_card_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.training_header_card_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  padding: 20px;
}

.training_header_card_title {
  font-size: 30px;
  font-weight: 600;
  color: #555;
}

.training_header_card_description {
  font-size: 18px;
  color: #777;
}

.training_header_card_button {
  padding: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
