.containerUsersList {
  background-color: #f3f8fa;
  height: 70vh;
  overflow-x: none;
  overflow-y: auto;
}

.usersListHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #dadada;
}

.usersListTitle {
  color: #103353;
  font-size: 1.2rem;
  font-weight: 600;
}

.newMsg {
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
  padding: 4px 10px;
  background-color: #e7f4f6;
  color: #1b9cfe;
  font-size: 0.8rem;
  font-weight: 600;
  border-radius: 6px;
}

.newMsg img {
  width: 16px;
  height: 16px;
}

.usersSearch {
  margin: 20px;
  padding: 12px;
  display: flex;
  gap: 10px;
  align-items: center;
  background-color: #fff;
  border-radius: 12px;
}

.searchInput {
  border: none;
  outline: none;
  width: 100%;
  font-size: 1rem;
  color: #103353;
}

.usersList {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.card {
  background-color: #fff;
  padding: 1rem;
  cursor: pointer;
  border-radius: 1rem;
  min-height: 100px;
  position: relative;
}

.selected {
  color: #fff !important;
  background-color: #24b3b9;
}

.card:hover {
  background-color: #24b3b950;
}

.selected:hover {
  background-color: #24b3b9;
}

.cardContent {
  display: flex;
  gap: 10px;
  align-items: flex-start;
}

.cardImg {
  min-width: 50px;
  max-width: 50px;
  min-height: 50px;
  max-height: 50px;
}

.cardImg img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.contentText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.8rem;
  margin: 0 10px;
  width: 100%;
}

.chatName {
  font-size: 1rem;
  color: #103353;
  font-weight: 600;
}

.chatEmail {
  font-size: 12px;
  color: #8b8b8b;
}
.email_wrap {
  white-space: pre-line;
}
.selected .chatName,
.selected .chatEmail {
  color: #fff;
}

.cardStatus {
  margin-top: 10px;
  width: 20px;
  height: 20px;
}

body[dir="rtl"] .cardStatus {
  float: left;
}

body[dir="ltr"] .cardStatus {
  float: right;
}

.cardStatus img {
  width: 100%;
  height: 100%;
}

@media (max-width: 1023px) {
  .chatEmail {
    width: 200px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .containerUsersList {
    height: 40vh;
    margin-bottom: 10px;
    padding: 10px;
  }

  .usersListTitle {
    font-size: 18px;
  }
}

@media (max-width: 767px) {
  .containerUsersList {
    height: 40vh;
    margin-bottom: 10px;
    padding: 10px;
  }

  .usersListTitle {
    font-size: 18px;
  }
}
.new-message {
  width: 0.5rem;
  height: 0.5rem;
  background-color: #1cacb7;
  border-radius: 50%;
}
