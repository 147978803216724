.tajah-list-exams-answers-header {
    background-color: white;
    border-radius: 20px;
    border-color: black;
    padding: 40px;
    margin: 100px;
    justify-content: center;
    justify-items: center;
}

.line{
    width: 100%;
    border-bottom: 1px solid gray;
    margin-top:10px;
    margin-bottom:10px;
}

.tajah-exams-result-exam-title{
    font-size: 22px;
    color: black;
}
.tajah-exams-result-question{

}

.tajah-exams-result-question-answers{
    background-color: #EFEFEF;
    border-radius: 20px;
    border-color: black;
    padding: 40px;
    margin-top: 10px;
    margin-bottom: 20px;
}
