.FAQ-from {
  margin-bottom: 25px;
}

.FAQ-from select {
  height: 44px !important;
}
.FAQ-from input[type="text"] {
  border: none;
  background: #dddddd;
  height: 34px;
  border-radius: 10px;
  padding: 0 10px;
}
.FAQ-from input[type="text"]::after {
  content: "\f002";
}
.FAQ-from form {
  padding-top: 10px;
}
.FAQ-from input[type="submit"] {
  padding: 5px 35px;
  color: #fff;
  background: #006d77;
  border: none;
  margin: 0 12px;
  border-radius: 10px;
  cursor: pointer;
}
.box_faq {
  display: block;
  width: 100%;
  padding: 30px 25px;
  padding-top: 0;
}
.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: transparent;
  border-bottom: none;
}
.btn.btn-link::after {
  content: "\f078";
  font-family: "FontAwesome";
  font-size: 11px;
  position: absolute;
  right: 0;
}
body[dir="rtl"] .btn.btn-link::after {
  left: 0;
  right: unset;
}
/* .btn.btn-link.collapsed:after{
    content: "\f077";
    font-family: "FontAwesome";
  } */
.box_faq .card-header .btn.btn-link {
  font-size: 16px;
  width: 100%;
  justify-content: flex-start;
  color: #333;
  padding-left: 0;
  padding-right: 0;
}
.box_faq .card-header .btn.btn-link:hover {
  text-decoration: none;
}
.box_faq .card {
  border: none;
  border-bottom: 1px solid #d3d2d2;
  border-radius: 0;
  padding-bottom: 15px;
  flex-direction: column;
}
.box_faq .card-header {
  padding-left: 0;
  padding-right: 0;
  position: relative;
}
.box_faq .card-body {
  font-size: 14px;
  color: #727272;
}

.box_faq .card-body img {
  width: 100%;
  height: auto;
}
.table_bank_tran.faq_box_white {
  width: 65%;
  margin: unset;
}
.btn-tickets {
  justify-content: flex-end;
  align-items: center;
  display: flex;
}
.btn-tickets a,
.btn-tickets a:hover {
  padding: 5px 35px;
  color: #fff;
  background: #006d77;
  border: none;
  margin: 0 12px;
  border-radius: 10px;
  cursor: pointer;
  margin: 0;
}
.btn_add_question,
.btn_add_question:hover {
  display: flex;
  position: absolute;
  right: 15px;
  padding: 5px 35px;
  color: #fff !important;
  background: #006d77;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 15px;
  cursor: pointer;
}
body[dir="rtl"] .btn_add_question {
  right: unset;
  left: 15px;
}
.btn_edit,
.btn_edit:hover {
  top: 22px;
  font-size: 13px;
  padding: 2px 15px;
}
.btn_add_question.btn_trush {
  background-color: transparent;
  right: 75px;
  top: 20px;
  cursor: pointer;
}
.btn_add_question.btn_trush i {
  color: #b92727;
  font-size: 12px;
  padding: 4px;
  background: #e3e3e3;
  border-radius: 5px;
}
body[dir="rtl"] .btn_add_question.btn_trush {
  right: unset;
  left: 75px;
}
.btn_edit i {
  color: #fff;
  padding: 0 5px;
  line-height: 2;
  font-size: 10px;
}

.faq-list-pg .header_profile {
  background: #011e20;
  padding: 15px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  padding-top: 50px;
}
.faq-list-pg .profile_wrapper {
  display: flex;
  width: 100%;
  height: auto;
  border-radius: 15px;
  margin: 0 auto;
  position: relative;
  justify-content: center;
  flex-direction: column;
  padding: 0 15px;
}
.faq-list-pg .profile_wrapper .profile_grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 50px;
  gap: 50px;
  margin: 20px 0;
  padding: 0 15px;
}
.faq-list-pg .profile_dhead {
  display: flex;
  align-items: center;
  margin: 0;
}
.faq-list-pg .profile_wrapper {
  display: flex;
  width: 100%;
  height: auto;
  border-radius: 15px;
  margin: 0 auto;
  position: relative;
  justify-content: center;
  flex-direction: column;
  padding: 0 15px;
}
.faq-list-pg .admin_label {
  display: flex;
  margin: 30px 0;
}
.faq-list-pg .admin_add_input {
  display: flex;
  background-color: transparent;
  color: #000;
  -webkit-backdrop-filter: blur(100px);
  backdrop-filter: blur(100px);
  position: relative;
  border: none;
  border-bottom: 1px solid #ccc;
  width: 90%;
  height: 44px;
}
.faq-list-pg .FAQ-from input[type="text"] {
  border: none;
  background: #ddd;
  height: 34px;
  border-radius: 10px;
  padding: 0 10px;
}
.faq-list-pg .FAQ-from {
  margin-bottom: 25px;
}
.faq-list-pg .table_bank_tran.faq_box_white {
  background-color: #fff;
  padding-bottom: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
}
.faq-list-pg .table_header {
  padding: 15px 25px;
}

.faq-list-pg .btn-link {
  white-space: pre-wrap;
  text-align: initial;
}
@media (max-width: 991px) {
  .table_bank_tran.faq_box_white {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .faq-list-pg .profile_dhead {
    justify-content: flex-start;
    padding: 0 !important;
  }
}
@media (max-width: 767px) {
  .faq-list-pg .header_profile {
    padding: 0;
  }
  .btn_add_question.btn_edit,
  .btn_add_question.btn_trush {
    position: relative;
    display: inline-block;
    top: 0;
    left: unset !important;
    right: unset !important;
    padding: 2px 14px;
  }
  .card-header .btn.btn-link {
    white-space: normal;
    word-break: break-all;
  }
  .box_faq {
    padding: 30px 0px;
  }
  .FAQ-box .container-fluid.profile_wrapper {
    padding: 0;
  }
  .FAQ-from form {
    padding-top: 56px;
  }
  .FAQ-from input[type="text"] {
    margin-bottom: 10px;
  }
  .table_header {
    padding: 0;
  }
}

@media only screen and (max-width: 380px) {
  .profile_dhead h3 {
    font-size: 14px;
  }
  .btn-tickets a {
    font-size: 14px;
    padding: 5px 20px;
  }
  .faq-list-pg .profile_wrapper .profile_grid {
    gap: 10px;
  }
}
