.left-chart,
.right-chart {
  display: inline;
  min-height: 100%;
  width: 100%;
  padding: 20px;
  background: #fff;
  margin: 10px 0 50px;
  box-shadow: 2px 2px 10px #ddd;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.left-chart {
  float: left;
}
.right-chart {
  float: right;
}
.wrapper-chart {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.w-500 {
  width: 437px !important;
  margin: auto;
}

.setting-admin {
  position: fixed;
  top: 150px;
  right: 0px;
  background-color: #fff !important;
  border: 3px solid rgb(20, 148, 154);
  display: flex;
  align-items: center;
  z-index: 1;
}

.card-setting {
  position: fixed;
  top: 196px;
  right: 0px;
  min-width: 300px;
  box-shadow: 1px 6px 10px #ddd;
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  z-index: 1;
}

body[dir="rtl"] .card-setting {
  right: unset;
  left: 0px;
}

body[dir="rtl"] .setting-admin {
  right: unset;
  left: 0px;
}

.setting-admin svg {
  width: 40px;
  padding: 10px;
  cursor: pointer;
}

.radio-card{
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin-top: 10px;
}

.radio-card label{
  margin: auto 10px;
}

.animate {
  animation-name: rotate;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  border-radius: 50%;
}

@keyframes rotate{
  from{ transform: rotate(-360deg); }
  to{ transform: rotate(360deg); }
}

@media (max-width: 991px) {
  .w-500 {
    width: auto !important;
    margin: auto;
  }
}

@media (max-width: 768px) {
  .wrapper-chart {
    display: block;
  }
  .left-chart,
  .right-chart {
    display: block;
    min-height: 100%;
    width: 100%;
    padding: 20px;
    background: #fff;
    margin: 10px 0 50px;
    box-shadow: 2px 2px 10px #ddd;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }

  .left-chart {
    float: unset;
  }
  .right-chart {
    float: unset;
  }
}
