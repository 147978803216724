.home_course_title {
  font-weight: bold;
  font-size: x-large;
  margin-top: 10px;
  margin-bottom: -4px;
  color: #484e4e;
}
.home_course_title_dep {
  font-size: x-large;
  color: #484e4e;
}
.home_course_desc_dep {
  margin: 15px 0;
  width: 80%;
  color: #acabab;
  line-height: 28px;
}

.courses_card_grid {
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

@media (max-width: 991px) {
  .courses_card_grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 50px;
    gap: 20px;
  }
}

.specialty_container {
  display: flex;
  flex-wrap: nowrap;
  gap: 60px;
  padding: 0rem 5rem;
  overflow-x: auto;
  width: 100%;
}
.specialty_tab {
  padding: 1.2rem 1rem;
  height: 100%;
  font-weight: 600;
  font-size: 14px;
  white-space: nowrap;
  width: fit-content;
  cursor: pointer;
}
.selected-tab {
  border-bottom: 3px solid #025299;
  color: #025299;
}
.loading__container {
  padding: 20px 0;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.search_container {
  display: flex;
  gap: 20px;
  flex: 3 1 auto;
}
.title_search {
  padding: 20px 10px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.title {
  font-size: 22px;
  font-weight: 600;
  color: #000000;
}
.search-box {
  display: flex;
  gap: 20px;
  width: 100%;
  background-color: #ffffff;
  padding: 10px 45px;
  border-radius: 10px;
  box-shadow: 2px 2px 10px #ddd;
}
.searchbtn {
  width: 120px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
}

@media (max-width: 767px) {
  .courses_card_grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 50px;
    gap: 20px;
  }
  .title_search {
    flex-direction: column;
    gap: 20px;
  }
  .specialty_container {
    padding: 0rem 1rem;
  }
  .specialty_tab {
    font-size: 12px;
  }
}
.advanceSearch {
  white-space: nowrap;
}
