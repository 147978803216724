.tax-list {
  padding: 2rem 0;
}

.tax-list__container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.tax-list__table-name {
  width: 150px;
  white-space: pre-wrap;
}
.certificate-desc {
  max-width: 300px;
  word-break: break-word;
  white-space: pre-wrap;
}

.course {
  padding: 2rem 0;
}

.course__container,
.course__form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.course__manage-content {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.course__warning__note {
  background-color: #df4b43;
  color: #fff;
  padding: 0.4rem 1rem;
  border-radius: 6px;
  margin: 0.4rem 0;
  width: fit-content;
  font-size: 0.9rem;
}

.course__form__fields {
  margin: 0 -15px;
}

.course__form__notes {
  margin-bottom: 2rem;
}
.course__form__notes p {
  color: #777777;
  line-height: 2rem;
}

.course__form__field {
  margin-bottom: 2rem;
}

.course__form__field label {
  color: #777777;
}

.course__form__field-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
}

.course__form__field-actions button {
  background-color: #046c77;
  border-radius: 6px;
  border: none;
  padding: 0 1rem;
  height: 50px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.course__form__field-actions-submit {
  padding: 1rem 8rem;
  margin-top: 2rem;
}
.course__form__field-actions-submit[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}
.course__form__field-actions-view {
  width: 50px;
}

.course__form__submit__container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1rem;
}

.course__form__submit__container button {
  padding: 0.7rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  width: 30%;
}

.course__form-error {
  margin: 0 !important;
  padding: 0 !important;
}

.course__form__field-mode {
  color: #777777;
}

@media only screen and (max-width: 480px) {
  .course__form__field-actions-submit {
    width: 100%;
  }
}
