.header_profile {
  background: #011e20;
  padding: 15px 0;
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  padding-top: 60px;
}

.btn_container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
}

.smallCardContainer {
  padding: 0px;
  margin-top: 5px;
}

.containerCard {
  padding-top: 45px;
  padding-bottom: 30px;
  justify-content: center;
}

.profile_wrapper {
  display: flex;
  width: 100%;
  height: auto;
  border-radius: 15px;
  margin: 0 auto;
  position: relative;
  justify-content: center;
  flex-direction: column;
  padding: 0 15px;
}

.profile_wrapper .profile_grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
  margin: 20px 0;
}

.profile_dhead {
  display: flex;
  align-items: center;
}

.profile_dimage {
  background-image: url("../../../../assets/image/team7.png");
  color: #000;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d4d4d4;
  border-radius: 50%;
  object-fit: cover;
  background-position: center;
  background-size: cover;
}

.profile_dname {
  margin: 0;
  flex: 1;
}
.profile_dtop {
  font-size: 25px;
  text-align: initial;
}

.profile_dbottom {
  font-size: 18px;
}

.profile_head_soical {
  font-size: 10px;
  display: flex;
  justify-content: space-between;
}

.profile_soical_img {
  width: 30px;
  padding: 0 5px;
}

.training_head {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 103%;
}

.training_head .progress {
  margin: 0 !important;
}

.profile_level {
  font-size: 14px;
}

.profile_level_in {
  font-size: 18px;
}

.profile_btn {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
}

.profile_inside_btn {
  width: 100%;
  /* margin: 10px; */
  font-size: 12px;
  background-color: #006d77;
  color: #fff;
  border-radius: 15px;
  outline: none;
  box-shadow: none;
  overflow: hidden;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
}

.trainers_dimage {
  color: #000;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  object-fit: cover;
  background-position: center;
  background-size: cover;
}

.training_circle_progress {
  width: 80px;
  margin: 0 20px;
}

@media (max-width: 1199px) {
  .header_profile {
    padding: 10px 0;
  }

  .profile_dimage {
    color: #000;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #d4d4d4;
    border-radius: 50%;
  }
}

@media (max-width: 991px) {
  .profile_grid {
    grid-template-columns: repeat(1, 1fr);
  }
  .profile_dhead {
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .profile_btn {
    justify-content: center;
  }

  .profile_dimage {
    color: #000;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #d4d4d4;
    border-radius: 50%;
  }

  .profile_dtop {
    font-size: 22px;
  }
  .profile_dbottom {
    font-size: 16px;
  }

  .training_head {
    display: flex;
    justify-content: baseline;
    align-items: center;
    width: 100%;
  }
  .header_profile {
    padding: 20px 15px !important;
  }
  .profile_info {
    order: 3;
    padding: 0 !important;
  }
  .profile_info .containerCard {
    padding: 0;
    justify-content: space-between;
  }
  .header_profile {
    height: auto;
  }
  .profile_wrapper > .row {
    justify-content: space-between;
  }
  .profile_btn {
    padding: 0;
  }
  .profile_dhead {
    padding-left: 0;
  }
}

@media (max-width: 767px) {
  .profile_dhead {
    margin: 10px 0;
  }
  .complete-profile .training_head {
    padding: 0;
  }

  .profile_dtop {
    font-size: 18px;
  }
  .profile_dbottom {
    font-size: 14px;
  }

  .profile_head_soical {
    margin: 0px 0 5px;
  }

  .profile_level_in {
    font-size: 14px;
  }

  .profile_inside_btn {
    font-size: 14px;
  }
  .card_component_container {
    margin: 0 !important;
  }
  .smallCardContainer {
    padding: 5px !important;
  }
}

@media only screen and (max-width: 480px) {
  .trainers_dimage {
    width: 40px;
    height: 40px;
  }
  .profile_dtop {
    font-size: 12px;
  }
  .profile_inside_btn {
    padding: 6px;
    font-size: 12px;
  }
  .profile_dhead {
    padding: 10px !important;
  }
}
