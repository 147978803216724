.training-course-content {
  display: flex;
  gap: 2rem;
  align-items: flex-start;
}
/* .back_title {
  font-size: 12px;
} */

.syllabus {
  min-width: 25%;
  max-width: 25%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 100%;
}

.syllabus-header {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 1rem;
  border-bottom: 1px solid #c8c8c8;
  padding-bottom: 0.5rem;
}

.syllabus-header-image {
  width: 100%;
  height: 300px;
  max-width: 424px;
}

.syllabus-header-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6px;
}

.syllabus-header-details {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}

.syllabus-header-details h2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #555;
}
.course-details-title-new {
  font-size: 1.5rem;
  font-weight: 600;
  color: #555;
  margin-top: -40px;
  margin-bottom: 30px;
  margin-right: 1rem;
  margin-left: 1rem;
}

.syllabus-header-details p {
  font-size: 16px;
  font-weight: 400;
  color: #555;
}

.syllabus-body {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.syllabus-body-sub-chapter {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  padding-bottom: 2rem;
}

.syllabus-body-sub-chapter-icon {
  width: 1.4rem;
  height: 1.4rem;
}

.syllabus-body-sub-chapter-icon img {
  width: 100%;
  height: 100%;
}

body[dir="ltr"] .syllabus-body-sub-chapter-icon img {
  transform: rotate(180deg);
}

.syllabus-body-sub-chapter h3 {
  font-size: 1.2rem;
  font-weight: 600;
  color: #555;
  padding-bottom: 0.3rem;
}
.course-title-sidebar {
  font-size: 1.2rem;
  font-weight: 600;
  color: #555;
  padding-bottom: 0.3rem;
}

.syllabus-body-lessons {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 500px;
}
@media (max-width: 800px) {
  .syllabus-body-lessons {
    height: 240px;
  }
}

.syllabus-body-lessons-item {
  display: flex;
  align-items: flex-start;
  gap: 0rem;
  cursor: pointer;
  padding: 0.5rem 0;
}

.syllabus-body-lessons-item-selected {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  cursor: pointer;
  border-bottom: 2px solid #21a6ac;
  padding: 1rem 0;
}

.syllabus-body-lessons-item:hover {
  background-color: #f5f5f5;
}

.syllabus-body-lessons-item-number {
  font-size: 1.2rem;
  font-weight: 600;
  color: #555;
  min-width: 2rem;
}

.syllabus-body-lessons-item-name {
  font-size: 1rem;
  font-weight: 500;
  color: #555;
  padding-top: 0.2rem;
}

.course-details {
  width: 75%;
  margin-top: 4rem;
}

.course-details-item {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  overflow-x: hidden;
}
.loading__container {
  height: 50%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card_title {
  min-height: 3rem;
  font-size: 14px;
  color: #0a737c;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.card_title-inner {
  min-height: 34px !important;
  font-size: 12px !important;
  color: #0a737c !important;
}

body[dir="rtl"] .course-details-item {
  padding-left: 2rem;
}

body[dir="ltr"] .course-details-item {
  padding-right: 2rem;
}

.course-details-item:not(:last-child) {
  border-bottom: 1px solid #c8c8c8;
  padding-bottom: 2rem;
}

.course-details-item:not(:first-child) {
  padding-top: 1rem;
}

.course-details-title h3 {
  font-size: 1.3rem;
  font-weight: 600;
  color: #555;
  margin-bottom: 0.2rem;
  margin-right: 1rem;
  margin-left: 1rem;
}

.course-details-title p {
  font-size: 1.1rem;
  font-weight: 400;
  color: #555;
}

.course-slider {
  display: flex;
  gap: 1rem;
  overflow-x: auto;
  padding: 1rem 1rem;
}

.course-slider-item {
  width: 200px;
  min-width: 200px;
  height: 150px;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  /* padding: 0.4rem; */
  border: 1px solid #c8c8c8;
  border-radius: 0.5rem;
  cursor: pointer;
  justify-content: space-between;
}
.corner-img {
  background-color: #ffffff00;
  display: flex;
  flex-direction: row-reverse;
}
.corner-img img {
  width: 30%;
}
.course-slider-item:hover {
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 0px,
    rgba(6, 24, 44, 0.65) 0px 1px 4px 4px,
    rgba(255, 255, 255, 0.08) 0px 3px 0px inset;
}

.course-slider-item-header {
  display: flex;
  gap: 0.5rem;
  background-color: #eeeeee;
  border-radius: 6px;
  padding: 3px 7px;
  align-items: center;
  justify-content: space-between;
}

.course-slider-item-header-inner {
  display: flex;
  font-size: 0.5rem;
  gap: 0.5rem;
  background-color: #eeeeee;
  border-radius: 6px;
  padding: 3px 3px;
  align-items: center;
  justify-content: space-between;
}

.watched,
.not-watched,
.answered {
  min-width: 0.8rem;
  min-height: 0.8rem;
  border-radius: 50%;
  margin-bottom: auto;
  margin-top: 0.4rem;
}

.watched {
  background-color: #21a6ac;
}

.not-watched {
  background-color: #d19513;
}

.answered {
  background-color: #046c77;
}

.course-slider-item-header img {
  width: 1.5rem;
  height: 1.5rem;
  margin-top: 0.4rem;
}

.course-slider-item h4 {
  font-size: 1.1rem;
  font-weight: 600;
  color: #046c77;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  min-height: 3rem;
}

.course-slider-item-content,
.course-slider-item-content > *,
.course-slider-item-content > * > * {
  font-size: 0.6rem !important;
  font-weight: 400 !important;
  overflow: hidden !important;
  padding: 2px;
}
.course-slider-item-content-inner,
.course-slider-item-content-inner > *,
.course-slider-item-content-inner > * > * {
  font-size: 7px !important;
  /* font-weight: 400 !important; */
  overflow: hidden !important;
  padding: 1px;
}
.course-slider-item-content-inner-body,
.course-slider-item-content-inner-body > *,
.course-slider-item-content-inner-body > * > * {
  font-size: 14px !important;

  /* padding: 15px; */
}
.content-icon-container {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}
.content-icon-container img {
  width: 50px;
  height: 50px;
  /* padding-top: 5px; */
}
.content-icon-container-inner {
  display: flex;
  justify-content: center;
  padding-top: 5px;
}
.content-icon-container-inner img {
  width: 25px;
  height: 25px;
  /* padding-top: 5px; */
}

.course-slider-model-container {
  width: 60%;
  height: 95%;
  z-index: 10000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* overflow-y: scroll; */
}

.course-slider-model {
  overflow-y: scroll;
}

.course-slider-model-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem;
  border-bottom: 1px solid #ddd;
}

.course-slider-model-title {
  font-size: 1.4rem;
  font-weight: 600;
  color: #046c77;
}

.course-slider-model-exit {
  cursor: pointer;
  width: 2rem;
  height: 2rem;
}

.course-slider-model-body {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.course-slider-model-body-slides {
  display: flex;
  gap: 1rem;
  overflow-x: auto;
  padding: 1rem;
}
.stars-font-size::before {
  font-size: 1rem;
}
.rate-now-btn {
  padding: 0.2rem 0.6rem;
  font-size: 0.8rem;
}
.training-course-content-rating {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.course-slider-model-body-slides-item {
  width: 150px;
  min-width: 150px;
  height: 100px;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  /* padding: 0.6rem; */
  border: 1px solid #c8c8c8;
  border-radius: 0.5rem;
  cursor: pointer;
}

.course-slider-model-body-slides-item:hover {
  border: 1px solid #555;
}

.course-slider-model-body-slides-item-selected {
  border: 1px solid #21a6ac;
}

.course-slider-model-body-slides-item h4 {
  font-size: 0.6rem;
  font-weight: 600;
  color: #046c77;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  min-height: 1.5rem;
}

.course-slider-model-body-slides-item p {
  font-size: 0.8rem;
  font-weight: 400;
  color: #555;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.course-slider-model-body-slide-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 1rem;
  width: 100%;
}

.course-slider-model-body-slide-arrow-left,
.course-slider-model-body-slide-arrow-right {
  cursor: pointer;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background-color: #eeeeee;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.course-slider-model-body-slide-arrow-left img,
.course-slider-model-body-slide-arrow-right img {
  width: 100%;
  height: 100%;
}

body[dir="rtl"] .course-slider-model-body-slide-arrow-right img {
  transform: rotate(180deg);
}

body[dir="ltr"] .course-slider-model-body-slide-arrow-left img {
  transform: rotate(180deg);
}

.course-slider-model-body-slide {
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid #c8c8c8;
  border-radius: 0.5rem;
  overflow-y: auto;
}

.course-slider-model-body-slide-item {
  display: none;
  padding: 1rem;
}

.course-slider-model-body-slide-item-selected {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  height: 100%;
}

.course-slider-model-body-slide-item-selected h4 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #555;
}
/* .course-slider-model-body-slide h4 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #555;
} */

.course-slider-model-body-slide p {
  font-size: 1.2rem;
  font-weight: 400;
  color: #555;
}

.main-box__head__actions {
  display: flex;
  align-items: center;
  gap: 1rem;
  overflow-x: auto;
  white-space: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin-right: 1rem;
  margin-left: 1rem;
}

.main-box__head__actions::-webkit-scrollbar {
  display: none;
}

.main-box__head__action__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
}

.activities-list {
  padding: 2rem;
}

.activities-list__container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.replays_container {
  display: flex;
  width: 100%;
  border-top: 1px solid #c9c8c8;
  padding-top: 10px;
}
.replays_button {
  display: flex;
  width: 50%;
  justify-content: center;
  color: rgb(13 148 136);
}
.button_split_div {
  width: 1px;
  background-color: #c9c8c8;
}
.button_img {
  width: 25px;
  height: 25px;
}

.comment_form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 20px;
}
.action_button_comment {
  display: flex;
  flex-direction: row-reverse;
  gap: 20px;
  padding-top: 20px;
}

.course-slider-zoom-item {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.course-slider-zoom-item-inner {
  display: flex;
  align-items: center;
  width: 100%;
}

.course-slider-zoom-item-inner-title {
  font-size: 1.2rem;
  font-weight: 600;
  color: #555;
  width: 10rem;
}

.course-slider-zoom-item-inner-value {
  font-size: 1.2rem;
  font-weight: 400;
  color: #555;
}

.course-slider-zoom-item-inner-btn {
  width: fit-content;
  padding: 1rem 6rem;
}

@media (max-width: 767px) {
  .training-course-content {
    display: flex;
    flex-direction: column;
  }

  .syllabus,
  .course-details {
    min-width: 100%;
  }
}

@media (max-width: 991px) {
  .course-slider-model-container {
    width: 100%;
  }
}
