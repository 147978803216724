.Container {
  overflow: hidden;

  ul {
    /* display: grid; */
    /* grid-gap: 10px; */
    /* grid-template-columns: repeat(var(--columns, 1), 1fr); */
    list-style: none;
    /* padding: 20px; */
    margin: 0;
  }
}

.Header {
  display: flex;
  padding: 5px 20px;
  padding-right: 8px;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);

  &:hover {
    .Actions > * {
      opacity: 1 !important;
    }
  }
}

.Actions {
  display: flex;

  > *:first-child:not(:last-child) {
    opacity: 0;

    &:focus-visible {
      opacity: 1;
    }
  }
}
