.textarea-field {
  width: 100%;
}

.textarea-field textarea {
  width: 100%;
  padding: 0 1rem;
  border: 1px solid #ebebeb;
  background-color: #ffffff;
  border-radius: 6px;
  color: #777777;
  resize: unset;
}

.textarea-field textarea:focus {
  outline: none;
}
.textarea-field textarea::placeholder {
  color: #b1b1b1;
}

.textarea-field label {
  color: #777777;
}

.textarea-field-error {
  margin: 0 !important;
  padding: 0 !important;
}
