.uk-section {
  background-color: unset;
  max-width: 80%;
  margin: 0 auto;
}
.uk-section .slick-dots {
  bottom: -65px;
}
.uk-section .slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: #025299;
}
.slick-dots li button:before {
  font-size: 10px;
}
@media (max-width: 700px) {
  .uk-section {
    max-width: 100%;
  }
}
