.messagesContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  overflow: auto;
  min-height: 85%;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #c7c7c7;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.messageContainer {
  position: relative;
}

.left {
  float: left;
}

.right {
  float: right;
}

.content-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.message-image {
  width: 40px;
  height: 40px;
}

.message-image img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.message-content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.date {
  font-size: 0.7rem;
  font-weight: 500;
  color: #bdbdbd;
}

.message-text {
  padding: 0.8rem 1.5rem;
  white-space: normal;
}

.right .message-text {
  background-color: #24b3b9;
  color: white;
  border-radius: 24px 24px 0 24px;
}

.left .message-text {
  background-color: #f3fbfb;
  color: #103353;
  border-radius: 24px 24px 24px 0;
}
