.courses_card_grid {
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.courses_card_header {
}

.no_courses {
  font-size: 16px;
  font-weight: 600;
  color: #979797;
  text-align: center;
  margin-top: 100px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-direction: column;
  grid-column: 1 / -1;
}
.no_courses img {
  width: 120px;
}

.courses_card_header h2 {
  font-size: 22px;
  font-weight: 600;
  color: #000000;
}

@media (max-width: 991px) {
  .courses_card_grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 50px;
    gap: 20px;
    /* margin: 40px 0; */
  }
}

@media (max-width: 767px) {
  .courses_card_grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 50px;
    gap: 20px;
    /* margin: 40px 0; */
  }
}
