.course-details-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  border: 1px solid #cfcfcf;
  border-radius: 6px;
}

.course-details-header-opened {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  border-bottom: 1px solid #cfcfcf;
}

.course-details-header__title,
.course-details-header__title-opened {
  font-size: 1.5rem;
  font-weight: 600;
  color: #777;
  z-index: unset;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.course-details-header__title {
  color: #777;
}

.course-details-header__title-opened {
  color: #046c77;
}

.course-details-header__title__badge {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;
  width: fit-content;
  padding: 0.2rem 1rem;
  border-radius: 9999px;
  font-size: 1rem;
  font-weight: 500;
}

.course-details-header__title__badge__text {
  padding-bottom: 0.2rem;
}

.course-details-header__title__badge__icon {
  width: 1.2rem;
  height: 1.2rem;
}

.course-details-header__title__badge__icon img {
  width: 100%;
  height: 100%;
}

.course-details-header__title__input {
  width: 90%;
  border: none;
  border-bottom: 1px solid #555;
  background-color: transparent;
  font-size: 1.5rem;
  font-weight: 600;
  color: #777;
}

.course-details-header__progress-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.course-details-header__actions-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.course-details-header__actions {
  display: flex;
  gap: 1rem;
  overflow-x: auto;
  white-space: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;
  min-width: fit-content;
}

.course-details-header__actions__action {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.course-details-header__actions__action__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.2rem;
  height: 1.2rem;
}

.course-details-header__actions__action__icon img {
  width: 100%;
  height: 100%;
}

.course-details-header__arrow {
  cursor: pointer;
}

@media (max-width: 768px) {
  .course-details-header,
  .course-details-header-opened {
    padding: 1rem;
    flex-direction: column;
    gap: 1rem;
  }

  .course-details-header__title,
  .course-details-header__title-opened {
    font-size: 1.2rem;
    text-align: center;
  }

  .course-details-header__actions {
    gap: 0.6rem;
    flex-wrap: wrap;
  }

  /* .course-details-header__actions__action {
    width: 100%;
  } */
}
.main-box__head__action {
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 2px solid #24b3b9; */
  width: 50px;
  height: 50px;
  border-radius: 6px;
  cursor: pointer;
  min-width: 50px;
}
