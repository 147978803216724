/* Treainer Details Section */

.trainers_title {
  background: #011e20;
  padding: 50px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
}

.trainers_wrapper {
  display: flex;
  width: 100%;
  height: auto;
  border-radius: 15px;
  margin: 0 auto;
  position: relative;
  justify-content: center;
  flex-direction: column;
  padding: 0 15px;
}

.trainers_details {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.trainers_dhead {
  display: flex;
  align-items: center;
}

.trainers_dimage {
  color: #000;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  object-fit: cover;
  background-position: center;
  background-size: cover;
  border: 1px solid white;
}

.trainers_icon {
  width: 70%;
  height: 70%;
}

.trainers_dname {
  margin: 0 20px;
}

.trainers_dtop {
  font-size: 25px;
  text-align: initial;
}

.trainers_dbottom {
  font-size: 18px;
}

.trainer_head_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0 15px;
}

.trainer_head_soical {
  font-size: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.trainer_overview_label {
  font-size: 25px;
  display: flex;
}

.trainer_overview_list {
  font-size: 18px;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  max-width: 100%;
  word-break: break-word;
}

.trainer_head_card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;
}

.trainer_details_grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
}

.training_soical_img {
  width: 30px;
  padding: 0 5px;
}

.trainer_head_soical {
  display: flex;
}

.container_download_file {
  width: 30%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.download_text {
  background-color: white;
  color: #006d77;
  text-decoration: underline;
  cursor: pointer;
  font-size: 15px;
}

@media (max-width: 1199px) {
  .trainers_dimage {
    color: #000;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #d4d4d4;
    border-radius: 50%;
    border: 1px solid white;
  }

  .trainers_icon {
    width: 40px;
  }

  .trainers_d_icon {
    color: #000;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #d4d4d4;
    border-radius: 50%;
  }

  .trainers_dtop,
  .trainer_overview_label {
    font-size: 22px;
  }
  .trainers_dbottom {
    font-size: 16px;
  }

  .trainer_overview_list {
    font-size: 14px;
    max-width: 100%;
    word-break: break-word;
  }
}

@media (max-width: 991px) {
  .trainer_details_grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 50px;
  }
  .trainers_dimage {
    color: #000;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #d4d4d4;
    border-radius: 50%;
    border: 1px solid white;
  }

  .trainers_icon {
    width: 30px;
  }

  .trainers_d_icon {
    color: #000;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #d4d4d4;
    border-radius: 50%;
  }

  .trainers_dtop,
  .trainer_overview_label {
    font-size: 18px;
  }
  .trainers_dbottom {
    font-size: 14px;
  }

  .trainer_overview_list {
    font-size: 12px;
    max-width: 100%;
    word-break: break-word;
  }
  .trainer_head_box {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .trainers_details {
    flex-direction: column;
    margin: 20px 0;
  }
  .trainer_details_grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 50px;
  }

  .trainers_title {
    padding: 0;
  }
  .trainers_dhead {
    margin: 10px 0;
  }
  .trainer_head_box {
    font-size: 12px;
    flex-direction: column-reverse;
  }
  .trainer_head_soical {
    margin: 0px 0 5px;
  }
}
.training_first_label {
  display: flex;
  font-size: 16px;
  padding: 30px 0 15px 0;
}
/* End Treainer Details Section */
.head_view {
  font-size: 16px;
  display: flex;
}
.arrow_seeall {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}

body[dir="rtl"] .arrow_seeall {
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  margin-top: -2px;
}

.arrow_icon {
  width: 8px;
  margin: 3px 10px 0;
}

.courses_card_grid {
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
@media (max-width: 1199px) {
  .head_view,
  .tracks_head_nav,
  .trainers_head_nav,
  .courses_card_category,
  .courses_card_third,
  .certification_main_top,
  .certification_text_bottom,
  .tracks_count,
  .trainers_name_type {
    font-size: 14px;
  }
}

@media (max-width: 991px) {
  .courses_card_grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 50px;
    gap: 20px;
    /* margin: 40px 0; */
  }
}

@media (max-width: 767px) {
  .courses_card_grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 50px;
    gap: 20px;
    /* margin: 40px 0; */
  }
}
