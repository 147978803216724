.activities-list__back-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.activities-list__back-button span {
  font-size: 1.2rem;
  font-weight: 500;
  color: #7c7c7c;
}
.maintext {
  font-size: 1.5rem;
  font-weight: 600;
  color: #025299;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
