.breadcrumbs-my-30 {
	padding-top: 45px;
	padding-bottom: 45px;
}

.body-faq {
	background-color: white;
	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
	padding: 30px;
	border-radius: 8px;
	margin: 10px auto 70px;
}

.header-faq {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-top: 20px;
	border-bottom: 2px solid #d4cccc;
	margin-bottom: 15px;
}

.header-faq h3 {
	color: #2e6a75;
	/* font-size: 34px; */
	margin-bottom: 0;
}

.header-faq .icons {
	display: flex;
	align-items: center;
}

.header-faq-q {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-top: 20px;
	padding-bottom: 50px;
}

.header-faq-q h3 {
	color: #2e6a75;
	font-size: 30px;
}

.header-faq-q .icons {
	display: flex;
	align-items: center;
}

.header-faq-end-user {
	display: flex;
	align-items: center;
	/* padding-bottom: 30px; */
	gap: 10px;
	cursor: pointer;
}

.pt-30 {
	padding-top: 30px;
}

.header-faq-end-user h3 {
	color: #2e6a75;
	font-size: 20px;
}

.header-faq-end-user .icons {
	display: flex;
	align-items: center;
}

.faq-item {
	border: 0;
	margin-bottom: 20px;
	/* overflow: hidden; */
	transition: max-height 0.6s ease-in-out;
	/* max-height: 70px; */
}

.faq-question {
	padding: 8px 15px;
	cursor: pointer;
	background-color: #fff;
	border-radius: 6px;
	border: 1px solid #ddd;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.faq-question p {
	margin-bottom: 0.5rem;
	font-size: 15px;
}

.faq-answer {
	padding: 10px;
	background-color: #fff;
	padding: 20px 20px 20px;
	color: #aaaaaa;
	font-size: 15px;
	margin-bottom: 0 !important;
}

.faq-item.active_faq {
	max-height: 100%;
	border-radius: 6px 6px 0px 0px;
	border: 1px solid #ddd;
}

.faq-item.active_faq .faq-question {
	background-color: #24b3b9;
	color: #fff !important;
	border-radius: 6px 6px 0px 0px;
	border: 0;
}

.faq-item .faq-question {
	color: #777777;
	font-weight: normal;
}

.cat_active_faq {
	max-height: 100%;
	border: 1px solid #ddd;
}

.d-flex-icons-faq {
	display: flex;
	align-items: center;
}

.MuiSwitch-colorPrimary {
	color: #c7c7c7;
}

/* For active switch */
.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
	color: #fff !important;
}

.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
	color: #2e6a75 !important;
}

.checkbox_zoom {
	height: 17px;
	width: 17px;
	transform: translate(0px, 5px);
}

.parg_zoom {
	display: inline-block;
	padding: 0 10px;
	margin: 0;
}

.dashes-line {
	width: 43%;
	border-bottom: 1px dashed #d0d0d0;
	margin: 10px 0;
}

.dashes-lines {
	width: 100%;
	border-bottom: 1px dashed #d0d0d0 !important;
}

.switch-on {
	color: #2e6a75;
}

.switch-off {
	color: #f1f1f1;
}

.add-question-btn span {
	font-weight: bold;
	margin-bottom: 2px;
}

@media (max-width: 1023px) {
	.faq-question p {
		margin-bottom: 0.3rem;
		font-size: 14px;
	}

	.faq-answer {
		padding: 20px 20px 20px;
		color: #808080;
		font-size: 14px;
	}

	/* .dashes-line {
      width: 35%;
    } */
}

@media (max-width: 767px) {
	/* .dashes-line {
      width: 25%;
    } */

	.faq-question {
		flex-direction: column;
		gap: 5px;
	}

	.j-center {
		justify-content: center;
	}

	.Toastify__toast-container--top-right {
		right: 0 !important;
	}
}
