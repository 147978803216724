.events-list {
  padding: 2rem 0;
}

.events-list__container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.system-users-list__table-email__wrapper {
  direction: ltr;
}
.system-users-list__table-email {
  color: #24b3b9;
  cursor: pointer;
}

.system-users-list__table-email:hover {
  color: #24b3b9 !important;
}
