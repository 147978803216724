.departments-trainees {
  padding: 2rem 0;
}

.departments-trainees__container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.departments-trainees__tables {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.departments-trainees__table-name,
.departments-trainees__table-desc {
  width: 300px;
  white-space: pre-wrap;
}

.departments-trainees__table-photo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.departments-trainees__table-photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.departments-trainees__table-certificate,
.departments-trainees__table-subscription {
  border-radius: 6px;
  height: 30px;
  padding: 0 15px;
}

.departments-trainees__table-certificate-no {
  background-color: rgb(232, 145, 145, 0.6);
  color: #e22020;
}

.departments-trainees__table-certificate-yes {
  background-color: rgb(193, 206, 226, 0.6);
  color: #8599b6;
}

.departments-trainees__table-subscription-yes {
  background-color: rgb(193, 226, 200, 0.6);
  color: #46bb3e;
}
.departments-trainees__table-subscription-no {
  background-color: rgb(193, 206, 226, 0.6);
  color: #8599b6;
}

.departments-trainees__table-payment-method {
}

.departments-trainees__table-payment-method--banktransfer {
  color: #24b3b9;
  border-bottom: 1px solid #24b3b9;
  cursor: pointer;
  -webkit-appearance: button;
  background-color: transparent;
  background-image: none;
}
.departments-trainees__table-email {
  color: #24b3b9;
  cursor: pointer;
}

.departments-trainees__table-email:hover {
  color: #24b3b9 !important;
}

.statics_container {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 1rem;
}

.reject-payment-modal__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem;
  border-bottom: 1px solid #ddd;
}

.reject-payment-modal__header h3 {
  font-size: 1.4rem;
  font-weight: 600;
  color: #046c77;
}

.reject-payment-modal__header img {
  cursor: pointer;
  width: 2rem;
  height: 2rem;
}

.reject-payment-modal__body {
  padding: 1.5rem;
}

.reject-payment-modal__submit {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.reject-payment-modal__submit-btn {
  padding: 1rem 6rem;
}

.payment-method--bank {
  color: #24b3b9;
  border-bottom: 1px solid #24b3b9;
  cursor: pointer;
  -webkit-appearance: button;
  background-color: transparent;
  background-image: none;
}

@media (min-width: 640px) {
  .statics_container {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (min-width: 1024px) {
  .statics_container {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}
