.trainer-courses {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.course-card {
  display: flex;
  justify-content: center;
}

@media (max-width: 1279px) {
  .trainer-courses {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
}

@media (max-width: 991px) {
  .trainer-courses {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
  }
}
