@media (max-width: 991px) {
  /* Home Page */
  .search_box_input .icon {
    top: 14%;
  }
  /* Home Page */

  /* Course  */
  .training-course-syllabus-container {
    flex-direction: column-reverse !important;
  }

  .training-course-tab-layout-tab {
    height: unset !important;
    font-size: 13px !important;
  }

  .training-course-syllabus-section-container-row-lesson-text {
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .training_info_img_name {
    justify-content: unset !important;
  }

  .personal_info_bottom {
    margin-top: 20px;
  }
  /* Course */
}

@media (max-width: 767px) {
  /* Login */
  .auth_main_box {
    height: fit-content;
    background-repeat: no-repeat;
  }
  /* Login */

  /* Admin */
  .important_actions_grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr) !important;
    grid-gap: 30px;
    grid-gap: 30px;
    gap: 30px;
    margin: 30px;
    font-size: 15px !important;
  }

  .important_actions_link {
    font-size: 14px !important;
  }

  .admin_num_icon {
    width: 40px !important;
  }

  .admin_forms {
    padding: unset !important;
    width: 80% !important;
  }

  .admin_input_label {
    font-size: 14px !important;
  }

  .validator_input_icon_container {
    position: absolute;
    right: 0;
  }

  .after_border {
    border-right: none !important ;
  }

  .border_bottom_res {
    border-bottom: 2px solid #ccc;
  }

  .modal_card_admin {
    width: unset !important;
  }

  .admin-header-table {
    flex-direction: column !important;
  }

  .admin-role-input,
  .custom_select {
    width: 50% !important;
  }
  /* Admin */

  /* edit profile */
  .input_hint_container {
    display: none;
  }

  .MuiSvgIcon-root-10 {
    font-size: unset !important;
  }

  .personal_info_top {
    margin-top: 20px;
  }

  .admin_add_input {
    height: 20px !important;
  }
  /* edit profile */
  .links {
    font-size: 13px !important;
    height: 50px !important;
    margin-top: 10px;
  }

  .nav.nav-tabs.profile {
    justify-content: center;
    align-items: center;
  }

  .profile_click_here {
    flex-direction: column;
    height: 100px;
  }

  .training-course-comment-body {
    padding: 0;
  }
}

@media (max-width: 430px) {
  .training-course-syllabus-section-container-row-lesson-content.opened {
    height: 100% !important;
    padding: 10px;
  }

  .training_info_img_name {
    justify-content: space-between !important;
  }
}
