.personal_info_card_st {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 50px 0;
}
.form-inline .form-check {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: auto;
  padding-left: 0;
}
.form-check-inline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}
.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}
.form-check-label {
  margin-bottom: 0;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}
.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip,
.form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip,
.form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.form-inline .form-check-input {
  position: relative;
  margin-top: 0;
  margin-right: 0.25rem;
  margin-left: 0;
}
.form-inline .form-check {
  width: 100%;
}

.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}
.personal_label_top {
  font-size: 20px;
  font-weight: bold;
}
.personal_checks {
  margin: 30px 0;
}
.personal_radio .form-check {
  padding: 0;
}
.second_radio {
  padding: 0 20px;
}
.personal_radio {
  display: flex;
}
.form-check {
  padding-left: 2.25rem;
}
.form-check-label {
  padding: 0 20px;
}
.personal_phone_info {
  font-size: 10px !important;
  display: flex;
  align-items: initial;
  margin-top: -40px;
  margin-bottom: 15px;
}
.personal_select_box {
  border: none;
  border-bottom: 1px solid #555;
  height: 23px;
  margin-bottom: 30px;
  font-size: 16px;
  margin-top: 5px;
}
.personal_delete_img {
  /* margin-top: 20px; */
  width: 40%;
  border: 2px solid #555;
  border-radius: 10px;
  padding: 5px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  background-color: transparent;
}
.personal_browse_img {
  width: 50%;
}
.personal_img_text {
  text-align: initial;
  margin: 20px;
}
.personal_browse {
  width: 50px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #006d77;
  border-radius: 50%;
  float: right;
  margin-left: 60px;
  margin-top: 60px;
  position: relative;
  z-index: 2;
  cursor: pointer;
}
body[dir="rtl"] .personal_browse {
  margin-left: 0px;
  margin-right: 60px;
}
.personal_dimage {
  background-image: url("../../../../assets/image/team7.png");
  color: #000;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d4d4d4;
  border-radius: 50%;
  object-fit: cover;
  background-position: center;
  background-size: cover;
}
.personal_img {
  display: flex;
  margin: 30px 0;
}
.perosnal_text_img {
  display: flex;
  font-size: 25px;
}
.personal_button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px 20px;
  background-color: #006d77;
  border-radius: 15px;
  color: #fff;
  font-size: 16px;
  border: none;
  outline: none;
}
.form-group {
  text-align: initial;
}
.personal_input_in {
  width: 100%;
  border: none;
  border-bottom: 1px solid #555;
  background-color: transparent;
  font-size: 17px;
  margin-bottom: 30px;
}
.auth_circle_icon_2 {
  position: absolute;
  right: 20px;
  margin-top: 24px;
  z-index: 2;
  width: 15px;
  cursor: pointer;
}
.auth_eye_2 {
  position: absolute;
  right: 20px;
  margin-top: 24px;
  z-index: 2;
  width: 15px;
  cursor: pointer;
}

body[dir="rtl"] .auth_eye_2,
body[dir="rtl"] .auth_circle_icon_2 {
  position: absolute;
  right: unset;
  left: 20px;
  margin-top: 24px;
  z-index: 2;
  cursor: pointer;
}

.personal_input {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.personal_form {
  margin-top: 50px;
}
.personal_info_card {
  display: flex;
  flex-direction: column;
  width: 70%;
  height: 100%;
  border: 2px solid #ccc;
  background-color: #fff;
  border-radius: 20px;
  padding: 50px 80px;
}

.personal_label {
  display: flex;
}

.checkbox > input {
  height: 15px;
  width: 15px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 1px solid #34495e;
  border-radius: 4px;
  outline: none;
  transition-duration: 0.3s;
  background-color: #fff;
  cursor: pointer;
}

.checkbox > input:checked {
  border: 1px solid #006d77;
  background-color: #006d77;
}

@media (max-width: 1199px) {
  .personal_info_card {
    width: 80%;
  }
}

@media (max-width: 991px) {
  .personal_info_card {
    width: 100%;
  }
  .personal_label {
    font-size: 10px;
  }
  .personal_button {
    font-size: 10px;
  }
  .perosnal_text_img {
    font-size: 20px;
  }
  .personal_img_text {
    font-size: 10px;
  }
  .personal_delete_img {
    font-size: 10px;
  }
  .form-check-label {
    font-size: 12px;
  }
  .personal_label_top {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .personal_info_card_st {
    margin: 20px 0;
  }
  .personal_info_card {
    padding: 10px 20px;
  }
  .personal_label {
    font-size: 12px;
  }
  .personal_button {
    font-size: 12px;
  }
  .perosnal_text_img {
    font-size: 14px;
  }
  .personal_dimage {
    width: 70px;
    height: 70px;
  }
  .personal_browse {
    width: 40px;
    height: 30px;
    margin-left: 40px;
    margin-top: 46px;
  }
  .personal_img_text {
    margin: 0 9px;
    font-size: 10px;
  }
  .personal_delete_img {
    font-size: 12px;
    width: 100%;
  }
  .personal_phone_info {
    font-size: 8px;
  }
  .personal_radio {
    padding: 0;
  }
}
