.mainCont {
  background-image: url("../../../assets/icons/BgcontactusForm.svg");
  width: 100%;
  background-size: cover;
  padding: 50px 200px;
}

.mainForm {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}
.subform {
  display: flex;
  flex-direction: column;
  width: 40%;
  gap: 20px;
}
.btnCont {
  display: flex;
  justify-content: end;
  width: 100%;

  margin-top: 40px;
}
.btn {
  padding: 10px 60px;
  font-size: 20px;
  border-radius: 14px;
}
@media (max-width: 768px) {
  .subform {
    width: 80%;
  }
  .mainForm {
    flex-direction: column;
  }
  .mainCont {
    padding: 20px;
  }
}
