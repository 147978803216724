.rating_panel_wrapper {
    flex:1.25;
}

.rating_panel {
    width:100%;
    height: 100%;
    background-color: #FFFFFF;
    box-shadow: 0px 5px 10px #0000000A;
    border-radius: 10px;
}

.rating_panel_header {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: row;
}

.rating_panel_header_action {
    flex: 2;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.rating_panel_header_label {
    flex:1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.rating_panel_header_label_report {
    color: #3B86FF;
    font-size: 12px;
    margin-bottom: 0 !important;
    cursor: pointer;
}

.rating_panel_body {
    position: relative;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
}