.CategoryCard {
  width: 200px;
  border-radius: 10px;
  height: 200px;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 5px;
  gap: 10px;
  background-color: #fff;

  /* justify-content: flex-end; */
}
.icon_cont {
  border-radius: 50%;
  width: 60px !important;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon_cont img {
  width: 40px;
  height: 40px;
  object-fit: contain;
}
.CategoryCard p {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}
.ArrowCard {
  height: 30px;
  width: 30px;
  transform: rotateY(180deg);
}
.title_arrow {
  display: flex;
  align-items: center;
  height: 50px;
  overflow: hidden;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
}
