.table_payment_label {
  display: flex;
  font-size: 16px;
  padding: 30px 0 15px 0;
}

.warning_msg {
  background-color: #fffed4;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  width: 60%;
  justify-content: space-between;
  align-items: center;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.warning_msg p {
  margin: 0 20px;
  display: flex;
  align-items: center;
}

.warning_msg img {
  width: 35px;
}

.table_payment {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px;
  margin-top: 30px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  box-shadow: 1px 1px 10px #cccccc;
}

.table_payment_btn {
  width: 150px;
  height: 40px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.receipt {
  background-color: #337fe4;
  color: #fff;
}

.paymentNow {
  background-color: #006d77;
  color: #fff;
}

.skeleton {
  margin-top: 30px;
  width: 100%;
  height: 350px;
}
