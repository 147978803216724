.briefContainer {
  margin: 20px;
  display: flex;
  gap: 20px;
  padding-top: 50px;
}

.briefImage {
  width: 50%;
  border-radius: 10px;
  overflow: hidden;
}

.briefImage img {
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.briefContent {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 5px;
}

.briefContent span {
  color: #979797;
  font-size: 1.2rem;
  font-weight: 600;
}

.briefContent h2 {
  color: #029499;
  font-size: 2.2rem;
  font-weight: 700;
}

.briefContent p {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  color: #979797;
}

.statistics {
  display: flex;
  gap: 20px;
  align-items: center;
  background-color: #029499;
  border-radius: 10px;
  margin-top: auto;
  margin-bottom: 5px;
}

.statisticsOne,
.statisticsTwo {
  width: 50%;
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 25px;
}

.statisticsTwo {
  background-color: #0dbfd1;
  border-radius: 10px;
}

.statisticsText p {
  color: white;
  font-size: 2.5rem;
  font-weight: 600;
}

.statisticsText span {
  color: white;
  font-size: 1.2rem;
  font-weight: 600;
}

.statisticsOne img,
.statisticsTwo img {
  width: 80px;
  height: 80px;
}

@media (max-width: 992px) {
  .briefContainer {
    flex-direction: column;
    gap: 20px;
  }

  .briefContent {
    width: 100%;
  }

  .briefImage {
    width: 100%;
  }

  .statistics {
    flex-direction: column;
    gap: 20px;
  }

  .statisticsOne,
  .statisticsTwo {
    width: 100%;
  }

  .statisticsOne img,
  .statisticsTwo img {
    width: 60px;
    height: 60px;
  }

  .statisticsText p {
    font-size: 2rem;
  }

  .statisticsText span {
    font-size: 1rem;
  }
}
