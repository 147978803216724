.main_green_header {
  height: 180px;
  background: #011e20;
  width: 100%;
  padding: 50px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
}

.program_grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
  margin: 20px 0;
}

.main_green_text {
  font-size: 30px;
  display: flex;
  align-items: center;
}

.btn_program {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
}

.header_button {
  width: 100%;
  margin: 10px;
  height: 50px;
  font-size: 14px;
  background-color: #006d77;
  color: #fff;
  border-radius: 15px;
  outline: none;
  box-shadow: none;
  overflow: hidden;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 991px) {
  .main_green_header {
    height: unset;
    padding: 0;
  }

  .program_grid {
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
  }

  .main_green_text {
    justify-content: center;
  }

  .header_btn {
    font-size: 14px;
  }
}
