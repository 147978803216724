.training_first_label {
  display: flex;
  font-size: 16px;
  padding: 30px 0 15px 0;
}

.training_second_label,
.training_second_label {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  font-size: 16px;
  padding: 15px 0 30px 0;
}

@media (max-width: 991px) {
  .training_second_label {
    font-size: 14px;
    flex-direction: column;
  }
}
