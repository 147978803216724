.course-partners {
  padding: 2rem 0;
}

.course-partners__container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.course-partners__table-name,
.course-partners__table-desc {
  width: 300px;
  white-space: pre-wrap;
}

.course-partners__table-photo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.course-partners__table-photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.add-trainer-modal-container {
  width: 50%;
  z-index: 10000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.add-trainer-modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem;
  border-bottom: 1px solid #ddd;
}

.add-trainer-modal-title {
  font-size: 1.4rem;
  font-weight: 600;
  color: #046c77;
}

.add-trainer-modal-exit {
  cursor: pointer;
  width: 2rem;
  height: 2rem;
}

.add-trainer-modal-body {
  padding: 1.5rem;
}

.add-trainer-modal-form {
  display: flex;
  flex-direction: column;
}

.add-trainer-modal-form-label {
  font-weight: 400;
  line-height: 45px;
  color: #036c77;
}

.form-input-error-space {
  height: 18px;
  font-size: 12px;
  color: red;
}

.add-trainer-modal__form-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
}

.add-trainer-modal__form-actions__btn {
  padding: 1rem 8rem;
}

.add-trainer-modal-button-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.add-trainer-modal-button-text {
  font-size: 0.8rem;
  color: #969696;
}

.course-partners__table-email {
  color: #24b3b9;
  cursor: pointer;
}

@media (max-width: 768px) {
  .add-trainer-modal-container {
    width: 100%;
  }
}
