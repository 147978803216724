.mb-4 {
  margin-bottom: 4px;
}

/* Define initial and final states */
.tw-enter {
  opacity: 0;
  transform: translateY(-50%);
}

.tw-exit {
  opacity: 1;
  transform: translateY(0);
}

/* Define animations using keyframes */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-50%);
  }
  to {
    transform: translateY(0);
  }
}
