.main-box {
  background-color: #fff;
  box-shadow: 2px 2px 10px #ddd;
  padding: 4rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  border-radius: 35px;
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  .main-box {
    padding: 1rem;
  }
}
