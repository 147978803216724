.tajah-form-container {
  position: relative;
  background-color: #fff;
  width: 100%;
  box-shadow: 2px 2px 10px #ddd;
  border-radius: 20px;
  padding: 30px;
}

.tajah-form-row {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.tajah-form-column {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.tajah-form-row-element {
  flex: 1;
  max-width: 100%;
}

.tajah-form-row-element-v2 {
  flex: 1;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.tajah-form-row-element-holder {
  width: 90%;
  position: relative;
}

.tajah-auto-complete-text-item {
  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
}

.tajah-auto-complete-text-item:hover {
  background-color: #ababab;
  transition: background-color 0.5s;
}

.tajah-auto-complete-loading {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tajah_filter_item_label_actionable {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}

.tajah_filter_item_label_action {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
}

.tajah_filter_item_label_action_clear {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: red;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.table_bank_tran button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.table_bank_tran button i {
  font-size: 17px;
  color: #fff;
  padding: 0px 5px;
}

.tajah-bordered-text-area {
  display: flex;
  background-color: transparent;
  color: #000;
  -webkit-backdrop-filter: blur(100px);
  backdrop-filter: blur(100px);
  position: relative;
  width: 100%;
  border-color: #cccccc;
  box-shadow: none;
  border-style: solid;
  border-width: 0.5px;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

.tajah-bordered-text-field {
  display: flex;
  background-color: transparent;
  color: #000;
  -webkit-backdrop-filter: blur(100px);
  backdrop-filter: blur(100px);
  position: relative;
  width: 100%;
  border-color: #cccccc;
  box-shadow: none;
  border-style: solid;
  border-width: 0.5px;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.admin_filter_input {
  display: flex;
  background-color: transparent;
  color: #000;
  -webkit-backdrop-filter: blur(100px);
  backdrop-filter: blur(100px);
  position: relative;
  width: 100%;
  height: 38px;
  border-color: #cccccc;
  box-shadow: none;
  border-style: solid;
  border-width: 0.5px;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

.admin_filter_input:disabled {
  background-color: hsl(0, 0%, 95%);
}

.admin_add_button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 130px;
  padding: 10px;
  background-color: #006d77;
  border-radius: 15px;
  color: #fff;
  font-size: 16px;
  border: none;
  outline: none;
  margin-top: 10px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  cursor: pointer;
}

.admin_add_button:disabled {
  background-color: rgba(0, 53, 58, 0.6);
}
