.modal {
  position: fixed;
  inset: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background-color: rgb(33 33 33 / 45%);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0 !important;
}
