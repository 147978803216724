.gift-list {
  padding: 2rem 0;
}

.gift-list__container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.gift-list__table-name {
  /* width: 150px; */
  white-space: pre-wrap;
  align-items: center;
}
.note_table {
  white-space: pre-line;
}
