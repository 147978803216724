/* Header */
.header_shadow {
  background-color: #fff;
  box-shadow: 0 0 21px #aaa;
  height: 111px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.logo_Main_qassim {
  width: 350px;
  /* margin-bottom: 5px; */
}
@media (max-width: 1000px) {
  .logo_Main_qassim {
    width: 250px;
    margin-bottom: unset;
  }
}

.dinamic_main {
  display: flex;
  width: 100%;
  justify-content: end;
}

.header_navabr {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

.headerLogoButton {
  -webkit-appearance: button;
  background-color: transparent;
  background-image: none;
}

.navbar {
  padding: 1rem 0rem;
  margin: 0 auto !important;
  justify-content: start;
  padding: 0 20px !important;
}

.navbar_logo {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 1rem 0rem;
  margin: 0;
  /* width: 70%; */
  justify-content: start;
}

.navbar-brand {
  background-color: transparent;
  width: 100px;
  margin: 0;
}

body[dir="rtl"] .navbar-brand {
  margin-right: 0;
}

.header_logoTebx {
  width: 100%;
  height: 100%;
}

.header_dinamic {
  width: 100%;
  display: -webkit-flex;
  -webkit-justify-content: flex-end;
  -webkit-flex-direction: row;
  align-items: center;
}

.lang_style {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #707070;
  font-size: 14px;
  cursor: pointer;
  /* margin-left: 20px;
  margin-right: 20px; */
}

.lang_style img {
  margin: 0 5px;
}
.lang_btn_qassem {
  display: flex;
  align-items: center;
  border-radius: 6px;
  padding: 10px 10px;
  /* background-color: #03a2b2; */
  /* color: #fff; */
}
.lang_name {
  color: #025299;
}
@media (max-width: 468px) {
  .lang_name {
    display: none;
  }
}
.line_logo {
  width: 1px;
  height: 50px;
  margin: 0 20px;
  background-color: #707070;
}

.logo_tebx {
  width: 97px;
}

.header_nav_links {
  font-size: 14px;
  font-weight: 600;
  color: #000 !important;
  text-decoration: none;
  padding: 1rem 0.4rem !important;
  margin: 0;
  border-bottom: 4px solid transparent;
  cursor: pointer;
}

.header_nav_link {
  color: #707070;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  padding: 0.6rem 0.8rem;
  margin: 0.5rem 0;
}

.header_nav_links:hover,
.header_nav_links.active {
  color: #21a6ac !important;
  border-bottom: 4px solid #21a6ac;
}

.header_nav_links.active {
  border-bottom: 4px solid #21a6ac;
}

.header_nav_links {
  display: flex;
  align-items: center;
}

.header_links {
  z-index: 100;
  position: sticky;
  display: flex;
  width: 100%;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  color: #fff !important;
  background-color: #025299;
}

.header_links_none {
  z-index: 100;
  position: absolute;
  display: none;
  width: 100%;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  color: #fff !important;
}

.header_links_container {
  /* border-top: 1px solid #d7d7d7; */
  height: 60px;
  padding: 0 !important;
  background-color: #025299;
  color: #fff;
}

.navbar-fixed-top {
  position: fixed;
  z-index: 999;
  top: 0;
}

.header_notif {
  width: 18px;
}

.header_btn_color {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0px 10px 0px;
  border-radius: 10px;
  margin: 0 5px;
}

.header_search {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 10px;
  margin: 0 5px;
  background-color: #1b3453;
  cursor: pointer;
}

.header_search img {
  width: 16px;
  height: 16px;
}

.headerSearchContainer {
  background-color: #fff;
  padding: 30px 0;
  margin-bottom: 20px;
}

.headerSearchForm {
}

.headerSearchField {
  padding: 0 5px !important;
}

.headerSearchButton {
  padding: 12px !important;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login_as_trainee {
  border: 1px solid #006d77;
  color: #006d77 !important;
}

.login_as_trainee:hover {
  background-color: #006d7710;
  color: #006d77 !important;
}

.login_as_trainer {
  border: 1px solid #1b3453;
  color: #1b3453 !important;
}

.login_as_trainer:hover {
  background-color: #006d7710;
  color: #1b3453 !important;
}

.header_btn_style {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #707070;
  padding: 10px 0px 10px 0px;
  border-radius: 15px;
  margin: 0 100px;
}

.header_text {
  text-decoration: none !important ;
  margin: 0 20px;
}

.header_btn {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  padding: 0px 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.header_btn img {
  width: 16px;
  height: 16px;
}

.MuiInputLabel-animated,
.MuiFormLabel-root {
  color: #777777;
}

.online {
  width: 200px;
  background-color: #006d77;
  padding: 20px;
  color: #fff;
  font-size: 20px;
  position: absolute;
  right: 0;
  cursor: pointer;
  border-radius: 20px;
  outline: none;
  border: none;
}

.header-dropdown {
  border: none;
  outline: none;
  box-shadow: none;
}

.dropdown-item {
  text-align: left;
}

.dropdown {
  /* padding-top: 10px; */
  outline: none !important;
}

body[dir="rtl"] .dropdown-item {
  text-align: right !important;
}

.btn:focus,
.btn.focus {
  box-shadow: none;
}
.dropdown-menu {
  /* top: 10px !important; */
}

.dropdown-toggle::after {
  margin: 0 10px;
}

.header_btn_lng {
  margin: 0 10px !important;
}

.dinamic_header {
  display: none;
}

.hamberger_button {
  width: 25px;
  height: 2px;
  background-color: #fff;
  margin: 6px 0;
}

.hamberger_button_action {
  display: none;
  background-color: #006d77;
  color: #fff;
  padding: 2px 7px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.navbar-expand-md .navbar-collapse {
  justify-content: end;
  padding-bottom: 10px;
  /* flex-basis: 100%; */
}

.navbar .navbar-nav {
  display: none !important;
}

body[dir="rtl"] .navbar-nav {
  text-align: right;
}

.navbar .navbar-toggler:not(:disabled):not(.disabled) {
  background: #006d77;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.navbar .navbar-toggler {
  padding: 0.25rem 0.5rem;
}

.navbar-nav .navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link {
  color: #707070;
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #707070;
}

.menu_icon {
  margin: 0 5px;
  width: 16px;
}

.fix_top_icon {
  margin-top: -5px;
}

.dropdown-menu {
  position: absolute;
  left: -13px;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: inherit;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.navbar-nav .dropdown-menu {
  border: none;
  padding: 0 40px;
  color: #707070;
  margin-bottom: 10px;
  margin-top: -7px;
}

.navbar-nav .dropdown-toggle {
  display: flex;
  width: 30%;
  justify-content: space-between;
  align-items: center;
}

a:hover,
.dropdown-menu a {
  color: #cacaca !important;
  text-decoration: none;
}
.dropdown-toggle {
  color: #707070;
}

.flex_menu {
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100%;
}

.main-menu-button {
  background-color: #73c2b5;
  width: 3rem;
  border-radius: 50%;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0;
}

@media (max-width: 1199px) {
  .header_nav_link,
  .header_nav_links {
    font-size: 13px;
  }
  .nav-link {
    padding: 15px 10px;
  }

  .header_btn {
    font-size: 14px;
  }
}

@media (max-width: 991px) {
  .header_nav_link,
  .header_nav_links {
    font-size: 11px;
  }
  .nav-link {
    padding: 15px 7px;
  }
  .navbar-brand {
    width: 70px;
  }
  .line_logo {
    display: none;
  }

  .header_btn {
    padding: 0 15px;
  }

  .header_logouni {
    width: 220px;
  }
  .logo_tebx {
    width: 64px;
  }

  .header_logouni {
    width: 170px;
  }

  .line_logo {
    height: 40px;
    margin: 0 10px;
  }
  .headerLogoAndAuth {
    flex: 1;
  }
}
.userProfile {
  display: none !important;
}
.button-toggle {
  display: none !important;
}
@media (max-width: 767px) {
  /* .header_dinamic .user-dropdown {
    display: none !important;
  } */
  .flex_menu .lang_style {
    margin-top: 10px;
  }
  .navbar-collapse {
    width: 100%;
  }
  .line_logo {
    display: none;
  }

  .dinamic_header {
    display: block;
  }

  .header_btn_lng {
    margin: 10px !important;
  }

  .header_dinamic {
    justify-content: center;
    align-items: center;
    /* display: none; */
    margin-top: 5px;
    /* gap: 10px; */
    margin-bottom: 10px;
  }
  .navbar hr {
    margin: 0;
    margin-top: 5px;
  }

  .fix_margin {
    margin-top: 15px !important;
  }

  .dinamic_main {
    display: unset;
    width: unset;
    justify-content: unset;
  }

  .navbar {
    margin: 0;
    padding: 0;
    width: 100%;
    justify-content: space-between;
  }

  .navbar-collapse {
    position: absolute;
    top: 61px !important;
    width: 100%;
    padding: 0 10px;
    z-index: 1000;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
  }

  .navbarSmallDeviceForAdmin {
    position: absolute;
    top: 96px !important;
    width: 100%;
    padding: 0 10px;
    z-index: 1000;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
  }

  .dropdown-toggle {
    padding: 0 !important;
  }
  .header_notif {
    margin: 0 0 10px;
  }

  .hamberger_button_action {
    display: unset;
  }

  .header_links .container-fluid {
    flex-direction: column;
    width: 100%;
    display: none;
  }

  .navbar .navbar-nav {
    display: unset !important;
  }

  .navbar_logo {
    width: 85%;
  }
  .dropdown-menu {
    top: 35px !important;
    left: 0 !important;
  }

  .navbar-nav .dropdown-toggle {
    display: flex;
    width: 50%;
    justify-content: space-between;
    align-items: center;
  }
  /* Todo */
  .admin_support,
  .header_notif {
    display: none !important;
  }

  .dropdown_admin {
    margin: 10px 0 0;
  }
  .hamburger-pointer {
    display: none;
  }

  .flex_menu .lang_style {
    margin-top: 10px;
  }

  .navbar-toggler-icon {
    width: 1.2em;
    height: 1.2em;
  }
  .navbar-brand {
    width: auto !important;
  }
  /* .navbar-brand > img {
    width: 100% !important;
  } */
  .navbar {
    padding: 0 !important;
  }
  body[dir="ltr"] .dropdown-menu {
    left: unset;
    right: 0;
  }
  .showAuthAndLangInLargeDevice {
    display: none !important;
  }
  .userProfile {
    display: block !important;
  }
  .button-toggle {
    display: block !important;
  }
  .navbar {
    gap: 1rem;
  }
}

@media (max-width: 480px) {
  .header_dinamic {
    /* flex-direction: column; */
  }
  .line_logo {
    display: none;
  }

  .header_links {
    z-index: 100;
    position: absolute;
    display: flex;
    width: 100%;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: rgba(3, 22, 23, 0.2);
    color: #fff !important;
  }
  .navbar-nav .dropdown-toggle {
    display: flex;
    width: 70%;
    justify-content: space-between;
    align-items: center;
  }
}

@media (max-width: 360px) {
  .line_logo {
    display: none;
  }
  .header_shadow {
    height: unset;
    padding: 0 0 10px 0;
  }
  .navbar-nav .dropdown-toggle {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
}

@media only screen and (max-width: 1024px) {
}
@media (max-width: 800px) {
  .headerLogoAndAuth {
    width: 80% !important;
  }

  .smallDeviceMenu {
    display: block !important;
  }
}

@media (min-width: 800px) {
  .headerLogoAndAuth {
    width: 100% !important;
  }
}
