.training-course-about-section {
  flex: 1;
}

.mx-5 {
  margin-right: 3rem !important;
}

.mx-5 {
  margin-left: 3rem !important;
}

.content_explanation {
  display: flex;
  flex-direction: column;
}

.add_training_course_video_container {
  height: 175px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 35px;
  background-color: #24b3b9;
  border-radius: 6px;
  padding: 0 2rem;
}

.add_training_course_video_text {
  color: #fff;
  font-weight: 500;
  font-size: 32px;
}

.content_explanation_text {
  font-size: 30px;
}

@media (max-width: 1199px) {
  .content_explanation_text {
    font-size: 20px;
  }
}

@media (max-width: 991px) {
  .content_explanation_text {
    font-size: 16px;
  }
  .training-course-about-section {
    margin: 0 !important;
  }
}

@media (max-width: 767px) {
  .content_explanation_text {
    font-size: 14px;
  }
  .training-course-syllabus-container {
    padding: 0 15px !important;
  }
  .content_explanation__wrapper {
    flex-wrap: wrap;
  }

  .content_explanation__wrapper > div:first-of-type {
    width: 100%;
  }

  .add_training_course_video_container {
    flex-direction: column;
    height: auto;
    padding: 1rem;
    gap: 15px;
  }

  .add_training_course_video_text {
    font-size: 24px;
    text-align: center;
  }
}

.mt-5 {
  margin-top: 3rem !important;
}

.content_explanation_video {
  margin: 30px 0;
}
