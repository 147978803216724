.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background-color: rgb(33 33 33 / 45%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal__container {
  /* width: 34rem;
  padding: 2.5rem; */
  width: 35rem;
  padding: 1rem;
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  align-items: center;
}

.modal__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.modal__icon img {
  width: 80px;
}

.modal__content__title {
  font-size: 20px;
  color: #555555;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;

}

.modal__content__name {
  font-size: 22px;
  font-weight: 500;
  line-height: 1.5;
}

.modal__content__confirm-msg {
  font-size: 19px;
  color: #555555;
  font-weight: 400;
  line-height: 1.5;
}

.modal__buttons {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.modal__buttons button {
  border: none;
  border-radius: 6px;
  height: 50px;
  padding: 0 1rem;
  width: 130px;
  font-size: 16px;
}

.modal__buttons__cancel {
  background-color: transparent;
  color: #046c77;
  border: 1px solid #046c77 !important;
}

.modal__buttons__confirm {
  background-color: #046c77;
  color: #ffffff;
}

.modal__buttons__confirm[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}


.search__box__item__email {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.search__box__item__email-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.search__box__item__email-field {
  position: relative;
}

.search__box__item__email-field-btn {
  height: 100%;
  border: none;
  background-color: #046c77 !important;
  border-radius: 6px;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  color: #ffffff;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
}

body[dir="rtl"] .search__box__item__email-field-btn {
  right: auto;
  left: 0;
}

.search__box__item__emails {
 
}

.search__box__item__emails-list {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}
.search__box__item__emails-list-item {
  display: flex;
  align-items: center;
  border: 1px solid #dedede;
  border-radius: 10rem;
  width: max-content;
  padding: 0.2rem 0.5rem;
  gap: 0.5rem;
}
.search__box__item__emails-list-item-icon  {
  cursor: pointer;
}
.search__box__item__emails-list-item-icon img {
  width: 1em;
  height: 1em;
}
.search__box__item__emails-list-item-text {
  font-size: 14px;
  color: #555555;
  font-weight: 400;
  line-height: 1.5;
}
@media only screen and (max-width: 768px) {
  .modal__container {
    width: 90%;
  }

  .modal__content__title,
  .modal__content__confirm-msg {
    font-size: 16px;
  }

  .modal__content__name {
    font-size: 14px;
  }

  .modal__buttons button {
    width: 110px;
  }
}
