.program_payment_label {
  display: flex;
  font-size: 16px;
  padding: 30px 0 15px 0;
}

.table_payment_label {
  display: flex;
  font-size: 16px;
  padding: 30px 0 15px 0;
}

.payment_card {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 20px 0;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  box-shadow: 1px 1px 10px #cccccc;
}

.program_padding {
  padding: 0 20px;
}

.fix-margin {
  margin-bottom: 20px;
}

.top_label {
  font-size: 25px;
}

.header_program {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin: "10px 0";
}

.program_collapse {
  display: flex;
  align-items: center;
}

.program_collapse img {
  width: 100px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.payment_program_name {
  width: 60%;
}

.modal_video {
  padding: 0;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #006d77;
}

.modal_video img {
  width: 30px;
  margin: 0 10px;
}

.card_border {
  border-top: 1px solid #ccc;
  margin: 10px 0;
}

.program_title {
  margin: 0 15px;
}

.program_label {
  font-size: 20px;
}

.card_center {
  display: flex;
  align-items: center;
}

.img_hour {
  width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -3px 5px 0;
}

.payment_price {
  font-size: 14px;
  margin-top: 10px;
}

.overall_price_payment {
  display: flex;
  justify-content: space-between;
  padding: 30px 60px;
  align-items: center;
  background-color: rgba(144, 212, 218, 0.6);
  color: #006d77;
  font-size: 20px;
  margin: 20px 0;
}

.program_btn {
  width: 170px;
  margin: 10px;
  padding: 10px;
  font-size: 14px;
  background-color: #006d77;
  color: #fff;
  border-radius: 15px;
  outline: none;
  box-shadow: none;
  overflow: hidden;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 991px) {
  .program_title {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
