.training-course-syllabus-container {
    margin-top: 15px;
    display: flex;
    flex-direction: row;
}

.training-course-syllabus-section {
    position: relative;
    flex: 2;
}

.training-course-about-section {
    flex: 1;
}

.training_info {
    display: flex;
    width: 30%;
    margin: 0 50px;
    flex-direction: column;
}

.training_info_label {
    font-size: 25px;
    padding: 20px 0;
}

.training_info_img_name {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    /* width: 100%; */
    max-width: 100%;
    word-break: break-word;
}

.trainers_dimage {
    color: #000;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    object-fit: cover;
    background-position: center;
    background-size: cover;
}

.training_info_name {
    font-size: 20px;
}

.training_info_major {
    font-size: 12px;
    max-width: 100%;
    word-break: break-word;
}

.training_info_text {
    margin-top: 20px;
    font-size: 14px;
    max-width: 100%;
    word-break: break-word;
}

.training_info_read_more {
    font-size: 14px;
    text-decoration: none;
    color: #006d77;
    margin-top: 20px;
}

.gift_course{
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 20px;
    color: #006D77;
}

@media (max-width: 1199px) {
    .training_info_label {
        font-size: 20px;
    }

    .trainers_dimage {
        width: 50px;
        height: 50px;
        background-color: #d4d4d4;
        border-radius: 50%;
    }
}

@media (max-width: 991px) {
    .training-course-syllabus-container {
        margin-top: 15px;
        display: flex;
        flex-direction: column;
    }

    .training_info_label {
        font-size: 18px;
        padding: 40px 0 10px;
    }

    .trainers_dimage {
        width: 50px;
        height: 50px;
        background-color: #d4d4d4;
        border-radius: 50%;
    }

    .training_info_name {
        font-size: 16px;
    }

    .training_info_major {
        font-size: 10px;
        max-width: 100%;
        word-break: break-word;
    }

    .training_info_text {
        font-size: 12px;
        max-width: 100%;
        word-break: break-word;
    }
}

@media (max-width: 767px) {
    .training_info {
        width: 80%;
    }

    .training_info_label {
        font-size: 16px;
        padding: 0 0 20px;
    }

    .training_info_img_name {
        justify-content: unset;
        max-width: 100%;
        word-break: break-word;
    }

    .training_info_name {
        font-size: 14px;
    }
}
