.admin_label {
  font: 18px;
  display: flex;
  margin: 30px 0;
}
.table {
  display: table;
}
.table {
  text-align: initial;
  width: 100%;
  margin: 0 auto;
}
.admin_card .table {
  width: 90%;
}
.admin_form_flex_col {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 20px;
}
.admin_card {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  /* margin-top: 40px; */
  padding: 50px 0;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 2px 2px 10px #ddd;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.admin_forms {
  display: flex;
  flex-direction: column !important;
  padding: 0px 50px;
  border-radius: 50px;
  width: 100%;
  height: 100%;
  color: #686868 !important;
}
.admin_input_label {
  font-size: 18px;
}
.admin_input {
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  color: #686868;
  border-bottom: 2px solid #686868;
  height: 50px;
  margin-bottom: 30px;
  font-size: 18px;
}

.admin_btns {
  display: flex;
  justify-content: flex-end;
}
.auth_btn {
  /* width: 376px; */
  border-radius: 6px;
  /* height: 75px; */
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  color: #fff;
  background-color: #046c77;
}
.admin_component_input {
  width: 100%;
  background-color: transparent;
  border-bottom: 2px solid #686868;
  outline: none;
  color: #686868;
  height: 50px;
  font-size: 18px;
}

@media (max-width: 991px) {
  .admin_label {
    font-size: 16px !important;
  }
}
@media (max-width: 768px) {
  .admin_label {
    font-size: 14px !important;
  }
}
