.cardCont {
  display: flex;
  align-items: center;
  padding: 15px;
  border-radius: 16px;

  min-width: 250px;
  justify-content: space-between;
  margin-bottom: 15px;
}
.cardCont span {
  font-size: 12px;
}
.img_name {
  display: flex;
  gap: 10px;

  align-items: center;
}
.imgCont {
  border-radius: 50%;
  height: 40px;
  width: 40px;
  overflow: hidden;
}
.Img {
  height: 100%;
  width: 100%;
}
