.coupon-details {
  padding: 2rem 0;
}

.coupon-details__container,
.coupon-details__form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.coupon-details__form__fields {
  margin: 0 -15px;
}

.coupon-details__form__field {
  margin-bottom: 2rem;
}

.coupon-details__form__field label {
  color: #777777;
}

.coupon-details__warning__note {
  background-color: #df4b43;
  color: #fff;
  padding: 0.4rem 1rem;
  border-radius: 6px;
  margin: 0.4rem 0;
  width: fit-content;
  font-size: 0.9rem;
}
