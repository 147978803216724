.input-container {
  margin: 30px 2px;
  display: flex;
  justify-content: space-between;
  align-items: self-start;
  gap: 30px;
}

.input-container .input {
  width: 100%;
}

.btn-paymentway {
  width: 300px;
  margin-top: 20px;
}

@media (max-width: 767px) {
  .input-container {
    flex-direction: column;
    gap: 10px;
    margin: 10px 2px;
  }

  .input-container .input,
  .popup-font-size {
    font-size: 12px !important;
  }

  .mt-25px {
    margin-top: 25px;
  }

  .btn-paymentway {
    width: auto;
    font-size: 14px !important;
  }
}
