.headerContainer {
  width: 100%;
  /* background: #03a2b2; */
  background-image: url("../../../assets/image/newContainerBackground.png");
  background-color: #29415e;
  background-size: cover;
  background-position: center;
  display: flex;
  padding: 40px;
  padding-bottom: 100px;
  border-radius: 0 0 40px 40px;
  /* position: relative; */
}

.headerContent {
  display: flex;
  gap: 20px;
  width: 100%;
}
.headerImage {
  /* position: relative; */
  border-radius: 6px;
  background-size: cover;
  background-position: center center;
  /* flex: 1 1 auto; */
}
.image {
  min-width: 300px;
  height: 220px;
  border-radius: 6px;
  border: 2px solid #fff;
  object-fit: fill;
  margin-inline-end: 40px;
}
.header-container {
  gap: 20px;
  flex: 6 1 auto;
}
.headed-detials-mid {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.title {
  display: flex;
  /* align-items: center; */
  gap: 10px;
}
.star-dep-cat {
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 6px 0;
}
.star-dep-cat p {
  font-size: 12px;
  color: #fff;
}
.back-share {
  display: flex;
  justify-content: end;
  gap: 5px;
}
.flip-star {
  /* transform: rotateY(180deg); */
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  justify-content: end;
}
.star-font-size::before {
  content: "\f005\f005\f005\f005\f005";
  font: normal normal normal 14px/1 FontAwesome;
  letter-spacing: 3px;
  background: linear-gradient(
    90deg,
    #fff var(--percent),
    #8599b6 var(--percent)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.3rem;
}
.shareBackBtn {
  padding: 15px;
  border-radius: 50%;
  border: 1px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.arrowIcon {
  width: 20px;
  height: 20px;
  transform: rotateY(180deg);
}
.num_courses {
  display: flex;
  gap: 15px;
  background-color: #4242421f;
  padding: 10px;
  border-radius: 10px;
  max-width: 200px;
  justify-content: center;
  color: #fff;
}
.numContainer {
  display: flex;
  gap: 12px;
  color: #fff;
}
.desc_trainer {
  color: #fff;
  font-size: 12px !important;
  max-width: 500px;
  margin-top: 10px;
  margin-bottom: 15px;
}
.desc_trainer span {
  font-size: 12px !important;
  color: #fff !important;
}
.desc_trainer p {
  font-size: 12px !important;
  color: #fff !important;
}
@media (max-width: 1279px) {
  .headerContainer {
    padding: 20px;
    padding-bottom: 80px;
  }

  .title {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media (max-width: 991px) {
  .headerContent {
    flex-direction: column;
    align-items: unset;
    justify-content: unset;
  }
  .headerContainer {
    background-image: url("../../../assets/image/newContainerBackground.png");
    background-color: #29415e;
    background-size: cover;
    background-position: center;
    padding-top: 20px;
    flex-direction: column;
    gap: 10px;
    border-radius: 0 0 30px 30px;
  }
  .image {
    width: 100%;
    height: 300px;
    object-fit: fill;
    max-width: unset;
    margin-inline-end: unset;
  }

  .title {
    justify-content: unset;
  }
}
