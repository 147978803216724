.tajah-stepper {
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 100px;
}

.tajah-step-item {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #CDCDCD;
    transition: color 0.5s;
    cursor: not-allowed;
    font-size: 14px;
}

.tajah-step-item-indicator {
    border-radius: 15px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(0,0,0,1);
    background-color: #CDCDCD;
    font-size: 12px;
    transition: background-color 0.5s;
    transition: color 0.5s;
}

.tajah-step-item-indicator.active {
    background-color: rgba(3, 22, 23, 0.85);
    color: #FFFFFF;
}

.tajah-step-item.active {
    color: rgba(3, 22, 23, 0.85);
}

.tajah-stepper-line-separator {
    flex:2;
    height: 2px;
    background-color: #CDCDCD;
    transition: background-color 0.5s;
}

.tajah-stepper-line-separator.active {
    background-color: rgba(3, 22, 23, 0.85);
}

.cursor-allowed {
    cursor: pointer;
}

.tajah-label-span {
    margin-top: 5px;
}
