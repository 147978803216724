.sendMessagesModal {
  width: 60%;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 15px;
  max-height: 500px;
  overflow-y: auto;
}

.sendMessagesModal_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.sendMessagesModal_title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #046c77;
}

.sendMessagesModal_close {
  cursor: pointer;
  width: 30px;
  height: 30px;
}

.sendMessagesModal_close img {
  width: 100%;
  height: 100%;
}

.sendMessagesModal-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 2rem;
  margin-top: 20px;
}

.sendMessagesModal-actions-btn {
  padding: 1rem 4rem;
}

.sendMessagesModal__form__field {
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .sendMessagesModal {
    width: 90%;
    padding: 20px;
  }
}

@media (max-width: 576px) {
  .sendMessagesModal {
    width: 100%;
  }

  .sendMessagesModal-actions-btn {
    padding: 1rem 2rem;
  }
}
