.admin_label {
  display: flex;
  margin: 30px 0;
  width: 95%;
  margin: auto;
}

.table_bank_tran {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  margin-top: 40px;
  padding: 50px 0;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 2px 2px 10px #ddd;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.table_bank_tran .table {
  width: 100%;
}

/* .admin_card .table {
    width: 90%;
  } */

.label_bank_tran {
  display: flex;
  width: 90%;
  margin-bottom: 30px;
  justify-content: space-between;
  align-items: center;
}

.admin_label_card {
  display: flex;
  width: 90%;
  margin-bottom: 30px;
  justify-content: space-between;
  align-items: center;
}

.select_admin {
  width: 150px;
  height: 40px;
  border: none;
  padding: 0 10px;
  background-color: #eee;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.table_bank_tran td,
.table_bank_tran th {
  text-align: center;
}

.table_bank_tran i {
  color: #006d77;
  font-size: 13px;
  cursor: pointer;
}

.backdrop_table_bank_tran {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1000000;
  background: rgba(0, 0, 0, 0.8);
}

.table_bank_tran button i {
  font-size: 17px;
  color: #fff;
  padding: 0px 5px;
}

.table_bank_tran button {
  display: flex;
  /*width: 100%;*/
  /*text-align: center;*/
  /*margin: 0 auto;*/
  justify-content: center;
  align-items: center;
}
/* Admin Role */
.admin-role {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}

.admin-role-input {
  width: 30%;
  /* height: 100%; */
  /* position: absolute; */
  /* left: 145px; */
  border-radius: 10px;
  border: 1px solid #dcdcdc;
  box-sizing: border-box;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  margin: 0 10px;
}

.admin-role-search {
  border: 0px;
  outline: none;
  width: 90%;
  height: 30px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
/* Admin Role */
.modal_flex_card {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal_card_admin {
  z-index: 1001 !important;
  position: fixed;
  top: calc(35% - 100px);
  width: 500px;
  height: 350px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  overflow: auto;
  animation: dropmove 0.5s normal;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 10px;
  -webkit-animation: dropmove 0.5s normal;
  padding: 0 30px;
}

.modal_card_title {
  font-size: 30px;
  font-weight: bold;
  color: #006d77;
}
.admin_edit_button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 120px;
  padding: 10px;
  background-color: #fff;
  border-radius: 15px;
  color: #000;
  font-size: 16px;
  border: none;
  outline: none;
  margin-top: 10px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  cursor: pointer;
}

.custom_select {
  position: relative;
  display: inline-block;
  width: 130px;
}

.roles_list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.checkbox__container {
  width: 50%;
}

.checkbox__container input {
  width: auto !important;
}

.checkbox__container label {
  padding: 0 0.8rem;
}

.actions__container {
  display: flex;
  align-items: center;
  gap: 1.4rem;
  justify-content: flex-end;
}

@media (max-width: 991px) {
  .admin_label,
  .important_actions,
  .text_num_b {
    font-size: 16px !important;
  }
}
@media (max-width: 768px) {
  .admin_label,
  .important_actions,
  .text_num_b {
    font-size: 14px !important;
  }
  .admin-header-table {
    flex-direction: column !important;
  }
  .admin-role-input,
  .custom_select {
    width: 50% !important;
  }
  .modal_card_admin {
    width: unset !important;
  }
}
