.activities-list {
  padding: 2rem 0;
}

.activities-list__container {
  display: flex;
  flex-direction: column;
}

.activities-list__back-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.activities-list__back-button span {
  font-size: 1.2rem;
  font-weight: 500;
  color: #7c7c7c;
}
.comment_approve_msg {
  color: #b9b9b9;
  font-size: 0.9rem;
  font-weight: 400;
}

.replays_container {
  display: flex;
  width: 100%;
  border-top: 1px solid #c9c8c8;
  padding-top: 10px;
}
.replays_button {
  display: flex;
  width: 50%;
  justify-content: center;
  color: rgb(13 148 136);
}
.button_split_div {
  width: 1px;
  background-color: #c9c8c8;
}
.button_img {
  width: 25px;
  height: 25px;
}

.comment_form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 20px;
}
.action_button_comment {
  display: flex;
  flex-direction: row-reverse;
  gap: 20px;
  padding-top: 20px;
}
.cancel_button {
  display: flex !important;
  padding: 7px 14px 10px;
  border-radius: 8px;
  gap: 10px;
}

.comment_buttons {
  display: flex;
  gap: 5px;
}
.booth-status-box {
  padding: 0 20px 5px;
  border-radius: 20px;
  font-size: 12px;
}

.booth-status-container {
  display: flex;
  align-items: center;
  /* gap: 0.5rem; */
}
.booth-actions {
  display: flex;
}

@media (max-width: 991px) {
  .booth-status-box-banned {
    font-size: 10px;
  }
  .booth-status-box-waiting {
    font-size: 10px;
  }
  .booth-status-box-published {
    font-size: 10px;
  }
  .booth-status-box {
    padding: 0px 10px 5px;
    display: flex;
    justify-content: center;
    text-align: center;
  }
}
.booth-status-box-banned {
  background-color: rgba(233, 75, 47, 0.767);
  color: #ffffff;
}
.booth-status-box-waiting {
  background-color: rgb(168, 167, 167);
  color: #ffffff;
}
.booth-status-box-published {
  background-color: rgba(37, 138, 37, 0.74);
  color: #ffffff;
}
.img_cont_comment {
  width: 25%;
}
@media (max-width: 756px) {
  .img_cont_comment {
    width: 50%;
  }
}
