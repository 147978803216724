.header_profile {
  background: #011e20;
  padding: 15px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
}


.container-fluid {
  max-width: 1280px;
  padding: 0 20px;
}


@media (max-width: 768px) {
  .container-fluid {
    padding: 0 15px;
  }
  .profile_dtop {
    font-size: 18px;
  }
}

.profile_wrapper {
  display: flex;
  width: 100%;
  height: auto;
  border-radius: 15px;
  margin: 0 auto;
  position: relative;
  justify-content: center;
  flex-direction: column;
  padding: 0 15px;
  margin-top: 20px;
}

.show_certificate_grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 50px;
  margin: 20px 0;
  margin-top: 50px;
}


@media (max-width: 991px) {
  .show_certificate_grid {
    grid-template-columns: repeat(1, 1fr);
  }
  .profile_dtop {
    font-size: 22px;
  }
  .header_profile {
    padding: 10px 0;
  }
}

.profile_dtop {
  font-size: 25px;
  text-align: initial;
}
.sec_edu{
    justify-content: flex-end;
    align-items: flex-end;
    display: flex;
}
.btn_edu,
.btn_edu:hover{
    background: #006D77;
    color: #fff !important
}