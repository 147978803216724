.MainSectionCarousel .slick-dots {
  position: absolute;
  width: fit-content;
  bottom: 3rem;
}

body[dir="rtl"] .MainSectionCarousel .slick-dots {
  right: 6rem;
}

body[dir="ltr"] .MainSectionCarousel .slick-dots {
  left: 6rem;
}

.slick-dots li.slick-active button:before {
  opacity: 0.75;
  content: "";
  background-color: #029499;
  height: 2px;
  border-radius: 1px;
}
.slick-dots li button:before {
  font-size: 20px;
  content: "";
  background-color: black;
  height: 2px;
  border-radius: 1px;
}

@media only screen and (max-width: 992px) {
  .MainSectionCarousel .slick-dots {
    display: none !important;
  }
}
