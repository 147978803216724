.invoice-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 2rem;
}

.invoice-user-verified {
  border-radius: 6px;
  height: 30px;
  padding: 0 15px;
}

.invoice-user-verified-no {
  background-color: rgb(232, 145, 145, 0.6);
  color: #e22020;
}

.invoice-user-verified-yes {
  background-color: rgb(193, 206, 226, 0.6);
  color: #8599b6;
}
