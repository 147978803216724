.tajah-form-row:not(:first-of-type) {
    margin-top: 20px;
}

.tajah-selection-holder {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    border: #000000 1px solid;
}

.tajah-selection-holder-item {
    background-color: #9c9c9c;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height:30px;
    border-radius: 5px;
    margin: 5px;
    padding-left: 5px;
    padding-right: 5px;
}

.tajah-selection-holder-item-text {
    max-width: 70px;
    color: #FFFFFF;
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.tajah-selection-holder-item-dismiss {
    cursor:pointer;
    color: #FFFFFF;
    font-size: 12px;
    margin-left: 5px;
}

.tajah-selection-holder-item-dismiss:hover {
    color: #E03434;
    transition: color 0.2s;
}

body[dir="rtl"] .tajah-selection-holder-item-dismiss {
    margin-right: 5px;
    margin-left: 0px;
}

@media (max-width: 1020px) {
    .tajah-selection-holder {
        grid-template-columns: repeat(9, 1fr);
    }
}

@media (max-width: 930px) {
    .tajah-selection-holder {
        grid-template-columns: repeat(8, 1fr);
    }
}

@media (max-width: 842px) {
    .tajah-selection-holder {
        grid-template-columns: repeat(7, 1fr);
    }
}

@media (max-width: 742px) {
    .tajah-selection-holder {
        grid-template-columns: repeat(6, 1fr);
    }
}

@media (max-width: 653px) {
    .tajah-selection-holder {
        grid-template-columns: repeat(5, 1fr);
    }
}

@media (max-width: 559px) {
    .tajah-selection-holder {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (max-width: 474px) {
    .tajah-selection-holder {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 383px) {
    .tajah-selection-holder {
        grid-template-columns: repeat(2, 1fr);
    }
}
