.tajah-lesson-activity-list-canvas {
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.tajah-lesson-activity-list-item.opened {
  border-radius: 10px;
  border: 1px solid #ccc;
}

.tajah-lesson-activity-loading-canvas {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}

.tajah-activity-details-canvas {
  padding: 15px;
}

.tajah-activity-details-row {
  display: flex;
  flex-direction: row;
  height: 60px;
  align-items: center;
}

.tajah-activity-details-row-text {
  flex: 1;
  margin-left: 10px;
}

body[dir="rtl"] .tajah-activity-details-row-text {
  margin-right: 10px;
  margin-left: 0;
}

.tajah-activity-action-btn {
  width: 200px;
  height: 30px;
  border-radius: 10px;
  background-color: #006d77;
  color: #fff;
  font-size: 13px;
  margin-left: 20px;
}

.tajah-activity-delete-btn {
  width: 200px;
  height: 30px;
  border-radius: 10px;
  background-color: red;
  color: #fff;
  font-size: 13px;
  margin-left: 20px;
}

@media only screen and (max-width: 768px) {
  .tajah-activity-details-row-actions {
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
    height: auto;
  }
  .tajah-activity-details-row-actions > button {
    width: 100%;
    margin: 0;
  }
}
