.tajah-lesson-content {
  padding-right: 15px;
  padding-left: 15px;
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.tajah-lesson-content-browser {
  padding: 15px;
}

.tajah-lesson-content-browser.is_loading {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
