.search {
	padding: 2rem 0;
}

.search__wrapper {
	display: flex;
	flex-direction: column;
	gap: 2rem;
}

.search__container {
  display: flex;
  align-items: center;
}

.search__container-field {
  flex: 1;
  position: relative;
}

.search__container-field input {
  border: 1px solid #eee;
  height: 100%;
  padding: 0 1rem;
  flex: 1 1;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  color: #777777;
  display: flex;
  align-items: center;
  font-size: 15px;
  height: 50px;
  width: 100%;
}

.search__container-field input::placeholder {
  color: #b1b1b1;
}

.search__container-field-icon {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
}

.search__container-button {
  height: 100%;
  border: none;
  background-color: #046c77 !important;
  border-radius: 6px;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  color: #ffffff;
  height: 50px;
  min-width: 120px;
  justify-content: center;
}


.search__box {
  border: 1px solid #eee;
  border-radius: 6px;
  background-color: #fcfcfc;
}

.search__box__list {
  display: flex;
  flex-direction: column;
  /* gap: 1rem; */

}

.search__box__item {
  display: flex;
  gap: 1rem;
  padding: 0 1rem;
}

.search__box__item-status {
  background-color: rgb(245 245 245);
  padding-top: 0.25rem;
}

.search__box__item-label {
  font-size: 16px;
  font-weight: 400;
  color: #777777;
  z-index: unset;
}

.search__box__item-fields {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem 0;
}


.search-input {
  border: 1px solid #eee;
  height: 100%;
  padding: 0 1rem;
  border-radius: 6px;
  color: #777777;
  display: flex;
  align-items: center;
  font-size: 15px;
  height: 40px;
  width: 50%;
}
.search-input input::placeholder {
  color: #b1b1b1;
}

.search__field-date  {
  width: 100%;
}

.search__field-date svg {
  position: absolute;
  padding: unset !important;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
}

body[dir="rtl"] .search__field-date svg {
  right: unset;
  left: 1rem;
}

.search__field-date-input {
  width: 100%;
  height: 40px;
  padding: 0 1rem;
  border: 1px solid #ebebeb;
  background-color: #ffffff;
  border-radius: 6px;
  color: #777777;
}

.search__field-date-input[disabled] {
  background-color: rgba(239, 239, 239, 0.3);
  cursor: not-allowed;
}


.search__actions {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: flex-end;
  padding: 2rem 1rem;
  flex-wrap: wrap;
}

.search__actions-button {
  border: none;
  background-color: #046c77;
  border-radius: 6px;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  color: #ffffff;
  height: 50px;
  min-width: 120px;
  justify-content: center;
}

.search__actions-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.search__box__item__tabs-percent {
  position: absolute;
  right: 0.5rem
}

.search__box__item__tabs-box input {
  padding-right: 2rem;
}

body[dir="rtl"] .search__box__item__tabs-box input{
  padding-right: 1rem;
  padding-left: 2rem;
}

body[dir="rtl"] .search__box__item__tabs-percent {
  right: unset;
  left: 0.5rem;
}

.saved-search__button {
  border-bottom: 1px solid #046c77;
  color: #046c77;
  padding: 0 0.5rem;
}

.saved-search__table-item {
  width: 300px;
  white-space: pre-wrap;
}

.search-view-icon {
  width: 1.5em;
  height: 1.5em;
}

.search-input-container {
  position: relative;
}

.search-input-clear {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  right: 1rem;
}

body[dir="rtl"] .search-input-clear {
  right: unset;
  left: 1rem;
}

.search-input-clear img {
  width: 1rem;
  height: 1rem;
}



@media only screen and (max-width:768px) {
  .search__actions-button {
    width: calc(50% - 0.5rem);
  }
}

@media only screen and (max-width:480px) {
  .search__actions-button {
    width: 100%;
  }
}

.statics_container {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 1rem;
}
.statics_container svg{
  width: 100%;
  height: 100%;
}
@media (min-width: 640px) {
  .statics_container {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (min-width: 1024px) {
  .statics_container {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}
