.exam-grades-canvas {
    padding-right: 15px;
    padding-left: 15px;
    margin-top: 62px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.tajah-question-progress {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.tajah-pre-table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding-top: 20px;
    padding-bottom: 20px;
}

.tajah-pre-table-header .add_margin_bottom {
    margin-bottom: 10px;
}