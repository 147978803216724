.profiles hr {
  border: none;
  height: 3px;
  color: #ccc;
  background-color: #ccc;
  text-align: left;
  margin-top: -23px;
}

.profile {
  margin: 20px 0;
  display: flex;
}

.pri_profile {
  color: #555 !important;
}

.pri_profile a {
  color: #555 !important;
}

.personal_info_bottom {
  display: flex;
}

.links {
  display: flex;
  background-color: transparent;
}

.links.active {
  padding-bottom: 15px;
  border-bottom: 3px solid #18b1b9;
}

.margin_tabs {
  padding: 0 20px;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.card-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem;
}

.personal_info_label {
  margin: 50px 100px;
  display: flex;
  flex-direction: column;
}

.personal_info_top {
  display: flex;
  font-size: 30px;
}

@media (max-width: 1199px) {
  .personal_info_label {
    margin: 50px 80px;
    display: flex;
    flex-direction: column;
  }
  .personal_info_top {
    font-size: 25px;
  }
}

@media (max-width: 991px) {
  .links,
  .personal_info_bottom {
    font-size: 14px;
  }
  .personal_info_label {
    margin: 0;
  }
  .personal_info_top {
    font-size: 20px;
  }
}

@media (max-width: 767px) {
  .links,
  .personal_info_bottom {
    font-size: 10px;
  }
  .personal_info_top {
    font-size: 14px;
  }
}
