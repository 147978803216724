.counter_analysis {
  padding: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 2px 2px 10px #ddd;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  margin-bottom: 50px;
}

.select_analysis {
  width: 30%;
}

.select_label {
  margin-bottom: 10px;
}

.counter_analysis_grid_top {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  margin: 30px 0;
}
.counter_analysis_grid_new {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 15px;
  gap: 15px;
  margin: 25px 0 45px 0;
  overflow-x: auto;
}
.counter_analysis_grid_table {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  margin: 15px 0;
}
.counter_analysis_grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin-bottom: 30px;
}

.analysis-card {
  background-color: #efefef;
  border-radius: 10px;
  padding: 20px;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.analysis-card_new {
  background-color: #efefef;
  border-radius: 10px;
  padding: 20px;
  min-height: 150px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.tabel-analysis {
  background-color: #efefef;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.tabel-analysis_new {
  background-color: #efefef;
  padding: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

@media (max-width: 991px) {
  .counter_analysis_grid,
  .counter_analysis_grid_top {
    grid-template-columns: repeat(1, 1fr);
  }

  .select_analysis {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .counter_analysis_grid_table {
    grid-template-columns: repeat(1, 1fr);
  }
  .counter_analysis {
    padding: 15px;
  }
}
