.edit_lesson_modal {
  width: 60%;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 15px;
  min-height: 400px;
  overflow-y: auto;
}

.edit_lesson_modal_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.edit_lesson_modal_title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #046c77;
}

.edit_lesson_modal_close {
  cursor: pointer;
  width: 30px;
  height: 30px;
}

.edit_lesson_modal_close img {
  width: 100%;
  height: 100%;
}

.course-details-actions-btn {
  padding: 1rem 4rem;
}

.course-details-actions {
  margin-top: 6rem;
}

@media (max-width: 768px) {
  .edit_lesson_modal {
    width: 90%;
  }
}

@media (max-width: 576px) {
  .edit_lesson_modal {
    width: 100%;
  }

  .course-details-actions-btn {
    padding: 1rem 2rem;
  }
}
