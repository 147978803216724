.header_profile {
    background: #011e20;
    padding: 50px 20px 0px 0px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #ffffff;
}

.header_question {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.tajah-trainee-activity-container{
    background-color: white;
    border-radius: 20px;
    border-color: black;
    border-width: 1px;
    padding: 40px;
    margin: 100px;
    box-shadow: 2px 2px 10px #ddd;
}


.next_question_button_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #d4d4d4;
    border-radius: 20px;
    padding: 5px;
}


.personal_input_in {
    width: 100%;
    border: none;
    border-bottom: 1px solid #555;
    background-color: transparent;
    font-size: 17px;
    margin-bottom: 30px;
    padding: 10px 0 !important;
}
