.card-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem;
}

.profiles hr {
  border: none;
  height: 3px;
  color: #ccc;
  background-color: #ccc;
  text-align: left;
  margin-top: -23px;
}

.profile {
  margin: 20px 0;
  display: flex;
}

.pri_profile {
  color: #555 !important;
  margin-top: 2%;
}

.pri_profile a {
  color: #555 !important;
}

.margin_tabs {
  padding: 0 20px;
}

.links {
  display: flex;
  background-color: transparent;
}

.links.active {
  padding-bottom: 15px;
  border-bottom: 3px solid #18b1b9;
}

.profile_click_here {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 0 20px;
  width: 100%;
  padding: 20px;
  background-color: #e9e9e9;
  border-radius: 15px;
}

.click_here {
  padding: 0 5px;
  color: #006d77;
}

.head_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;
}

.head_lable {
  font-size: 22px;
}

.head_view {
  font-size: 16px;
  display: flex;
}

.arrow_seeall {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}

body[dir="rtl"] .arrow_seeall {
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  margin-top: -2px;
}

.arrow_icon {
  width: 8px;
  margin: 3px 10px 0;
}

@media (max-width: 1199px) {
  .head_lable {
    font-size: 18px;
  }
  .head_view {
    font-size: 14px;
  }
}

@media (max-width: 991px) {
  .links {
    font-size: 14px;
  }

  .profile_click_here {
    font-size: 16px;
  }
  .head_lable {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .links {
    font-size: 10px;
  }
  .profile_click_here {
    font-size: 14px;
  }
  .no-result-img {
    width: 100%;
  }
  .nav.nav-tabs.profile {
    flex-wrap: nowrap;
    overflow-x: auto;
    display: -webkit-inline-box;
    max-width: 100%;
  }
}
