.training_course_content_info {
  display: flex;
  flex-direction: column;
  gap: 0rem;
  background-color: #fff;
  border-radius: 20px;
  margin-top: -50px;
  margin-bottom: 60px;
}

.training_top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 2rem;
}

.training_separator {
  background-color: #c7c7c7;
  width: 1px;
  box-shadow: rgba(53, 43, 43, 0.5) 1px 0px 3px;
}

.trainers_container {
  width: 40%;
  overflow-y: auto;
  padding-top: 40px;
}

.training_over_view_title {
  font-size: 1.6rem;
  font-weight: 600;
  color: #012327;
  margin-bottom: 0.4rem;
}

.training_over_view_subtitle {
  font-size: 1rem;
  /* font-weight: 600; */
  color: #24b3b9;
  margin-bottom: 0.4rem;
}

.training_over_view_data {
  font-size: 0.9rem;
  color: #878787;
}

.training_video {
  max-width: 450px;
  max-height: 450px;
}
.training_over_view {
  width: 100%;
  padding: 30px;
}

.training_over_view_content {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.training_header_card_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  width: 100%;
  border-radius: 10px;
  border: 1px solid #c7c7c7;
  overflow: hidden;
}

.training_header_card_img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.training_header_card_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.training_header_card_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  padding: 20px;
}

.training_header_card_title {
  font-size: 30px;
  font-weight: 600;
  color: #707070;
}

.training_header_card_description {
  font-size: 18px;
  color: #777;
}

.training_header_card_button {
  padding: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.training_header_card_countdown {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  font-size: 1.2rem;
  font-weight: 600;
  background-color: #f1f1f1;
  border-radius: 10px;
  padding: 0 1rem;
}

.training-course-details-title-card {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.training-course-back {
  display: flex;
  gap: 3px;
  cursor: pointer;
  min-width: fit-content;
}

.training-course-back-icon {
  width: 20px;
  height: 20px;
  margin-top: 0.4rem;
}

.training-course-back-icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

body[dir="ltr"] .training-course-back-icon img {
  transform: rotate(180deg);
}

.training-course-back-text {
  font-size: 16px;

  color: #fff;
  padding-bottom: 0.4rem;
}

.training_youtube_title_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1.2rem;
}

.training_youtube_title {
  font-size: 1rem;
  /* font-weight: 600; */
  color: #24b3b9;
  margin-bottom: 0.8rem;
}

.trainer_container {
  display: flex;
  gap: 1rem;
}

.trainer_info {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  width: 90%;
}

.trainer_name {
  font-size: 1rem;
  font-weight: 500;
  color: #707070;
}

.star_font_size::before {
  font-size: 1rem;
}

.trainer_description {
  font-size: 0.9rem;
  color: #878787;
}

.trainer_image {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid #c7c7c7;
  cursor: pointer;
}

.trainer_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.training_plan {
  margin-top: 2rem;
}

.training_plan_title {
  font-size: 1.2rem;
  font-weight: 600;
  color: #707070;
  margin-bottom: 1rem;
}
.training_plan_content {
  display: flex;
  flex-wrap: wrap;
  gap: 1.2rem;
  width: 100%;
}

.training_plan_content_item {
  flex: 0 0 45%;
  display: flex;
  gap: 0.8rem;
  align-items: center;
  max-width: 340px;
}

.training_plan_content_item_title {
  font-size: 1rem;
  font-weight: 500;
  color: #707070;
}

.training_plan_program_content {
  display: flex;
  /* grid-template-columns: repeat(auto-fit, minmax(280px, 1fr)); */
  gap: 20px;
  row-gap: 20px;
  margin-top: 60px;
  flex-wrap: wrap;
  /* justify-items: center; */
}

.training_plan_program_content_item {
  display: flex;
  flex: 0 0 23%;
  flex-direction: column;
  gap: 1rem;
  /* width: fit-content; */
  cursor: pointer;
}

.payment-details-coupon-button-disabled {
  background-color: #ebebeb !important;
  color: rgb(170, 170, 170);
}
.training_plan_program_content_item_image {
  width: 100%;
  min-height: 230px;
  max-height: 230px;
  min-width: 230px;
}

.training_plan_program_content_item_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6px;
}

.training_plan_program_content_item_title {
  font-size: 1.2rem;
  /* font-weight: 600; */
  text-align: center;
  color: #878787;
}

.training_plan_program_content_item:hover
  .training_plan_program_content_item_title {
  color: #24b3b9;
}

/*******************************/

.headerImage {
  /* position: relative; */
  border-radius: 6px;
  background-size: cover;
  background-position: center center;
  /* flex: 1 1 auto; */
}

.offer-badge {
  /* position: absolute;
  top: 4.5rem; */
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  padding: 10px 10px;
  background-color: #df4b43;
  color: #fff;
  border-radius: 20px;
  width: fit-content;
  height: 40px;
}

.offer-badge-number {
  font-size: 12px;
  font-weight: bold;
}

.offer-badge-off {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 8px;
}

.title-price-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 5px;
}
.star-dep-cat {
  display: flex;
  gap: 10px;
  align-items: center;
}
.star-dep-cat p {
  font-size: 12px;
  color: #fff;
}
.price-container {
  /* position: absolute;
  top: 0.5rem; */
  display: flex;
  flex-direction: column;
  gap: 6px;
  justify-content: center;
  /* align-items: center; */
  background-color: #fcfcfc;
  border: 1px solid #f2f2f2;
  padding: 10px;
  border-radius: 10px;
  width: fit-content;
  /* margin-bottom: 10px; */
  /* height: 40px; */
}
.price_title {
  color: #707070;
  /* margin-bottom: 4px; */
  font-size: 12px;
  font-weight: 600;
}

/* body[dir="rtl"] .headerDitalsWrapper {
  margin-left: 2px;
}

body[dir="ltr"] .headerDitalsWrapper {
  margin-right: 2px;
} */

.price-number {
  line-height: 0.8;
}

.old-price {
  color: #777777;
  font-size: 12px;
  text-decoration: line-through;
}

.cannot-register-badge {
  background-color: #df4b43;
  color: #fff;
  border-radius: 10px;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-bottom: 10px; */
}

.box-w {
  max-width: 70%;
}

.price-label {
  color: #046c77;
  font-size: 12px;
  /* text-align: center; */
}

.price {
  color: #24b3b9;
  font-weight: bold;
  display: flex;
}

.cur {
  font-size: 8px !important;
  color: #707070;
}

.price span {
  font-size: 12px;
}

.headerContainer {
  width: 100%;
  /* background: #03a2b2; */
  background-image: url("../../../../../assets/image/newContainerBackground.png");
  background-size: cover;
  background-color: #29415e;
  background-position: center;
  display: flex;
  padding: 40px;
  padding-bottom: 100px;
  border-radius: 0 0 40px 40px;
  /* position: relative; */
}

.headerContent {
  display: flex;
  gap: 20px;
  width: 100%;
}

.image {
  max-width: 400px;
  height: 200px;
  border-radius: 6px;
  border: 2px solid #fff;
}

.flip-star {
  transform: rotateY(180deg);
  display: flex;
  /* flex-direction: column; */
  justify-content: end;
}

.star-font-size::before {
  content: "\f005\f005\f005\f005\f005";
  font: normal normal normal 14px/1 FontAwesome;
  letter-spacing: 3px;
  background: linear-gradient(
    90deg,
    #fff var(--percent),
    #8599b6 var(--percent)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.3rem;
}

.loading-container {
  display: flex;
  align-items: center;
  gap: 20px;
}

.loading-w-h {
  height: 300px;
  width: 200px;
}

.loading-w {
  width: 200px;
}

.header-container {
  gap: 20px;
  flex: 6 1 auto;
}
.headed-detials-mid {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.mb40 {
  margin-bottom: 40px;
}

.title {
  display: flex;
  align-items: center;
  gap: 10px;
}

.mt10 {
  margin-top: 10px;
}

.type-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  gap: 20px;
  bottom: 102px;
}

.d-flex {
  display: flex;
}

.type {
  border-radius: 6px;
  background: #8599b6;
  color: #fff;
  display: flex;
  align-content: center;
  justify-content: start;
  padding: 10px 20px;
  gap: 10px;
  width: 250px;
  align-items: center;
}

.btn-container {
  position: relative;
}

.btn {
}

.btn span {
  white-space: nowrap;
}

.calender-container {
  min-width: fit-content;
}

.calender-container-phone {
  display: none;
}

.calender-container p {
  font-size: 1rem;
  color: #fff;
}
.red_note {
  color: #a50c0c !important;
  position: absolute;
  bottom: 20px;
  font-size: 12px;
}

@media (min-width: 1679px) {
  .right {
    right: 830px;
  }
  .right_2 {
    right: 880px;
  }
}
@media (max-width: 1279px) {
  .type {
    width: 100%;
    min-width: fit-content;
  }

  .type-container {
    bottom: 90px;
  }

  .headerContainer {
    padding: 20px;
    padding-bottom: 80px;
  }

  .btn {
    top: 3rem;
  }

  .red_note {
    bottom: 0px;
  }

  .title {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (max-width: 991px) {
  .headerContent {
    flex-direction: column;
    align-items: unset;
    justify-content: unset;
  }
  .headerContainer {
    background-image: url("../../../../../assets/image/newContainerBackground.png");
    background-size: cover;
    background-color: #29415e;

    background-position: center;
    padding-top: 20px;
    flex-direction: column;
    gap: 10px;
    border-radius: 0 0 30px 30px;
  }
  .image {
    width: 100%;
    height: 300px;
    object-fit: cover;
    max-width: unset;
  }
  .rowFlex {
    justify-content: center;
  }
  .type {
    padding: 5px 15px;
  }
  .mb40 {
    margin-bottom: 20px;
  }
  .title {
    justify-content: unset;
  }
  .type-container {
    position: unset;
    flex-direction: column;
    gap: 10px;
  }

  /* .btn {
    position: unset;
    background: #fff !important;
    color: #046c77 ;
    width: 100%;
    justify-content: center;
    margin-top: 16px;
  } */

  .red_note {
    position: unset;
    width: 100%;
    justify-content: center;
    margin-top: 5px;
  }

  .calender-container {
    display: none;
  }

  .calender-container-phone {
    display: flex;
  }

  .calender-container-phone p {
    font-size: 1rem;
    color: #fff;
  }

  .training_top {
    flex-direction: column;
    gap: 1rem;
  }

  .training_separator {
    display: none;
  }

  .trainers_container {
    width: 100%;
    padding: 1rem;
  }
}

body[dir="rtl"] .training_bottom {
  padding-right: 17px;
}

body[dir="ltr"] .training_bottom {
  padding-left: 17px;
}

.user-container {
  display: flex;
  gap: 2rem;
  align-items: center;
  background-color: #8b8b8b57;
  padding: 10px;
  border-radius: 10px;
  /* width: 30%; */
}
.user-img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-inline-end: -20px;
  border: 2px solid #fff;
}

.user-img-more {
  width: 40px;
  height: 40px;
  font-size: 14px;
  color: #777777;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}
.trainers-name {
  font-size: 11px;
  font-weight: 500;
  color: #fff;
}
.trainers-tag {
  font-size: 11px;
  color: #fff;
}
.trainers-price {
  display: flex;
  gap: 10px;
}
.iconscontainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #fff;
}
.date-seat-hour {
  display: flex;
  gap: 10px;
  font-size: 10px;
  align-items: center;
  /* justify-content: end; */
  /* margin: 5px 0; */
}
.shareBackBtn {
  padding: 15px;
  border-radius: 50%;
  border: 1px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.back-share {
  display: flex;
  justify-content: end;
  gap: 5px;
}
.arrowIcon {
  width: 20px;
  height: 20px;
  transform: rotateY(180deg);
}
.desc {
  margin-inline-start: 56px;
  margin-top: 30px;
}
.desc p {
  color: #707070 !important;
  font-size: 16px;
}

.trainers_box {
  margin-inline-start: 56px;
  margin-top: 30px;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.trainer_item {
  flex: 0 0 30%;
  display: flex;
  /* align-items: center; */
  gap: 10px;
  width: 100%;
  padding: 15px;
  background-color: #b4b4b457;
  border-radius: 10px;
  min-width: 300px;
  cursor: pointer;
}
.trainer_item_image {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 2px solid #fff;
}
.trainer_item_title {
  font-size: 14px;
  font-weight: 500;
}
.trainer_des span {
  font-size: 10px !important;
}
.trainer_des p {
  font-size: 10px !important;
}
