.container-fluid {
  max-width: 1280px;
  padding: 0 20px;
}
.training-course-body {
  margin-bottom: 20px;
}

.training_details {
  width: 100%;
  display: grid;
  grid-template-columns: 4fr 8fr;
  gap: 2rem;
  align-items: start;
}

.training_details .row {
  width: 100%;
}
.training_wrapper {
  display: flex;
  width: 100%;
  height: auto;
  border-radius: 15px;
  margin: 0 auto;
  position: relative;
  justify-content: center;
  flex-direction: column;
  padding: 0 15px;
}

.training_title {
  font-size: 30px;
  margin-bottom: 20px;
  display: flex;
}

.training_after_grid {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  gap: 50px;
  margin: 20px 0;
}

.training_head {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 103%;
  gap: 10px;
}

.training_text {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 25px;
  align-items: center;
  height: 50px;
  font-size: 18px;
}

.training_text_small {
  font-size: 14px;
}

.training_up_level {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.3);
  height: 70px;
  font-size: 14px;
  width: 100%;
  padding: 0 30px;
  border-radius: 13px;
}

.certi_progress::after {
  content: "";
  background-image: url("../../../../assets/image/smallpng.png");
  width: 100px;
  height: 100px;
  background-repeat: no-repeat;
  background-size: cover;
  background-repeat: no-repeat;
  width: 70px;
  height: 50px;
  border-radius: 0%;
  border: none;
  background-color: #011516;
  -webkit-border-radius: 10%;
  z-index: 100;
}

.progress-bar {
  background-color: #18b1b9;
}

.progress {
  display: flex;
  width: 90%;
  margin: 10px auto;
  height: 4px;
}

@media (max-width: 1199px) {
  .training_title {
    font-size: 25px;
  }
}

@media (max-width: 991px) {
  .training_title {
    font-size: 20px;
  }

  .training_after_grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 30px;
  }

  .training_head {
    display: flex;
    justify-content: baseline;
    align-items: center;
    width: 100%;
  }
}

@media (max-width: 767px) {
  .training_details {
    display: flex;
    flex-direction: column-reverse;
  }

  .training_title {
    font-size: 18px;
  }

  .training_text {
    font-size: 14px;
  }
  .container-fluid {
    padding: 0 15px;
  }

  .training_wrapper {
    gap: 10px;
  }
  .course-complete-progress {
    flex-direction: column;
    gap: 10px;
  }

  .course-complete-progress .training_text {
    padding: 0;
  }
}
