@media only screen and (max-width: 768px) {
  .add-exams__row {
    flex-wrap: wrap;
  }

  .add-exams__row .tajah-form-row-element-v2 {
    flex: 1 1 100% !important;
    align-items: flex-start;
  }
  .add-exams__row .tajah-form-row-element-holder {
    width: 100%;
  }
}
