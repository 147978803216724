.MainCont {
  /* flex-direction: row; */
  width: 100%;
}
.rightDiv {
  flex: 2 1 auto;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.leftDiv {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
  /* border-radius: 16px; */
  background-color: rgb(239, 249, 250);
}
.selected_btn {
  display: flex;
  width: 100%;
}
.selctedCardsCont {
  width: 50%;
  border: 1px solid #e7e6e6;
  border-radius: 16px;
  padding: 25px;
  height: 450px;
}
.reversbtn {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.selectedCont {
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.groupName label {
  font-weight: 600;
}
.allTraineeTitle {
  border-bottom: 1px solid #e7e6e6;
  padding-bottom: 20px;
}
.allTraineeTitle p {
  font-weight: 600;
}
.searchInput {
  width: 100%;
  display: flex;
  background-color: #fff;
  padding: 15px;
  border-radius: 16px;
  gap: 20px;
}
.searchInput input {
  width: 100%;
}
.movebtnCont {
  background-color: #025299;
  padding: 10px;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
