.certification {
	margin-top: -100px;
}

.certification__wrapper {
	background-color: #21a6ac;
	border-radius: 1rem;
	color: #ffffff;
	padding: 2.5rem;
	display: flex;
	align-items: center;
	gap: 5rem;
	justify-content: space-between;
	height: 150px;
}

.certification__main {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
}

.certification__main-label {
	font-size: 14px;
}
.certification__main-title {
	font-size: 32px;
}

.certification__list {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 2rem;
	width: 70%;
}

.certification__item {
	display: flex;
	align-items: center;
	gap: 1rem;
}

.certification__item-content {
	display: flex;
	flex-direction: column;
}

.certification__item-number {
	color: #66c2c6;
	font-size: 2rem;
}

@media only screen and (max-width: 1024px) {
	.certification__wrapper {
		flex-direction: column;
		height: auto;
		gap: 2rem;
	}
	.certification__list {
		width: 100%;
	}
}

@media only screen and (max-width: 768px) {
	.certification__list {
		flex-direction: column;
    align-items: flex-start;
    width: max-content;
  }
}
