.rating-list-item {
    height: 45px;
    padding: 10px;
    display: flex;
    flex-direction: row;
}

.rating-list-item-data-entity {
    display: flex;
    flex-direction: row;
}

.rating-list-item-data-entity-text{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}