.description {
  padding: 20px 0;
  background-color: #fff;
}

.description-content {
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  max-width: 100%;
  word-break: break-word;
  color: #919191;
  margin-top: 15px;
}

.btn {
  width: 100%;
  margin-top: 35px;
  justify-content: center;
}
.training_course_content_info {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background-color: #fff;
  border-radius: 20px;
  margin-top: -50px;
  margin-bottom: 60px;
  padding: 50px !important;
}
.loading__container {
  padding: 20px 0;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}
