.tajah-trainee-activity-container{
    background-color: white;
    border-radius: 20px;
    padding: 40px;
    margin: 5px;
}
.tajah-trainee-activity-title{
    font-size: 30px;
    padding-bottom: 10px;
}

.tajah-trainee-activity-name{
    font-size: 25px;
    padding-bottom: 10px;
    max-width: 100%;
    word-break: break-word;
}

.tajah-trainee-activity-description{
    font-size: 20px;
    padding-bottom: 10px;
    color:#707070;
    max-width: 100%;
    word-break: break-word;
}

.tajah-trainee-activity-response{
    background-color: #FFF9F9;
    border-radius: 20px;
    border: 1px solid #ccc;
    padding: 20px;
    margin-bottom: 25px;
}

.tajah-trainee-activity-form-sender-img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: 0 5px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    position: absolute;
    margin-top: 0px;
}
.tajah-trainee-activity-response-sender-img{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    margin-left: 15px;
}
.tajah-trainee-activity-form-textarea{
    width: 80%;
    margin-right: 80px;
    border-radius: 20px;
    resize: none;
    padding: 20px;
}

.tajah-trainee-activity-form-sendButton{
    float: left;
    border-radius: 10px;
    background-color: #006D77;
    width: 130px;
    height: 40px;
    color: white;
    margin-bottom: 30px;
    margin-left: 30px;
}

.tajah-trainee-activity-form-download-response{
    /*float: left;*/
    border-radius: 10px;
    background-color: #006D77;
    width: 130px;
    height: 40px;
    color: white;
    /*margin-bottom: 30px;*/
    /*margin-left: 30px;*/
}
.tajah-trainee-activity-form-sendFile{
    border-radius: 10px;
    border-color: #006D77;
    background-color: white;
    width: 130px;
    height: 40px;
    color: black;
    padding: 0px;
}



