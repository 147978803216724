.admin_label {
  font-size: 18px;
  display: flex;
  margin: 0;
}
.goals-not-found,
.list-goals,
.add-goals-Edu {
  box-shadow: 1px 1px 11px #d1d1d1;
  border-radius: 10px;
  padding-bottom: 35px;
}
.goals-not-found {
  text-align: center;
}
.goals-not-found img {
  width: 115px;
  padding: 20px 0;
}
.goals-not-found p {
  color: rgb(137 137 137);
  text-align: center;
  margin: 0px;
  padding-top: 29px;
}
.btn-add-goals,
.btn-add-goals:hover,
.btn-add-goals:active {
  background: rgb(18, 110, 119);
  border: 1px solid rgb(18, 110, 119);
  color: rgb(255, 255, 255);
  border-radius: 10px;
  padding: 5px 29px;
}
.btn-add-goals i {
  font-size: 12px;
  padding: 0 5px;
}

/** Add Edu **/
.add-goals-Edu {
  margin-bottom: 20px;
}
.add-goals-Edu h4 {
  padding: 20px 30px;
}
.add-goals-Edu form {
}
.add-goals-Edu form label {
  display: block;
  color: #6b6b6b;
}
.add-goals-Edu form input {
  border: none;
  border-bottom-color: #c5c5c5;
  border-bottom-style: solid;
  border-block-width: 1px;
  width: 100%;
  background: transparent;
}
.add-goals-Edu form .btn_submit {
  display: inline-block;
  align-items: center;
  justify-content: center;
  min-width: 130px;
  padding: 5px 10px;
  background-color: #006d77;
  border-radius: 15px;
  color: #fff;
  font-size: 16px;
  border: none;
  outline: none;
  margin: 0 10px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  cursor: pointer;
}
body[dir="rtl"] .add-goals-Edu form .fa-trash {
  transform: translate(20px, 0px);
}
.add-goals-Edu form .fa-trash {
  color: #cc0000;
  font-size: 12px;
  transform: translate(20px, 0px);
}
.add-goals-Edu form .btn-remove {
  border: none;
  background: transparent;
  cursor: pointer;
}
.list-goals {
  padding: 20px;
  margin-bottom: 20px;
}
.list-goals h3 {
  padding-bottom: 30px;
}
