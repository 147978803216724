.train_courses {
    height: 200px;
  background: #011e20;
  width: 100%;
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  padding-top: 60px;
}

.train_courses_wrapper {
  display: flex;
  width: 100%;
  height: auto;
  border-radius: 15px;
  margin: 0 auto;
  position: relative;
  justify-content: center;
  flex-direction: column;
  padding: 0 15px;
}

.train_courses_label {
  display: block;
  position: relative;
}

.train_courses_label_top {
  font-size: 28px;
  display: flex;
}

.train_courses_search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 18px;
}

.train_courses_label_bottom {
  margin-top: 15px;
  font-size: 16cpx;
  color: #d4d4d4;
  display: flex;
}

.train_courses_select {
  width: 35%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  height: 50px;
}

.train_courses_selectLabel {
  display: flex;
}

.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon{
  color: white !important;
}

.train_courses_selectBox {
  display: flex;
  border-radius: 13px;
  /* background-color: rgba(0, 0, 0, 0.2); */
  background-color: rgba(255, 255, 255, 0.22);
  backdrop-filter: blur(10px);
  color: #fff;
  /* backdrop-filter: blur(100px); */
  position: relative;
  padding: 0 15px;
  border: none;
  width: 60%;
  -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
  height: 50px;
}

.menuList{
  background-color: rgba(1, 30, 32, 0.7) !important;
  color: white !important;
}

.menuList:hover{
  background-color: #006d77 !important;
  color: white !important;
}

.css-r8u8y9{
  padding: 0px !important;
}


.css-qiwgdb{
    color: white !important;
}


.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper{
  background-color: rgba(1, 30, 32, 0.7) !important;
  outline: none !important;
  border-radius: 15px !important;
}

.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
  color: white !important;
  border-radius: 15px !important;

}

.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper{
  background-color: rgba(1, 30, 32, 0.7) !important;
  outline: none !important;

}

.css-6hp17o-MuiList-root-MuiMenu-list{
  background-color: rgba(1, 30, 32, 0.7) !important;
  color: white !important;
  outline: none !important;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover{
  background-color: #006d77 !important;
}

.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline{
  border-width: 0px !important;
  border-color: transparent !important;
}


.css-177ic5c{
  background-color: rgba(1, 30, 32, 0.7) !important;
  outline: none !important;
  border-radius: 15px !important;

}

.css-fvipm8{
  border-radius: 15px !important;
  color: white !important;
  outline: none !important;
}

.css-fvipm8.Mui-focused .MuiOutlinedInput-notchedOutline{
  border-color: transparent !important;
  border-width: 0px !important;
}

.css-1km1ehz{
  color: white !important;
  background-color: rgba(1, 30, 32, 0.7) !important;

}

.css-1km1ehz:hover {
  text-decoration: none !important;
  background-color: #006d77 !important;
}

@media (max-width: 1199px) {
  .train_courses_search {
    font-size: 15px;
  }
  .train_courses_label_top {
    font-size: 26px;
  }
}

@media (max-width: 991px) {
  .train_courses {
    height: unset;
  }
  .train_courses_search {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 13px;
    flex-direction: column;
  }
  .train_courses_label_top {
    font-size: 22px;
  }
  .train_courses_label_bottom {
    font-size: 16px;
    color: #d4d4d4;
  }
  .train_courses_select {
    width: 100%;
    margin-top: 30px;
  }
}

@media (max-width: 767px) {
  .train_courses_label_top {
    font-size: 18px;
  }
  .train_courses_label_bottom {
    font-size: 14px;
    color: #d4d4d4;
  }
}
