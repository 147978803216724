.refund-list {
  padding: 2rem 0;
}

.refund-list__container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.refund-list__table-name {
  width: 150px;
  white-space: pre-wrap;
}

.departments-trainees__tables {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.departments-trainees__table-email {
  color: #24b3b9;
  cursor: pointer;
}

.departments-trainees__table-email:hover {
  color: #24b3b9 !important;
}

.reject-payment-modal__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem;
  border-bottom: 1px solid #ddd;
}

.reject-payment-modal__header h3 {
  font-size: 1.4rem;
  font-weight: 600;
  color: #046c77;
}

.reject-payment-modal__header img {
  cursor: pointer;
  width: 2rem;
  height: 2rem;
}

.reject-payment-modal__body {
  padding: 1.5rem;
}

.reject-payment-modal__submit {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.reject-payment-modal__submit-btn {
  padding: 1rem 6rem;
}

.payment-method--bank {
  color: #24b3b9;
  border-bottom: 1px solid #24b3b9;
  cursor: pointer;
  -webkit-appearance: button;
  background-color: transparent;
  background-image: none;
}
.note_table {
  white-space: pre-line;
}
