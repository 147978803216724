.specialties-list {
	padding: 2rem 0;
}

.specialties-list__container {
	display: flex;
	flex-direction: column;
	gap: 2rem;
}


.specialties-list__table-name,
.specialties-list__table-desc {
  width: 300px;
  white-space: pre-wrap;
}
