.card-counter {
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  color: rgb(20, 148, 154);
}
.link-cursor{
    cursor: pointer;
}

.card-image {
  margin-bottom: 10px;
  width: 80px;
}

.card-label {
  font-size: 16px;
  color: #333;
  font-weight: 600;
}

.card-number {
    font-size: 22px;
    font-weight: bold;
    width: 100%;
    position: relative;
    padding-top: 10px;
    color: rgb(20, 148, 154);
}
.card-number i{
    justify-content: flex-end;
    position: absolute;
    right: 0;    
    top: 20px;
    font-size: 17px;
}
body[dir = 'rtl'] .card-number i {
    right: unset;
    left: 0;    
}

@media (max-width: 768px) {
  .card-counter {
    align-items: center;
  }
}
