.header_profile {
  background: #011e20;
  padding: 15px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
}

.profile_inside_btn {
  width: 100%;
  margin: 10px;
  height: 50px;
  font-size: 14px;
  background-color: #006d77;
  color: #fff;
  border-radius: 15px;
  outline: none;
  box-shadow: none;
  overflow: hidden;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile_soical_img {
  width: 30px;
  padding: 0 5px;
}

.profile_btn {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
}

.profile_head_soical {
  font-size: 10px;
  display: flex;
  justify-content: space-between;
}

.profile_dname {
  margin: 0 20px;
}

.profile_dtop {
  font-size: 25px;
  text-align: initial;
}

.profile_dbottom {
  font-size: 18px;
}

.profile_wrapper {
  display: flex;
  width: 100%;
  height: auto;
  border-radius: 15px;
  margin: 0 auto;
  position: relative;
  justify-content: center;
  flex-direction: column;
  padding: 0 15px;
}

.profile_dhead {
  display: flex;
  align-items: center;
}

.profile_dimage {
  background-image: url("../../../../assets/image/team7.png");
  color: #000;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d4d4d4;
  border-radius: 50%;
  object-fit: cover;
  background-position: center;
  background-size: cover;
}

@media (max-width: 1199px) {
  .profile_dimage {
    color: #000;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #d4d4d4;
    border-radius: 50%;
  }
}

@media (max-width: 991px) {
  .header_profile {
    padding: 10px 0;
  }

  .profile_btn {
    justify-content: center;
  }

  .profile_dhead {
    justify-content: center;
    align-items: center;
  }

  .profile_dimage {
    color: #000;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #d4d4d4;
    border-radius: 50%;
  }

  .profile_dtop {
    font-size: 22px;
  }

  .profile_dbottom {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .profile_dhead {
    margin: 10px 0;
  }

  .profile_dtop {
    font-size: 18px;
  }

  .profile_dbottom {
    font-size: 14px;
  }

  .profile_head_soical {
    margin: 0px 0 5px;
  }

  .profile_inside_btn {
    font-size: 14px;
  }
}
