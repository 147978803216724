.admin_flex {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.admin_card {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 97%;
  margin: 0 auto;
  padding: 50px 0;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 2px 2px 10px #ddd;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.admin_card .table {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.search_input {
  width: 60%;
  display: flex;
  position: relative;
  height: 40px;
  border: 1px solid #dcdcdc;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  -ms-border-radius: 10px !important;
  -o-border-radius: 10px !important;
}

.search_input input {
  width: 100%;
  border-radius: 15px;
  color: #000;
  backdrop-filter: blur(10px);
  padding: 0 15px;
  border: none;
}

.search_input input::placeholder {
  color: #686868;
}

.search_input .icon {
  position: absolute;
  left: 10px;
  top: 20%;
  z-index: 99;
}

.release_icon {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-evenly;
}

.admin_flex {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.assign_card {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  padding: 50px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 2px 2px 10px #ddd;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  min-height: 450px;
}

.admin_card .table {
  width: 90%;
}

.search_input {
  width: 60%;
  display: flex;
  position: relative;
  height: 40px;
  border: 1px solid #686868;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  -ms-border-radius: 10px !important;
  -o-border-radius: 10px !important;
}

.search_input input {
  width: 100%;
  border-radius: 15px;
  color: #000;
  backdrop-filter: blur(10px);
  padding: 0 15px;
  border: none;
}

.search_input input::placeholder {
  color: #686868;
}

.search_input .icon {
  position: absolute;
  left: 10px;
  top: 20%;
  z-index: 99;
}

.release_icon {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-evenly;
}

.btn-assign {
  background-color: #006d77;
  color: #fff;
}

.btn-assign-action {
  display: flex;
}

.trainer-img {
  width: 50px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.trainer-name {
  height: 88px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table_header {
  padding: 0 0 55px 0;
  margin: 0;
  width: 90%;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1;
  background: rgba(0, 0, 0, 0.6);
}

.container-modal {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.assign-modal {
  background-color: #fff;
  width: 60%;
  z-index: 10000;
  position: fixed;
  top: calc(50% - 250px);
  margin: auto;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  padding: 30px 50px 50px;
}

.admin_label_card {
  display: flex;
  width: 90%;
  margin-bottom: 30px;
  justify-content: space-between;
  align-items: center;
}

.table_bank_tran{
  background-color: white ;
  width:100%
}

@media (max-width: 991px) {
  .assign-modal {
    width: 90%;
  }
}
