.exam-canvas {
    padding-right: 15px;
    padding-left: 15px;
    margin-top: 62px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.tajah-exam-progress {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
