.phone-select {
  width: 110px !important;
  min-width: 110px !important;
  font-size: 13px;
}

.phone-select .css-1jqq78o-placeholder {
  /* margin-top: 7px; */
  color: white !important;
  margin: 0 !important;
}

.phone-select .css-1fdsijx-ValueContainer {
  /* margin-top: -10px; */
  color: white !important;
}

.phone-select .css-1dimb5e-singleValue,
.phone-select .css-olqui2-singleValue {
  /* margin-top: 10px; */
  color: white !important;
}

.phone-select .css-1xc3v61-indicatorContainer {
  color: white !important;
}

.phone-select .css-1u9des2-indicatorSeparator {
  background-color: white !important;
}

.phone-select .css-166bipr-Input {
  color: white !important;
}

.phone-select .css-15lsz6c-indicatorContainer {
  color: white !important;
}

.phone-select .css-qbdosj-Input {
  display: flex !important;
}

/* @media (max-width: 767px) {
  .phone-select {
    width: 75px !important;
    min-width: 75px !important;
  }
} */
