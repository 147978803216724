.refund-list {
  padding: 2rem 0;
}

.refund-list__container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.refund-list__table-name {
  width: 150px;
  white-space: pre-wrap;
}
