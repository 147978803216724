.tajah-list-exams-result-header {
  /* background-color: white; */
  border-radius: 20px;
  border-color: black;
  padding: 40px 0;

  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.line {
  width: 100%;
  border-bottom: 1px solid gray;
}

.tajah-btn-nav-to-answers {
  background: #126e77;
  color: #fff;
  border: none;
  padding: 10px 12px;
  border-radius: 15px;
  cursor: pointer;
}
