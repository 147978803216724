.modal_head {
  width: 100%;
  padding: 50px 20px 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.modal_head_label {
  font-size: 30px;
  margin: 0 0 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal_head_label_bottom {
  font-size: 18px;
  margin: 0 0 20px 0;
  display: flex;
  align-items: center;
}

.modal_pay_head_card_after {
  display: flex;
  flex-direction: column;
}

.modal_flex {
  width: 80%;
  display: flex;
  align-items: center;
}

.modal_course_img_after {
  display: flex;
  width: 160px;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.modal_course_text_after {
  display: flex;
  padding: 10px 20px;
  width: 100%;
  word-break: break-all;
}

.overall_modal {
  display: flex;
  justify-content: space-between;
}

.overall_price {
  padding: 20px 0 0;
  font-size: 16px;
}

.modal_coupon_video {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #006d77;
  padding: 20px 0 0;
  font-size: 16px;
}

.modal_coupon_video img {
  width: 40px;
  margin: 0 10px;
}

.modal-body {
  display: flex;
  flex-direction: column;
  padding: 35px;
}

.modal_body_label {
  font-size: 30px;
  margin-bottom: 20px;
}

@media (max-width: 991px) {
  .modal_head_label,
  .modal_body_label {
    font-size: 22px;
  }

  .modal_head_label_bottom {
    font-size: 15px;
  }
}

@media (max-width: 767px) {
  .modal_flex {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  .modal_course_text_after {
    font-size: 12px;
    padding: 10px;
  }

  .overall_modal {
    flex-direction: column;
  }

  .modal-body {
    padding: 20px;
  }

  .form-payment__group {
    flex-direction: column !important;
  }
  .form-payment__group > div {
    flex: 1 1 100% !important;
  }
  .form-payment__group input,
  .form-payment__group .personal_input_in {
    width: 100% !important;
  }

  .form-payment__photo {
    flex-direction: column !important;
    gap: 20px !important;
  }
  .form-payment__photo-wrapper {
    flex: unset !important;
    width: 80px !important;
    height: 80px !important;
  }
}
.form-payment .input_container {
  justify-content: flex-start;
}
.form-payment .input_container .personal_input_in.PhoneInput {
  justify-content: flex-start;
  border-bottom: 1px solid #c9c9c9;
  margin-bottom: 0;
  padding-bottom: 6px;
}
.form-payment .PhoneInputCountrySelectArrow {
  margin: 0 5px;
}
