.rdp-caption_label {
  color: #7c7c7c;
}
.rdp-head {
  color: #979797 !important ;
}
body[dir="rtl"] .rdp-nav_icon {
  transform: scaleX(-1) !important;
}
.rdp-nav_button {
  border-radius: 30%;
  background-color: #c9c7c741;
  margin: 0 2px;
}
.rdp-caption {
  border-bottom: 1px solid #c9c7c7;
  padding-bottom: 10px;
}
.rdp-nav_icon {
  color: #7c7c7c;
}
