.card_component_container{
  background-color: white;
  border-radius: 10px;
  border: 1px solid white;
  margin-left: 5px;
  text-align: center;
  /* width: 100%; */
  padding: 5px;
  height: 100%;
}

.card_hint_container{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}