.input-file {
}

.input-file__container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.input-file__label {
  color: #777777;
}

.input-file__container__note {
  color: #b1b1b1;
  font-size: 12px;
}
.input-file__container__field {
  display: flex;
  align-items: center;
  height: 50px;
}

.input-file__container__field-item {
  border: 1px solid #eee;
  height: 100%;
  padding: 0 1rem;
  flex: 1;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  color: #b1b1b1;
  display: flex;
  align-items: center;
  cursor: -webkit-grab;
  font-size: 15px;
}
.input-file__container__field-btn {
  height: 100%;
  border: none;
  background-color: #046c77 !important;
  border-radius: 6px;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  color: #ffffff;
}

.input-file__container__previews {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
}

.input-file__container__previews__item {
  background-color: #f9f9f9;
  border-radius: 6px;
  border: 1px solid #ebebeb;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem 1.3rem;
}

.input-file__container__previews__item-audio {
  background-color: #24b3b9;
}

.input-file__container__previews__item-document {
  background-color: rgb(223 223 223 / 20%);
}

.input-file__container__previews__item-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.input-file__container__previews__item-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.input-file__container__previews__item-audio .input-file__container__previews__item-img {
  background-color: #046c77 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.input-file__container__previews__item-document .input-file__container__previews__item-img {
  background-color: #dfdfdf;
  display: flex;
  align-items: center;
  justify-content: center;
}
.input-file__container__previews__item-audio .input-file__container__previews__item-img img,
.input-file__container__previews__item-document .input-file__container__previews__item-img img {
  width: auto;
  height: auto;
}

.input-file__container__previews__item-name {
  flex: 1;
  color: #046c77 !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 150px;
}

.input-file__container__previews__item-remove {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.input-file__container__gallery {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 150px;
  gap: 0.5rem;
  border: 1px dashed #ebebeb;
  border-radius: 6px;
  cursor: pointer;
  border: 1px dashed #ccc !important;
  height: 200px !important;
  padding: 0 1rem;
}

.input-file__container__gallery p {
  color: #777777;
  text-align: center;
}

.input-file-error {
  padding: 0 !important;
  margin: 0 !important;
}

.input-file__container__fields {
  align-items: center;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .input-file__container__previews__item {
    width: 100%;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 380px) {
  .input-file__container__field-text {
    display: none;
  }
}
