.profiles hr {
  border: none;
  height: 3px;
  color: #ccc;
  background-color: #ccc;
  text-align: left;
  margin-top: -23px;
}

.trainee_tag {
  display: flex;
  font-size: 25px;
  margin: 20px 0;
  width: 60%;
}

.trainee_cer_image {
  display: flex;
  justify-content: center;
  margin: 20px 0;
  width: 100%;
}

.cer_img {
  width: 60%;
}

.trainee_cer_btn {
  display: flex;
  margin: 20px 0;
  justify-content: center;
  align-items: center;
}

.cer_btn {
  background-color: #006d77;
  color: #fff;
  padding: 15px 50px;
  outline: none;
  border: none;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}

.trainee_cer_dlabel {
  display: flex;
  margin-top: 50px 0 100px;
  font-size: 24px;
}

.trainee_cer_dlist {
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  text-align: initial;
}
