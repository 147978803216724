.coursesContainer {
  padding-top: 50px !important;
}

.titleCoureses {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 20px;
}
.titleFont {
  font-size: 35px;
  font-weight: 600;
}
.no_courses {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  font-size: 1.5rem;
  font-weight: 500;
  color: #025299;
}
