.button {
  border: none;
  background-color: #046c77;
  color: #ffffff;
  border-radius: 6px;
  height: 50px;
  padding: 0 1rem;
  font-size: 20px;
  width: 100%;
}
