.trainers_card {  
  width: 100% !important;
  background-color: #011e20;
  font-size: 18px;
  height: 375px;
}
.height-auto{
    height: auto !important;
}
.trainers_image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #000;
  background-color: #fff;
  margin: 0;
  box-shadow: 0 -15px 20px transparent;
  transition: all .5s ease-in-out;
  overflow: hidden;
}

.trainers_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  height: 260px;
  transition: all .5s ease-in-out;
}
.trainers_img:hover{
    transform: scale(1.2);
}

.trainers_name {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 15px 10px;
  text-align: center;
    line-height: 27px;
}

.trainers_name_type {
  font-size: 16px;
}

@media (max-width: 1199px) {
  .trainers_card {
    font-size: 18px;
    width: 200px;
    height: 350px;
  }
  .trainers_name_type {
    font-size: 14px;
  }
}

@media (max-width: 991px) {
  .trainers_card {
    font-size: 14px;
  }
  .trainers_name_type {
    font-size: 12px;
  }
}

@media (max-width: 767px) {
  .trainers_card {
    font-size: 12px;
  }
}
