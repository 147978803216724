.search_box {
  background: url("../../../assets/image/homepage.png") center no-repeat;
  background-size: cover;
  height: 90vh;
  padding: 100px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}

.search_box_wrapper {
  display: flex;  
  background-color: rgb(1 30 32 / 60%);
  width: 100%;
  height: auto;
  border-radius: 15px;
  position: relative;
  justify-content: space-between;
  flex-direction: column;
}

.search_box_inner {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 10px 75px 10px;
  height: 100%;
}
.search_box_desc {
  margin: 5px 0;
  font-size: 30px;
  text-align: center;
}

.search_box_header {
  margin: 10px 0;
  text-align: center;
  font-size: 18px;
}
.search_box_desc {
  margin: 5px 0;
  font-size: 45px;
  text-align: center;
}

.search_box_search {
  display: flex;
  justify-content: center;
  margin: 35px 0 10px;
  width: 65%;
}
.search_box_icon{
    width: 35%;
    position: relative;
}
.search_box_select {
  display: flex;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.22);
  color: #fff;
  backdrop-filter: blur(10px);
  position: relative;
  /* padding: 0 15px; */
  border: none;
  width: 85%;
  margin: 0 20px;
  height: 50px;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}
.search_box_icon::before{
    content: "\f078";
    font-family: "FontAwesome";
    position: absolute;
    top: 15px;    
    right: 33px;
    z-index: 2; 
}
body[dir='rtl'] .search_box_icon::before{
    left: 33px;
    right: unset
}

.search_box_select option {
  background-color: rgba(0, 0, 0, 0.34);
  color: white;
}

.search_box_input {
  width: 60%;
  display: flex;
  position: relative;
  height: 50px;
}

.search_box_input {
  width: 60%;
  display: flex;
  position: relative;
  height: 50px;
}

.search_box_input input {
  width: 100%;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
  backdrop-filter: blur(10px);
  padding: 0 15px;
  border: none;
}

.search_box_input input::placeholder {
  color: #fff;
}

.search_box_input .icon {
  position: absolute;
  right: 10px;
  top: 25%;
  z-index: 99;
  cursor: pointer;
}

body[dir="rtl"] .search_box_input .icon {
  left: 10px;
  right: unset;
}

.search_box_footer {
  width: 100%;
  border-radius: 15px;
  background-color: #000000;
  height: 70px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
  font-size: 18px;
}

.menuList{
  background-color: rgba(1, 30, 32, 0.7) !important;
  color: white !important;
}

.menuList:hover{
  background-color: #006d77 !important;
  color: white !important;
}

.css-qiwgdb{
  color: white !important;
}

.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper{
  background-color: rgba(1, 30, 32, 0.7) !important;
  outline: none !important;
  border-radius: 15px !important;
}

.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
  color: white !important;
  border-radius: 15px !important;

}

.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper{
  background-color: rgba(1, 30, 32, 0.7) !important;
  outline: none !important;

}

.css-6hp17o-MuiList-root-MuiMenu-list{
  background-color: rgba(1, 30, 32, 0.7) !important;
  outline: none !important;
  color: white !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.css-r8u8y9{
  padding: 0px !important;
}


.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover{
  background-color: #006d77 !important;
  color: white !important;
}

.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline{
  border-width: 0px !important;
  border-color: transparent !important;
}

.css-177ic5c{
  background-color: rgba(1, 30, 32, 0.7) !important;
  outline: none !important;
  border-radius: 15px !important;

}

.css-fvipm8{
  border-radius: 15px !important;
  color: white !important;
  outline: none !important;
}

.css-fvipm8.Mui-focused .MuiOutlinedInput-notchedOutline{
  border-color: transparent !important;
  border-width: 0px !important;
}

.css-1km1ehz{
  color: white !important;
  background-color: rgba(1, 30, 32, 0.7) !important;

}

.css-1km1ehz:hover {
  text-decoration: none !important;
  background-color: #006d77 !important;
}

@media (max-width: 1199px) {
  .search_box_desc {
    font-size: 25px;
  }
  .search_box_header {
    font-size: 18px;
  }
  .search_box_desc {
    font-size: 25px;
  }
  .search_box_select {
    height: 45px;
  }
  .search_box_input input {
    height: 45px;
  }
  .search_box_footer {
    font-size: 18px;
  }
}

@media (max-width: 991px) {
  .search_box_desc {
    font-size: 20px;
  }
  .search_box_header {
    font-size: 14px;
  }
  .search_box_desc {
    font-size: 20px;
  }
  .search_box_search {
    /* flex-direction: column; */
    /* align-items: center; */
    width: 100%;
  }
  .search_box_select {
    margin-bottom: 20px;
    width: 100%;
    height: 40px;
  }
  .search_box_input input {
    height: 40px;
  }
  .search_box_input {
    width: 100%;
  }
  .search_box_footer {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .search_box {
    height: unset;
  }
  .search_box_desc {
    font-size: 18px;
  }
  .search_box_header {
    font-size: 12px;
  }
  .search_box_desc {
    font-size: 18px;
  }
  .search_box_search {
    flex-direction: column;
    align-items: center;
  }

  .search_box_select {
    margin-bottom: 20px;
    width: 100%;
    height: 40px;
  }
  .search_box_input input {
    height: 40px;
  }
  .search_box_input {
    width: 100%;
  }
  .search_box_footer {
    font-size: 12px;
  }
  .search_box_footer {
    display: none;
  }
}
