.filter-box {
  background-color: #fff;
  padding: 30px 0;
  margin-bottom: 20px;
}

.filter-box__field {
  margin-bottom: 1.5rem;
  position: relative;
}

.filter-box__field input,
.filter-box__field select {
  width: 100%;
  height: 50px;
  padding: 0 1rem;
  border: 1px solid #ebebeb;
  background-color: #ffffff;
  border-radius: 6px;
  color: #b1b1b1;
}

.filter-box__field input::placeholder {
  color: #b1b1b1;
}

.filter-box__field-reset {
  position: absolute;
  right: 1.4rem;
  top: 55%;
  /* transform: translateY(-50%); */
  -webkit-appearance: button;
  background-color: transparent;
  background-image: none;
}

body[dir="rtl"] .filter-box__field-reset {
  right: unset;
  left: 1.4rem;
}

.filter-box__field__item {
  width: 100%;
  position: relative;
}

.filter-box__field .border-button {
}

.filter-box__field-reset-btn {
  border: none;
  background: transparent;
  color: rgb(3, 162, 178);
  border: 1px solid rgb(3, 162, 178);
  border-radius: 6px;
  height: 50px;
  padding: 0 1rem;
  font-size: 20px;
  width: 100%;
}

.align-end {
  align-items: flex-end;
}
