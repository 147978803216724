.training-course-comments-container {
    padding-top: 40px;
    padding-left: 140px;
    padding-right: 140px;
}

.training-course-comment-outline {
    border-color: #c1c1c1;
    border-radius: 13px;
    border-width: 1px;
    border-style: solid;
    margin-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
}

.training-course-comment-header {
    display: flex;
    flex-direction: row;
    height: 70px;
    align-items: center;
}

.training-course-commenter {
    padding-right: initial;
    padding-left: 10px;
}

body[dir="rtl"] .training-course-commenter {
    padding-right: 10px;
    padding-left: initial;
}

.training-course-comment-body {
    padding: 5px 66px;
    max-width: 100%;
    word-wrap: break-word;
}

.training-course-comment-footer {
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.training-course-comment-like-icon {
    width: 15px;
    margin: 0px 10px;
}

.training-course-comment-like-text {
    font-size: 13px;
}

.courses_card_icon {
    background-image: url("../../../../../assets/image/smallDoc.png");
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: 0 10px;
    object-fit: cover;
    background-position: center;
    background-size: cover;
}

@media (max-width: 991px) {
    .training-course-comments-container {
        padding-left: 90px;
        padding-right: 90px;
    }
}

@media (max-width: 767px) {
    .training-course-comments-container {
        padding-left: 40px;
        padding-right: 40px;
    }
}
