.title {
  font-size: 22px;
  font-weight: 600;
  color: #000000;
}
.cardsCont {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  flex-wrap: wrap;
  /* justify-content: center; */
}
.btn {
  width: 100px;
  display: flex;
  justify-content: center;
}
.main-box {
  padding: 0 4rem;
  margin: 40px 0;
}
@media (max-width: 768px) {
  .main-box {
    padding: 1rem;
  }
}
