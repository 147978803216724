.exhibition_list {
  padding: 2rem 0;
}

.exhibition_list__container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.exhibition_list__actions {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.exhibition_list__actions-btn {
  background: none;
  border: none;
  padding: 0;
}

.container-fluid {
  max-width: 1280px;
  padding: 0;
  margin-right: auto;
  margin-left: auto;
}

.word-break {
  word-break: break-all !important;
}
