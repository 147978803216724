.add-course-modal {
  background-color: rgb(33, 33, 33, 0.45);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-course-modal__container {
  background-color: #ffffff;
  width: 50rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  border-radius: 15px;
  padding: 1.5rem;
  position: relative;
}
@media (max-width: 1200px) {
  .add-course-modal__container {
    width: 40rem;
  }
}
@media (max-width: 800px) {
  .add-course-modal__container {
    width: 30rem;
  }
}
@media (max-width: 600px) {
  .add-course-modal__container {
    width: 25rem;
  }
}
@media (max-width: 400px) {
  .add-course-modal__container {
    width: 20rem;
  }
}

.add-course-modal__close {
  position: absolute;
  right: 1.5rem;
  left: auto;
  top: 1.5rem;
  cursor: pointer;
}

body[dir="rtl"] .add-course-modal__close {
  left: 1.5rem;
  right: auto;
}

.add-course-modal__head {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
}

.add-course-modal__head-title {
  color: #046c77;
  font-size: 30px;
  font-weight: 500;
}

.add-course-modal__content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.add-course-modal__content-title {
}

.add-course-modal__content__box {
  display: flex;
  flex-direction: column;
}

.add-course-modal__content__box__head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}
.add-course-modal__content__options {
  max-height: 300px;
}
.add-course-modal__content__options__item {
  /* display: flex;
	align-items: center;
	justify-content: space-between; */
}

.add-course-modal__content__options__item input {
  /* cursor: pointer; */
  display: none;
}
.add-course-modal__content__options__item-label {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  padding-left: 2rem;
}
body[dir="rtl"] .add-course-modal__content__options__item-label {
  padding-left: 0;
  padding-right: 2rem;
}
.add-course-modal__content__options__item-button {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.add-course-modal__content__box__actions {
  display: flex;
  justify-content: flex-end;
}

.add-course-modal__content__box__actions-btn {
  background-color: #046c77;
  border-radius: 6px;
  border: none;
  padding: 0 1rem;
  height: 50px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 250px;
}

.add-course-modal__content__options__item input[type="radio"]:checked,
.add-course-modal__content__options__item input[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.add-course-modal__content__options__item
  input[type="radio"]:checked
  + .add-course-modal__content__options__item-label,
.add-course-modal__content__options__item
  input[type="radio"]:not(:checked)
  + .add-course-modal__content__options__item-label {
  position: relative;
}
.add-course-modal__content__options__item
  input[type="radio"]:checked
  + .add-course-modal__content__options__item-label::before,
.add-course-modal__content__options__item
  input[type="radio"]:not(:checked)
  + .add-course-modal__content__options__item-label::before {
  content: "";
  position: absolute;
  left: 0.5rem;
  top: 50%;
  width: 18px;
  height: 18px;
  border: 1px solid #ebebeb;
  border-radius: 100%;
  background: #fff;
  transform: translateY(-50%);
}
body[dir="rtl"]
  .add-course-modal__content__options__item
  input[type="radio"]:checked
  + .add-course-modal__content__options__item-label::before,
body[dir="rtl"]
  .add-course-modal__content__options__item
  input[type="radio"]:not(:checked)
  + .add-course-modal__content__options__item-label::before {
  right: 0.5rem;
}
.add-course-modal__content__options__item
  input[type="radio"]:checked
  + .add-course-modal__content__options__item-label::after,
.add-course-modal__content__options__item
  input[type="radio"]:not(:checked)
  + .add-course-modal__content__options__item-label::after {
  content: "";
  width: 12px;
  height: 12px;
  background: #046c77;
  position: absolute;
  top: 50%;
  left: 0.69rem;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
body[dir="rtl"]
  .add-course-modal__content__options__item
  input[type="radio"]:checked
  + .add-course-modal__content__options__item-label::after,
body[dir="rtl"]
  .add-course-modal__content__options__item
  input[type="radio"]:not(:checked)
  + .add-course-modal__content__options__item-label::after {
  right: 0.67rem;
}

.add-course-modal__content__options__item
  input[type="radio"]:not(:checked)
  + .add-course-modal__content__options__item-label::after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.add-course-modal__content__options__item
  input[type="radio"]:checked
  + .add-course-modal__content__options__item-label::after {
  opacity: 1;
  -webkit-transform: transformY(-50%) scale(1);
  transform: transformY(-50%) scale(1);
}
