.delete-modal {
  display: flex;
  width: 100%;
  height: 200px;
  position: fixed;
  z-index: 1000000000;
  justify-content: center;
  align-items: center;
  top: 50%;
}

.delete-box {
  display: flex;
  flex-direction: column;
  padding: 35px 50px;
  justify-content: space-between;
  width: 350px;
  height: 200px;
  border-radius: 10px;
  background-color: #fff;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.delete-button {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
}

.delete-modal-button {
  width: 70px;
  padding: 5px 25px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.delete-yes {
  background-color: #d20101;
  color: #fff;
}



.tajah-basic-modal-footer {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.tajah-basic-modal-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.tajah-basic-modal-body {
  margin-top: 10px;
}

.tajah-basic-modal-md {
  width: 60%;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 15px;
  height: 500px;
  overflow-y: auto;
}

.tajah-basic-modal-mui {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 15px;
  max-height: 80%;
  overflow-y: scroll;
}

.tajah-modal-dismiss {
  color: red;
  cursor: pointer;
}
