.react-datepicker__close-icon {
  right: 45px !important;
}
body[dir="rtl"] .react-datepicker__close-icon {
  right: unset !important;
  left: 45px !important;
}

.react-datepicker__close-icon::after {
  content: "";
  background-image: url("../../../assets/icons/delete.svg") !important;
  background: none;
  background-size: contain;
  background-repeat: no-repeat;
  width: 1rem;
  height: 1rem;
}