.datepicker-field {
  width: 100%;
}
.datepicker-field-input {
  width: 100%;
  height: 50px;
  padding: 0 1rem;
  border: 1px solid #ebebeb;
  background-color: #ffffff;
  border-radius: 6px;
  color: #777777;
}

.datepicker-field-input::placeholder {
  color: #b1b1b1;
}

.datepicker-field label {
  color: #777777;
}

.datepicker-field svg {
  position: absolute;
  padding: unset !important;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
}

body[dir="rtl"] .datepicker-field svg {
  right: unset;
  left: 1rem;
}

.datepicker-field-error {
  margin: 0 !important;
  padding: 0 !important;
  height: 10px !important;
}

.datepicker-field-note {
  color: #b1b1b1;
  font-size: 12px;
  margin-top: 0.4rem;
}
