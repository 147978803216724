.training-course-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 1rem;
}

.training-course-content-header {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.training-course-content-header-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.training-course-content-header-title h2 {
  font-size: 1.8rem;
  font-weight: 600;
  color: #24b3b9;
}

.training-course-actions {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.private-chat {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.new-msg {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: #fff;
}

.training-course-content-header-info {
  display: flex;
  align-items: center;
  gap: 8rem;
}

.training-course-content-info-one {
  display: flex;
  align-items: center;
  gap: 1rem;
  min-width: fit-content;
}

.training-course-content-info-two {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.training-course-content-progress-note {
  padding: 0.5rem 1rem;
  border-radius: 8px;
  border: 1px solid #cfcfcf;
  width: fit-content;
  font-size: 1rem;
}

.training-course-content-date span {
  font-size: 0.8rem;
  font-weight: 500;
}

.training-course-content-rating {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.rate-now-btn {
  padding: 0.2rem 0.6rem;
  font-size: 0.8rem;
}

.stars-font-size::before {
  font-size: 1rem;
}

.training-course-content-trainers {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.trainer_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.4rem;
  width: fit-content;
}

.trainer_name {
  cursor: pointer;
}

.trainer_rating {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.trainer_image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid #c7c7c7;
  cursor: pointer;
}

.trainer_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.training-course-content-body {
  margin-top: 2rem;
}

.training-course-content-syllabus {
  font-size: 1.5rem;
  font-weight: 500;
  color: #777;
}

.chapter-row-opened {
  border-radius: 8px;
  border: 1px solid #cfcfcf;
  min-height: 150px;
}

.chapter-row-content {
  display: none;
}

.chapter-row-content-opened {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}

.chapter-row-content-item {
  font-size: 1.2rem;
  font-weight: 600;
  color: #555;
  cursor: pointer;
}

.main-box__head__actions {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  overflow-x: auto;
  white-space: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.main-box__head__actions::-webkit-scrollbar {
  display: none;
}

.main-box__head__action__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
}

.training-rating__list {
  direction: ltr;
}
body[dir="rtl"] .training-rating__list {
  direction: rtl;
}

@media (max-width: 991px) {
  .course-slider-model-container {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .main-box__head__actions {
    width: 100%;
    gap: 0.5rem;
  }

  .training-course-content-header-title {
    flex-direction: column;
    gap: 1rem;
    align-items: normal;
  }

  .training-course-actions {
    flex-direction: column;
    align-items: stretch;
  }

  .main-box__head__actions button {
    font-size: 0.8rem;
  }
}

/* .syllabus {
  min-width: 25%;
  width: 25%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.syllabus-header {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 1rem;
}

.syllabus-header-image {
  width: 100%;
  height: 300px;
}

.syllabus-header-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.syllabus-header-details {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}

.syllabus-header-details h2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #555;
}

.syllabus-header-details-desc {
  font-size: 1.2rem;
  font-weight: 400;
  color: #555;
}

.syllabus-body {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.syllabus-body-courses {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.syllabus-body-courses-item {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  cursor: pointer;
  padding-bottom: 0.5rem;
}

.syllabus-body-courses-item-selected {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  cursor: pointer;
  padding-bottom: 0.4rem;
  border-bottom: 2px solid #21a6ac;
}

.syllabus-body-courses-item:hover {
  border-bottom: 2px solid #555;
  padding-bottom: 0.4rem;
}

.syllabus-body-courses-item-number {
  font-size: 1.2rem;
  font-weight: 600;
  color: #555;
  min-width: 2rem;
}

.syllabus-body-courses-item-name {
  font-size: 1rem;
  font-weight: 500;
  color: #555;
  padding-top: 0.2rem;
} */
.btnFont {
  font-size: 14px;
}
.training-course-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 1rem;
}

.training-course-content-header {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.training-course-content-header-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.training-course-content-header-title h2 {
  font-size: 1.8rem;
  font-weight: 600;
  color: #24b3b9;
}

.training-course-actions {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.training-course-content-header-info {
  display: flex;
  align-items: center;
  gap: 8rem;
}

.training-course-content-info-one {
  display: flex;
  align-items: center;
  gap: 1rem;
  min-width: fit-content;
}

.training-course-content-info-two {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.training-course-content-progress-note {
  padding: 0.5rem 1rem;
  border-radius: 8px;
  border: 1px solid #cfcfcf;
  width: fit-content;
  font-size: 1rem;
}

.training-course-content-date span {
  font-size: 0.8rem;
  font-weight: 500;
}

.training-course-content-rating {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.rate-now-btn {
  padding: 0.2rem 0.6rem;
  font-size: 0.8rem;
}

.stars-font-size::before {
  font-size: 1rem;
}

.training-course-content-trainers {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.trainer_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.4rem;
  width: fit-content;
}

.trainer_name {
  cursor: pointer;
}

.trainer_rating {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.trainer_image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid #c7c7c7;
  cursor: pointer;
}

.trainer_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.training-course-content-body {
  margin-top: 2rem;
}

.training-course-content-syllabus {
  font-size: 1.5rem;
  font-weight: 500;
  color: #777;
}

.chapter-row-opened {
  border-radius: 8px;
  border: 1px solid #cfcfcf;
  min-height: 150px;
}

.chapter-row-content {
  display: none;
}

.chapter-row-content-opened {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}

.chapter-row-content-item {
  font-size: 1.2rem;
  font-weight: 600;
  color: #555;
  cursor: pointer;
}

.main-box__head__actions {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  overflow-x: auto;
  white-space: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.main-box__head__actions::-webkit-scrollbar {
  display: none;
}

.main-box__head__action__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
}

.training-rating__list {
  direction: ltr;
}
body[dir="rtl"] .training-rating__list {
  direction: rtl;
}

.training-course-content-closed {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  font-weight: 600;
  color: #555;
}

@media (max-width: 991px) {
  .course-slider-model-container {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .main-box__head__actions {
    width: 100%;
    gap: 0.5rem;
  }

  .training-course-content-header-title {
    flex-direction: column;
    gap: 1rem;
    align-items: normal;
  }

  .training-course-actions {
    flex-direction: column;
    align-items: stretch;
  }

  .main-box__head__actions button {
    font-size: 0.8rem;
  }
}

.disabledButton {
  background-color: #24b4b985;
}

/* .syllabus {
  min-width: 25%;
  width: 25%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.syllabus-header {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 1rem;
}

.syllabus-header-image {
  width: 100%;
  height: 300px;
}

.syllabus-header-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.syllabus-header-details {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}

.syllabus-header-details h2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #555;
}

.syllabus-header-details-desc {
  font-size: 1.2rem;
  font-weight: 400;
  color: #555;
}

.syllabus-body {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.syllabus-body-courses {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.syllabus-body-courses-item {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  cursor: pointer;
  padding-bottom: 0.5rem;
}

.syllabus-body-courses-item-selected {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  cursor: pointer;
  padding-bottom: 0.4rem;
  border-bottom: 2px solid #21a6ac;
}

.syllabus-body-courses-item:hover {
  border-bottom: 2px solid #555;
  padding-bottom: 0.4rem;
}

.syllabus-body-courses-item-number {
  font-size: 1.2rem;
  font-weight: 600;
  color: #555;
  min-width: 2rem;
}

.syllabus-body-courses-item-name {
  font-size: 1rem;
  font-weight: 500;
  color: #555;
  padding-top: 0.2rem;
} */
