.breadcrumbs {
  padding-top: 30px;
  padding-bottom: 30px;
}

.m-20 {
  margin: 20px 0;
}

.m-50 {
  margin-bottom: 50px;
}

.d-flex-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 12px 0;
}

.input-label {
  font-size: 17px;
  margin-bottom: 4px;
  color: #5c5c5c;
}

.modal-button {
  display: flex !important;
  justify-content: end !important;
}

.loading__container {
  padding: 20px 0;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}
