.card-counter {
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    color: #fff;
    background-color: #14949A;
  }
  
  .card-image {
    margin-bottom: 10px;
    width: 80px;
  }
  
  .card-label-survey {
    font-size: 16px;
    font-weight: 600;
    color: white;
  }
  
  .card-number-survey {
    font-size: 28px;
    font-weight: bold;
    color: white;
  }

  .container-inputs{
    padding:20px;
    border-radius:20px;
    margin-top:5%;
    box-shadow:2px 2px 2px 2px #006d7760;
  }

  .button-add{
    margin-top:3%;
    padding:10px;
    background-color:white;
    border-radius:5;
    border:1px solid #006d7760
  }

  .container-quations{
    display:flex;
    flex-direction:column
  }

  .container-quations-inputs{
    display:flex;
    flex-direction:column
  }

  .order-input{
    margin-top: 2%;
  }

  .container-chooses{
    display:flex;
    flex-direction:row;
    margin-top: 3%;
  }

  .action-add-quation{
    margin-top:2%;
    padding:10px;
    border:1px solid #006d7760;
    background-color:white;
    border-radius:5px;
    width:100%
  }


  .disabled{
    pointer-events: none;
    cursor: default;
    color: rgba(255, 0, 0, 0.6);
  }

  .enabled{
    color: red;
    cursor: pointer;
  }
  
  @media (max-width: 768px) {
    .card-counter {
      align-items: center;
    }
  }


