.create_coupon {
  padding: 2rem 0;
}

.create_coupon__container,
.create_coupon__form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.create_coupon__form__fields {
  margin: 0 -15px;
}

.create_coupon__form__field {
  margin-bottom: 2rem;
}

.create_coupon__form__field label {
  color: #777777;
}


.create_coupon__form__field-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
}

.create_coupon__form__field-actions button {
  background-color: #046c77;
  border-radius: 6px;
  border: none;
  padding: 0 1rem;
  height: 50px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.create_coupon__form__field-actions-submit {
  width: 250px;
}
.create_coupon__form__field-actions-submit[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}
.create_coupon__form__field-actions-view {
  width: 50px;
}

.create_coupon__form-error {
  margin: 0 !important;
  padding: 0 !important;
}

.create_coupon__form__field-mode {
  color: #777777;
}

@media only screen and (max-width: 480px) {
  .create_coupon__form__field-actions-submit {
    width: 100%;
  }
}
