.search__container {
  display: flex;
  align-items: center;
}

.search__container-field {
  flex: 1;
  position: relative;
}

.search__container-field input {
  border: 1px solid #eee;
  height: 100%;
  padding: 0 1rem;
  flex: 1 1;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  color: #777777;
  display: flex;
  align-items: center;
  font-size: 15px;
  height: 50px;
  width: 100%;
}

.search__container-field input::placeholder {
  color: #b1b1b1;
}

.search__container-field-icon {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
body[dir="rtl"] .search__container-field-icon {
  right: auto;
  left: 1rem;
}
.search__container-button {
  height: 100%;
  border: none;
  background-color: #046c77 !important;
  border-radius: 6px;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  color: #ffffff;
  height: 50px;
  min-width: 120px;
  justify-content: center;
}
