.validator_input_component_container {
  position: relative;
}

.validator_input_component_border {
  border-bottom: 2px solid rgba(184, 251, 255, 0.2);
  margin-bottom: 30px;
}

.validator_input_icon_container {
  position: absolute;
  width: 1.8rem;
  height: 1.8rem;
}

.validator_input_icon_container img {
  width: 100%;
  height: 100%;
}

body[dir="rtl"] .validator_input_icon_container {
  left: 14px;
  right: initial;
  top: 48%;
}

body[dir="ltr"] .validator_input_icon_container {
  right: 14px;
  left: initial;
  top: 48%;
}

.component_input {
  width: 92%;
  background-color: transparent;
  border: none;
  outline: none;
  color: #fff;
  height: 50px;
  font-size: 18px;
}

.input_container {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
}

.input_container_without_space_around {
  display: flex;
  flex-direction: row;
}

.input_container svg {
  color: #ccc;
}

@media (max-width: 991px) {
  .component_input {
    width: 91%;
  }
}

@media (max-width: 767px) {
  .component_input {
    height: unset;
    margin-bottom: 5px;
    width: 95%;
  }

  .validator_input_component_border {
    margin-bottom: 0;
  }

  .validator_input_icon_container {
    position: absolute;
    right: 0;
    top: 0px;
  }
}
.PhoneInputInput {
  border: none;
  background: transparent;
  direction: ltr !important;
  margin-left: 30px !important;
}
.input_component_container {
  position: relative;
}
.input_component_container .input_icon_container {
  position: absolute;
  top: 0;
  left: unset;
  right: 0;
}
body[dir="rtl"] .input_component_container .input_icon_container {
  left: 0;
  right: unset;
}

/****/
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #ddd;
  border-radius: 50%;
}

body[dir="rtl"] .checkmark {
  left: unset;
  right: 0;
}
#inlineRadio1.form-check-input,
#inlineRadio2.form-check-input {
  /* z-index: 2; */
  /* width: 25px; */
  /* height: 20px; */
  /* opacity: 0; */
  /* cursor: pointer; */
}
body[dir="rtl"] #inlineRadio1.form-check-input,
body[dir="rtl"] #inlineRadio2.form-check-input {
  /* position: absolute; */
  /* right: 0; */
}
.checkmark.checked::after {
  content: "";
  right: 3px;
  top: 3px;
  position: absolute;
  border: solid #006d77;
  border-width: 6px;
  transform: rotate(45deg);
  border-radius: 50%;
  background: #006d77;
}
/****/

.react-datepicker-wrapper {
  width: 100%;
}
