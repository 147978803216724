.BannerCont {
  width: 100%;
  height: 500px;
  display: flex;
  margin-top: 50px;
}
.first {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 60px;
  gap: 20px;
}
.second {
  width: 50%;
}

.maintext {
  font-size: 36px;
  font-weight: 600;
  max-width: 350px;
  text-align: center;
  color: #fff;
}
.btnRegister {
  padding: 10px 20px;
  border-radius: 15px;
  color: rgb(3, 162, 178) !important;
}
@media (max-width: 900px) {
  /* .second {
    display: none;
  } */
  .BannerCont {
    justify-content: center;
  }
  .first {
    width: 100%;
  }
  .maintext {
    font-size: 26px;

    max-width: unset;
  }
}
@media (max-width: 960px) {
  .second {
    display: none;
  }
}
