.title-statistics{
    padding-bottom:2%;
    padding-top:2%
}

.content-statistics{
    background-color:white;
    padding:20px
}

.card-statistics{
    margin-top:2%
}

