.program_main_card {
  width: 100%;
  height: 520px;
  margin: 10px auto;
  font-size: 18px;
  box-shadow: 0px 0px 20px #ccc;
  background: #fff;
}

.card_img {
  background-color: #ccc;
  object-fit: cover;
  background-position: top;
  background-size: cover;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: #fff;
}

.courses_name {
  width: 100%;
  background-color: rgba(3, 41, 43, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100px;
  transition: all 0.5s;
  padding-bottom: 20px;
  padding: 20px 10px;
  text-align: initial;
}

.logo_hover {
  display: none;
}

.courses_name:hover > .logo_hover {
  display: flex;
  width: 100%;
  margin-top: -70px;
  margin-bottom: 70px;
  justify-content: flex-start;
}

.logo_hover_width {
  width: 100px;
}

.card_price {
  display: flex;
  justify-content: space-between;
  padding: 0 25px;
  align-items: center;
  height: 70px;
  background-color: #f6f6f6;
  border-bottom: 1px solid #ccc;
  font-size: 16px;
}

.card_hour {
  display: flex;
  justify-content: space-between;
  padding: 0 25px;
  align-items: center;
  height: 60px;
  background-color: #fff;
  font-size: 16px;
}

.card_center {
  display: flex;
  align-items: center;
}

.img_hour {
  width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -3px 5px 0;
}

.courses_card_button {
  margin: 0 15px;
  background-color: #fff;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
}
.courses_card_button_in_link {
    outline: none;
    border: 2px solid #ccc;
    background-color: #fff;
    border-radius: 10px;
    width: 100%;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
.courses_card_button_in {
  outline: none;
  border: 2px solid #ccc;
  background-color: #fff;
  border-radius: 10px;
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.courses_card_button_in_link:hover{
  background-color: #006d77 !important;
  color: #fff;
  border: 2px solid #006d77;
}

.courses_card_button a.courses_card_button_in, .courses_card_button .courses_card_button_in {
  border: none !important;
}
