.tajah-restriction-modal-data-section {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  border-bottom: #dadada solid 1px;
}

.tajah-restriction-modal-data-section-user-partition {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.tajah-restriction-modal-data-section-prerequisties-partition {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  border-right: #dadada solid 1px;
  padding-right: 10px;
}

.tajah-basic-modal-body-restriction {
  justify-content: center;
  margin-top: 10px;
}

.tajah-basic-modal-close {
  cursor: pointer;
}

.restrictions-modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.restrictions-modal-footer-btn {
  padding: 1rem 4rem;
}

.restrictions-modal {
width: 60%;
background-color: #ffffff;
padding: 30px;
border-radius: 15px;
max-height: 500px;
overflow-y: auto;
}