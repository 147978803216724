.tajah_system_preference_form_row {
    display: flex;
    flex-direction: row;
}

.tajah_system_preference_form_row:not(:first-child) {
    margin-top: 20px;
}

.tajah_system_preference_form_entity {
    flex: 1;
    padding-right: 15px;
    padding-left: 15px;
}

.tajah_system_preference_form_submission_button {
    margin-top: 20px;
}
