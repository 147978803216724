.main_section {
  background: url("../../../../assets/image/main-back.png") center no-repeat;
  background-size: cover;
  background-position: top;
  height: 70%;
  padding: 50px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}

.section_wrapper {
  display: flex;
  background-color: rgba(14, 35, 35, 0.5) !important;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  width: 100%;
  height: auto;
  border-radius: 15px;
  position: relative;
  justify-content: space-between;
  flex-direction: column;
}

.section_inner {
  display: flex;
  width: 100%;
  height: 220px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 10px;
  height: 100%;
}

.section_header {
  margin: 10px 0;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
}

.section_desc {
  margin: 5px 0;
  font-size: 18px;
  text-align: center;
  width: 40%;
}
@media (max-width: 1199px) {
  .section_inner {
    height: 200px;
  }
  .section_header {
    font-size: 25px;
  }

  .section_desc {
    font-size: 16px;
  }
}

@media (max-width: 991px) {
  .section_inner {
    height: 150px;
  }
  .section_header {
    font-size: 20px;
  }

  .section_desc {
    font-size: 16px;
    width: 60%;
  }
}

@media (max-width: 767px) {
  .section_header {
    font-size: 18px;
  }
  .section_desc {
    font-size: 14px;
    display: none;
  }
}
