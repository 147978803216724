.lesson-content-browser__tab {
  margin: 1rem 0;
}

.lesson-content-browser__tab-opened {
  border: 1px solid #cfcfcf;
  border-radius: 6px;
  margin: 1rem 0;
  margin-bottom: 2rem;
}

.lesson-content-browser__tab__add-new-item {
  margin: 2rem;
}

.lesson-content-browser__actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 1rem 0;
  gap: 1rem;
}
