.question-canvas {
  padding-right: 15px;
  padding-left: 15px;
  margin-top: 62px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.tajah-question-progress {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.tajah-pre-table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding-top: 20px;
  padding-bottom: 20px;
}

.exam-table-action-item {
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.tajah-pre-table-header-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .course-exams {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
  .course-exams__actions {
    flex-wrap: wrap;
    gap: 10px;
    align-content: center;
  }
}
