.header_profile {
    background: #011e20;
    padding: 15px 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #ffffff;
  }
  .profile_dhead{
      padding-top: 45px;
      padding-bottom: 30px;
  }
  .profile_wrapper {
    display: flex;
    width: 100%;
    height: auto;
    border-radius: 15px;
    margin: 0 auto;
    position: relative;
    justify-content: center;
    flex-direction: column;
    padding: 0 15px;
  }
  
  .profile_grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 50px;
    margin: 20px 0;
  }
  
  .profile_dhead {
    display: flex;
    align-items: center;
  }
  .profile_dhead.btn_end{
    justify-content: flex-end;
}   
  
  .table_header{
    padding: 0 0 55px 0;
    margin: 0;
    width: 90%;
    position: relative;

}
.table_bank_tran{
    margin-top: 0px;
    padding: 25px 0;
}
  
  @media (max-width: 1199px) {
    .header_profile {
      padding: 10px 0;
    }
  
  }
  
  @media (max-width: 991px) {
   
    .profile_dhead {
      justify-content: center;
      align-items: center;
    }
  }
  
  @media (max-width: 767px) {
    .profile_dhead {
      margin: 10px 0;
    }   
  }
  
/** Add Sponsor **/