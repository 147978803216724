.homeActions {
  margin: 20px;
  padding-top: 50px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.homeAction {
  flex: 1;
  white-space: nowrap;
  cursor: pointer;
  padding: 40px;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.homeAction img {
  width: 50px;
  height: 50px;
}

.homeAction span {
  font-size: 1.2rem;
  font-weight: 600;
}

@media (max-width: 992px) {
  .homeActions {
    flex-direction: column;
    gap: 20px;
  }

  .homeAction {
    width: 100%;
  }
}
