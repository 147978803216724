.backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.5);
}

.modal_flex_card {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal_card_pay {
  z-index: 1001 !important;
  position: fixed;
  /* top: 5vh; */
  max-width: 800px;
  min-width: 700px;
  z-index: 100;
  overflow: auto;
  max-height: 90%;
  animation: mymove 0.5s normal;
  -webkit-animation: mymove 0.5s normal;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 10px;
}

.modal_head {
  width: 100%;
  padding: 50px 20px 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.modal_head_label {
  font-size: 30px;
  margin: 0 0 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal_head_label_bottom {
  font-size: 18px;
  margin: 0 0 20px 0;
  display: flex;
  align-items: center;
}

.modal_pay_head_card {
  display: flex;
}

.modal_course_img {
  display: flex;
  width: 280px;
  align-items: center;
  justify-content: center;
}

.modal_course_des {
  display: flex;
  flex-direction: column;
  margin: 0 20px;
}

.modal_course_name {
  font-size: 16px;
  width: 100%;
  margin: 15px 0;
}

.modal_coupon {
  font-size: 14px;
  color: #006d77;
  margin: 15px 0;
}

.modal_coupon_form {
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
}

.modal_form {
  display: flex;
  align-items: center;
  gap: 10px;
}

.modal_coupon_input {
  border: none;
  border-bottom: 1px solid #999;
  height: 30px;
  width: 80%;
}

.modal_coupon_button {
  padding: 5px 30px;
  background-color: #006d77;
  color: #fff;
  font-size: 18px;
  border: none;
  border-radius: 15px;
  margin: 0 30px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}

.modal_coupon_price {
  font-size: 14px;
  margin: 10px 0;
}

.modal-body {
  display: flex;
  flex-direction: column;
  padding: 35px;
}

.modal_body_label {
  font-size: 30px;
  margin-bottom: 20px;
}

.modal_pay_card {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
}

.modal_card {
  width: 100%;
  height: 220px;
  border-radius: 20px;
  background-color: #e9e9e9;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.img_card_pay {
  margin-bottom: 20px;
  height: 60px;
}

@media (max-width: 991px) {
  .modal_card_pay {
    min-width: 70%;
  }

  .modal_head_label,
  .modal_body_label {
    font-size: 22px;
  }

  .modal_head_label_bottom {
    font-size: 15px;
  }

  .modal_course_img {
    width: 230px;
  }

  .modal_course_name,
  .modal_coupon {
    font-size: 14px;
    margin: 10px 0;
  }

  .modal_coupon_input {
    font-size: 14px;
    width: 100%;
  }

  .modal_coupon_button {
    font-size: 16px;
    padding: 3px 30px;
  }

  .modal_coupon_price {
    font-size: 12px;
  }

  .img_card_pay {
    height: 45px;
  }
}

@media (max-width: 767px) {
  .modal_pay_head_card {
    flex-direction: column;
  }

  .modal_coupon_button {
    margin: 0;
  }

  .modal-body {
    padding: 20px;
  }

  .modal_pay_card {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 30px;
    gap: 30px;
  }

  .modal_form {
    flex-direction: column;
    align-items: flex-end;
    gap: 20px;
  }
}
