

.container-inputs{
    padding:15px;
    border-radius:15px;
    margin-top:5%;
    box-shadow:2px 2px 2px 2px #006d7760;
    border: 0px;
    width: 100%;
    background-color: white;
  }

  .survey-cancel-button{
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 130px;
    padding: 10px;
    background-color: white;
    border-radius: 15px;
    color: #006d77;
    font-size: 16px;
    border: 1px solid #006d77;
    outline: none;
    margin-top: 10px;
    -webkit-border-radius: 15px;
  }


  .disabled{
    pointer-events: none;
    cursor: default;
    color: rgba(255, 0, 0, 0.6);
  }

  .enable{
    color: red;
    cursor: pointer;
  }