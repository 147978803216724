.payment-box-option {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 250px;
  height: 250px;
  border: 1px solid #ebebeb;
  border-radius: 6px;
  cursor: pointer;
}

.payment-way-txt {
  position: absolute;
  top: 180px;
  color: #b1b1b1;
  font-size: 18px;
  font-weight: 400;
}

@media (max-width: 767px) {
  .payment-way-img {
    display: none;
  }
  .payment-way-txt {
    font-size: 14px;
    position: relative;
    top: 0px;
    padding: 5px 10px;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .payment-box-option {
    height: auto;
  }
}
