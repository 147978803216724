.css-1dn350p-MuiInputBase-root-MuiInput-root:before {
  border-bottom: 1px solid #fff !important;
}
.css-18uuv8m-MuiFormLabel-root-MuiInputLabel-root {
  color: #fff !important;
  font-family: "dinNextRegular" !important;
  text-align: right !important;
}
.css-1s5gbjz-MuiFormHelperText-root {
  color: #f80a0a9f !important;
  font-family: "dinNextRegular" !important;
}
.css-1x51dt5-MuiInputBase-input-MuiInput-input {
  color: #fff !important;
  padding: 10px !important;
  font-family: "dinNextRegular" !important;
}
.css-ewm26-MuiFormLabel-root-MuiInputLabel-root {
  color: #fff !important;
  font-family: "dinNextRegular" !important;
}
.css-134xipd-MuiInputBase-root-MuiInput-root:before {
  border-bottom: 1px solid #fff !important;
  font-family: "dinNextRegular" !important;
}
