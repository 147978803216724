.main_contact {
  display: flex;
  /* padding-top: 50px; */
  gap: 30px;
}
.contentDiv {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.imgDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}
.imgDiv img {
  max-width: 500px;
}
@media (max-width: 768px) {
  .imgDiv {
    display: none;
  }
}
.title {
  font-size: 30px;
  font-weight: 800;
  color: #000000;
  border-bottom: 3px solid #03a2b2;
  width: fit-content;
}
.desc {
  color: #b7b7b7;
  font-size: 14px;
  width: 70%;
}
.contactInfo {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.contactInfo p {
  color: #000000a2;
}
.icon_text {
  display: flex;
  gap: 10px;
}
