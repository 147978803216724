.admin_label {
    display: flex;
    margin: 30px 0;
}
.delete-button {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
}
.delete-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 100000000;
    background: rgba(0, 0, 0, 0.3);
}
.delete-modal-fix-top {
    top: calc(50% - 100px);
}
.table_bank_tran {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    margin-top: 40px;
    padding: 50px 0;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 2px 2px 10px #ddd;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }

  .table_bank_tran .table {
    width: 100%;
  }


.table_bank_tran td,
.table_bank_tran th {
  text-align: center;
}

.table_bank_tran i {
  color: #006d77;
  font-size: 13px;
  cursor: pointer;
}

.backdrop_table_bank_tran {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1000000;
  background: rgba(0, 0, 0, 0.8);
}

.table_bank_tran button i {
  font-size: 17px;
  color: #fff;
  padding: 0px 5px;
}

.table_bank_tran button {
  display: flex;
  /*width: 100%;*/
  /*text-align: center;*/
  /*margin: 0 auto;*/
  justify-content: center;
  align-items: center;
}
.admin_label_card {
    display: flex;
    width: 90%;
    margin-bottom: 30px;
    justify-content: space-between;
    align-items: center;
  }
  .release_icon {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-evenly;
  }

  .release_icon .fa-trash-alt {
    color: #d20101;
  }






  .add_admin {
    /* margin-top: 100px; */
    padding: 30px;
    border-radius: 20px;
    display: flex;
    width: 100%;
    background-color: #fff;
    box-shadow: 2px 2px 10px #ddd;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    margin-bottom: 50px;
  }

  .admin_add_form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .admin_add_label {
    font-size: 14px;
  }

  .admin_add_input {
    display: flex;
    background-color: transparent;
    color: #000;
    backdrop-filter: blur(100px);
    position: relative;
    /* padding: 0 15px; */
    border: none;
    border-bottom: 1px solid #ccc;
    width: 100%;
    height: 50px;
  }

  .admin_edit_button {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 120px;
    padding: 10px;
    background-color: #fff;
    border-radius: 15px;
    color: #000;
    font-size: 16px;
    border: none;
    outline: none;
    margin-top: 10px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    cursor: pointer;
  }







@media (max-width: 991px) {
    .admin_label,
    .important_actions,
    .text_num_b {
      font-size: 16px !important;
    }

    /* .admin_label_card {
        flex-direction: column;
    } */
}
@media (max-width: 768px) {
    .admin_label,
    .important_actions,
    .text_num_b {
      font-size: 14px !important;
    }
}
