.training_first_label {
  display: flex;
  font-size: 16px;
  padding: 30px 0 15px 0;
}

.training-course-tab-layout-border-bottom {
  display: flex;
  flex-direction: row;
}

.training-course-tab-layout-tab {
  height: 60px;
  padding: 15px;
  color: #191919;
  cursor: pointer;
}

.training-course-tab-layout-tab-active {
  border-bottom-style: solid;
  border-bottom-color: #10b0b9;
  border-bottom-width: 2px;
}
.hide-tap {
  display: none !important;
}
