.admin_label {
    font: 18px;
    display: flex;
    margin: 30px 0;
  }
  .delete-modal {
    display: flex;
    width: 100%;
    height: 200px;
    position: fixed;
    z-index: 1000000000;
    justify-content: center;
    align-items: center;
    top: 50%;
  }

  .delete-modal-fix-top {
    top: calc(50% - 100px);
  }
  .delete-modal-button {
    width: 70px;
    padding: 5px 25px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }
  .delete-box {
    display: flex;
    flex-direction: column;
    padding: 35px 50px;
    justify-content: space-between;
    width: 350px;
    height: 200px;
    border-radius: 10px;
    background-color: #fff;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }
  .delete-button {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
  }
  .delete-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 100000000;
    background: rgba(0, 0, 0, 0.3);
  }
  .fix_delete_style {
    top: 40%;
    width: 90%;
  }
  .admin_card {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    /* margin-top: 40px; */
    padding: 50px 0;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 2px 2px 10px #ddd;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }

  .admin_flex {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }

  .font_awesome_edit {
    font-size: 20px !important;
    color: #000;
  }

  .admin_button_green {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 130px !important;
    height: 40px;
    background-color: #006d77;
    color: #fff;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }

  .admin_button_flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .admin_button_flex span {
    padding: 0 5px;
  }

  .admin_card {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    /* margin-top: 40px; */
    padding: 50px 0;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 2px 2px 10px #ddd;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }

  .admin_box_bg {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding: 50px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 2px 2px 10px #ddd;
  }

  .admin_input_sponsor {
    display: flex;
    width: 90%;
    padding: 30px 0;
    align-items: flex-end !important;
  }

  .delete_coupon {
    width: 65%;
  }
  .admin_card {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    /* margin-top: 40px; */
    padding: 50px 0;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 2px 2px 10px #ddd;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }
  .admin_card_add {
    min-height: 200px;
    border: 1px solid #ccc;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }
  .admin_card .table {
    width: 90%;
    margin: auto;
  }
  .admin_label_card {
    display: flex;
    width: 90%;
    margin-bottom: 30px;
    justify-content: space-between;
    align-items: center;
  }
  .release_icon {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-evenly;
  }

  .release_icon .fa-trash-alt {
    color: #d20101;
  }
  .admin_add_form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .admin_add_label {
    font-size: 14px;
  }

  .admin_add_input {
    display: flex;
    background-color: transparent;
    color: #000;
    backdrop-filter: blur(100px);
    position: relative;
    /* padding: 0 15px; */
    border: none;
    border-bottom: 1px solid #ccc;
    width: 100%;
    height: 50px;
  }

  .admin_edit_button {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 120px;
    padding: 10px;
    background-color: #fff;
    border-radius: 15px;
    color: #000;
    font-size: 16px;
    border: none;
    outline: none;
    margin-top: 10px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    cursor: pointer;
  }

  .admin_box_text {
    font-size: 30px;
    margin-bottom: 30px;
    color: rgb(0, 0, 0, 47%);
  }
  /* time */
  .time_use .react-datepicker__month-container,
  .time_use
    .react-datepicker__navigation
    .react-datepicker__navigation--next
    .react-datepicker__navigation--next--with-time {
    display: none !important;
  }

  .time_use
    .react-datepicker__navigation.react-datepicker__navigation--next.react-datepicker__navigation--next--with-time {
    display: none !important;
  }

  .time_use .react-datepicker__navigation.react-datepicker__navigation--previous {
    display: none !important;
  }

  .react-datepicker-popper[data-placement^="bottom"] {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  /* time */
  .admin_coupon_in_card {
    display: flex;
    background-color: #ebebeb;
    padding: 10px;
    text-align: center;
    justify-content: space-between;
    margin: 10px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }

  .coupon_card_width {
    min-width: 20%;
  }

  .admin_coupon_radio {
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    /* width: 40%; */
  }
  .delete_icon {
    cursor: pointer;
    margin: 0 9px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .release_icon {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-evenly;
  }

  .release_icon .fa-trash-alt {
    color: #d20101;
  }

  @media (max-width: 1500px) {
    .delete_coupon {
      width: 90%;
    }
  }
  @media (max-width: 991px) {
    .admin_label,
    .important_actions,
    .text_num_b {
      font-size: 16px !important;
    }
    .admin_label_card {
      flex-direction: column;
      /* height: 150px; */
    }
  }
  @media (max-width: 768px) {
    .admin_label,
    .important_actions,
    .text_num_b {
      font-size: 14px !important;
    }
    .overview_button {
      width: 100%;
    }
  }
  .admin_label {
    display: flex;
    margin: 30px 0;
}

.table_bank_tran {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    margin-top: 40px;
    padding: 50px 0;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 2px 2px 10px #ddd;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }

  .table_bank_tran .table {
    width: 100%;
  }

  /* .admin_card .table {
    width: 90%;
  } */

  .label_bank_tran {
    display: flex;
    width: 90%;
    margin-bottom: 30px;
    justify-content: space-between;
    align-items: center;
  }

  .admin_label_card {
    display: flex;
    width: 90%;
    margin-bottom: 30px;
    justify-content: space-between;
    align-items: center;
  }

  .select_admin {
    width: 150px;
    height: 40px;
    border: none;
    padding: 0 10px;
    background-color: #eee;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
  }

  .table_bank_tran td,
  .table_bank_tran th {
    text-align: center;
  }

  .table_bank_tran i {
    color: #006d77;
    font-size: 13px;
    cursor: pointer;
  }

  .backdrop_table_bank_tran {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 1000000;
    background: rgba(0, 0, 0, 0.8);
  }

  .table_bank_tran button i {
    font-size: 17px;
    color: #fff;
    padding: 0px 5px;
  }

  .table_bank_tran button {
    display: flex;
    /*width: 100%;*/
    /*text-align: center;*/
    /*margin: 0 auto;*/
    justify-content: center;
    align-items: center;
  }
/* Admin Role */
.admin-role {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    position: relative;
  }

  .admin-role-input {
    width: 30%;
    /* height: 100%; */
    /* position: absolute; */
    /* left: 145px; */
    border-radius: 10px;
    border: 1px solid #dcdcdc;
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    margin: 0 10px;
  }

  .admin-role-search {
    border: 0px;
    outline: none;
    width: 90%;
    height: 30px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }
  /* Admin Role */
  .modal_flex_card {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }


  .modal_card_admin {
    z-index: 1001 !important;
    position: fixed;
    top: calc(35% - 100px);
    width: 500px;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    z-index: 100;
    overflow: auto;
    animation: dropmove 0.5s normal;
    background: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    border-radius: 10px;
    -webkit-animation: dropmove 0.5s normal;
  }
  .admin_edit_button {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 120px;
    padding: 10px;
    background-color: #fff;
    border-radius: 15px;
    color: #000;
    font-size: 16px;
    border: none;
    outline: none;
    margin-top: 10px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    cursor: pointer;
  }

  .custom_select {
    position: relative;
    display: inline-block;
    width: 130px;
}



@media (max-width: 991px) {
    .admin_label,
    .important_actions,
    .text_num_b {
      font-size: 16px !important;
    }
}
@media (max-width: 768px) {
    .admin_label,
    .important_actions,
    .text_num_b {
      font-size: 14px !important;
    }
    .admin-header-table {
        flex-direction: column !important;
      }
      .admin-role-input,
      .custom_select {
        width: 50% !important;
      }
      .modal_card_admin {
        width: unset !important;
      }
}
.list_of_trainer{
    padding: 25px 0;
}
.list_of_trainer .btn_flex{
    display: flex;
    justify-content: flex-end;

}
.sponsor_model{
    align-items: baseline;
    padding: 0px 30px;
    height: 315px;
    padding-bottom: 40px;
}
.sponsor_model p{
    width: 50%;
    display: block;
    margin: 0 auto;
}
.sponsor_model .admin_add_button{
    margin: 0 8px;
}
.remove_doc{
    color: #D20101;
    position: absolute;
    right: 0;
    top: 6px;
    padding: 0;
    margin: 0;
    background: transparent;
    font-size: 15px;
    font-weight: 400;
    border: none;
}

body[dir='rtl'] .remove_doc{
    right: unset;
    left: 0;
}
.remove_doc i{
    color: #D20101 !important;
    font-size: 15px;
}
@media (max-width: 768px) {
    .sponsor_model p{
        width: 100%;
    }
}
/** End Zoom**/


.checkbox_zoom{
    height: 17px;
    width: 17px;
    transform: translate(0px, 5px);
}
.parg_zoom{
    display: inline-block;
    padding: 0 10px;
    margin: 0;
}
.admin_add_button.btn_zoom{
    margin: 40px 0 0 0 ;
}

.span-input{
    position: absolute;
    bottom: 47px;
    right: 20px;
    color: #918f8f;
}
body[dir='rtl'] .span-input{
    left: 20px;
    right: unset
}
.admin_input_sponsor input::-webkit-outer-spin-button,
.admin_input_sponsor input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.admin_input_sponsor input[type=number] {
    -moz-appearance:textfield;
}
