.course-detailes {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 40%;
    padding: 0 40px;
}

.select-label {
    font-size: 30px;
}

.hint-label {
    font-size: 14px;
}

.course-detailes .course {
    margin-top: 30px;
    display: flex;
    align-items: center;
}

.course-detailes .course .course-name {
    margin: 0 20px;
    font-weight: bold;
}

.course-detailes .course img {
    width: 100px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.course-price {
    margin-top: 30px;
}

.course-price span {
    font-weight: bold;
}

.price-hint {
    font-size: 12px;
}

@media (max-width:768px) {
    .course-detailes {
        width: 100%;
        margin-bottom: 40px;
        padding: 0;
    }
}