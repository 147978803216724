.create_event {
  padding: 2rem 0;
}

.create_event__container,
.create_event__form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.create_event__form__fields {
  margin: 0 -15px;
}
.create_event__form__field {
  margin-bottom: 2rem;
}

.create_event__form__field label {
  color: #777777;
}
.event__form__submit__container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1rem;
  gap: 10px;
}

.event__form__submit__container button {
  padding: 0.7rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  width: 25%;
}
@media (max-width: 1024px) {
  .event__form__submit__button {
    width: 50% !important;
  }
}
@media (max-width: 767px) {
  .event__form__submit__container {
    width: 100% !important;
  }
  .event__form__submit__button {
    width: 100% !important;
  }
}
