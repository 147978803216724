.matrix-cell {
  flex-direction: row;
  flex-grow: 1;
  flex-basis: 200px;
  background-color: bisque;
  margin: 10px;
  display: inline-block;
  align-items: flex-start;
  text-align: center;
}
.matrix-row {
  flex-direction: column;
  background-color: bisque;
  align-items: center;
  text-align: center;
}

.hidden {
  display: none;
}

input {
  /* width: 80px; */
}

.bottom-new-item-option {
  width: 100%;
}

.selection-option {
  position: relative;
}

.option-delete-button {
  color: rgba(255, 0, 0, 0.75);
  cursor: pointer;
}

.survey-section {
  border: solid 1px #ccc;
  margin: 5px;
  padding: 5px;
}

.option-remove-area {
  border: solid 1px rgba(255, 0, 0, 0.75);
}

/* Responsive layout - makes a one column layout instead of a two-column layout */
@media (min-width: 801px) {
  .lg-view {
    display: inline-block;
  }
  .md-view {
    display: none;
  }
}

.survey-header {
  height: 180px;
  background: #011e20;
  width: 100%;
  padding: 50px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
}

.survey-content-header {
  display: flex;
  width: 100%;
  height: auto;
  border-radius: 15px;
  margin: 0 auto;
  position: relative;
  justify-content: center;
  flex-direction: column;
  padding: 0 15px;
}

.input_Check_container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 15%;
}

.survey-card-container-trainer {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  border: 1px solid;
  border-color: #e7e7e7;
  border-radius: 10px;
  background-color: white;
  margin-top: 20px;
}
.survey-title {
  font-size: 30px;
  display: flex;
}

.survey-card-container {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 5px 5px 5px #cfcfcf;
  margin-top: 20px;
  background-color: #f8f8f8;
}

.survey-card-title-trainer {
  font-size: 17;
  color: black;
  font-weight: bold;
}

.description {
  overflow: auto;
  resize: none;
  width: 60%;
  height: 100px;
  border: 1px solid #e7e7e7;
  background: transparent;
  padding: 10px;
  outline: none;
}

.date-container {
  width: 40%;
}

.email-survey {
  border-bottom: 1px solid #e7e7e7;
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
  background-color: transparent;
  width: 40%;
}

.dropdown-survey {
  border-bottom: 1px solid #e7e7e7;
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
  background-color: transparent;
  width: 40%;
}

.like-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.shape-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.text-like {
  color: #7e7e7e;
  font-size: 14px;
}

.text-shape {
  color: #7e7e7e;
  font-size: 14px;
}
.survey-card-title {
  font-size: 17;
  color: black;
  font-weight: bold;
}

.shape_radio {
  display: none;
}

.shape_radio + label {
  -webkit-appearance: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #7e7e7e;
  border-radius: 50px;
  width: 100%;
  width: 23px;
  height: 23px;
  cursor: pointer;
  background-color: transparent;
}

.shape_radio + label span {
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 50px;
  width: 100%;
  display: inline-block;
  position: relative;
  width: 17px;
  height: 17px;
  cursor: pointer;
  background-color: transparent;
}

.label-radio {
  margin-right: 10px;
  margin-left: 10px;
}

.shape_radio:checked + label span {
  background: #006d77;
  border: 1px solid #7e7e7e;
}

.input_radio_container {
  display: flex;
  flex-direction: row;
}

.shape_radio_2 {
  display: none;
}

.shape_radio_2 + label {
  -webkit-appearance: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #7e7e7e;
  border-radius: 50px;
  width: 100%;
  width: 23px;
  height: 23px;
  cursor: pointer;
  background-color: transparent;
}

.shape_radio_2 + label span {
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 50px;
  width: 100%;
  display: inline-block;
  position: relative;
  width: 17px;
  height: 17px;
  cursor: pointer;
  background-color: transparent;
}

.label-radio-2 {
  margin-right: 10px;
  margin-left: 10px;
}

.shape_radio_2:checked + label span {
  background: #006d77;
  border: 1px solid #7e7e7e;
}

.input_radio_container_2 {
  display: flex;
  flex-direction: row;
}

.container-label-checkbox {
  display: flex;
}

label .checkbox-input {
  display: none;
}

label .checkbox-input + span,
[type="checkbox"] + label span {
  height: 23px;
  width: 23px;
  display: inline-block;
  position: relative;
  cursor: pointer;
}

[type="checkbox"]:checked + span::before {
  content: "\2714";
  position: absolute;
  top: 0;
  left: 0;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.inputfile:focus + label {
  outline: none;
}

.inputfile + label * {
  pointer-events: none;
}

.labelFile {
  padding: 1% 3% 1% 3%;
  background-color: white;
  border: 1px dashed #e7e7e7;
  border-radius: 5px;
  color: #e7e7e7;
  font-weight: bold;
  font-size: 15px;
  cursor: pointer;
}

.sendBtn {
  background-color: #006d77;
  font-size: 16px;
  padding: 5px;
  border: 1px solid #006d77;
  border-radius: 10px;
  color: white;
  width: 20%;
  font-weight: bold;
  margin-left: 5%;
}

.sendBtnCancel {
  background-color: white;
  font-size: 16px;
  padding: 5px;
  border: 1px solid #006d77;
  border-radius: 10px;
  color: #006d77;
  width: 20%;
  font-weight: bold;
  margin-left: 5%;
}

.containerBtn {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
}
.side-by-side-matrix {
  width: 90%;
  margin: 0 auto;
}
.side-by-side-matrix .list {
  display: inline-block;
  padding: 0 10px;
  /* width: 55px; */
}
.side-by-side-matrix .list label {
  display: block;
  font-size: 11px;
}
.side-by-side-matrix input {
}

.rateStyle {
  transform: rotate(180deg) !important;
}

@media (max-width: 1199px) {
  .survey-title {
    font-size: 26px;
  }
}
@media (max-width: 991px) {
  .survey-header {
    height: unset;
  }

  .survey-title {
    font-size: 22px;
  }
}

@media (max-width: 767px) {
  .survey-title {
    font-size: 18px;
  }
}

@media (max-width: 800px) {
  .lg-view {
    display: none;
  }
  .md-view {
    display: inline-block;
  }
}

.smiley-input {
  font-size: large;
  margin: 5px;
  cursor: pointer;
}

.selected-smiley {
  border: solid 1px #000;
}

.selected-option {
  border: solid 1px #000;
  padding: 5px;
}

.required-question {
  color: rgba(255, 0, 0, 0.6);
}
