.modal_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.modal_header_row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.modal_close_icon {
    width: 45px;
    height: 45px;
    cursor: pointer;
}

.modal_header_divier {
    width: 100%;
    margin: 30px 0 !important;
}

.modal_container {
    width: 100%;
}

.modal_container div {
    width: 100%;
}

.button_width {
    width: 220px;
    height: 70px;
}

.button_width span {
    margin: auto;
    font-weight: bold;
    font-size: 18px;
}

.input_field_error {
    margin: 0 !important;
    padding: 0 !important;
}