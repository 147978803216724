.trainee-exam-answers-canvas {
    padding-right: 15px;
    padding-left: 15px;
    margin-top: 62px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.tajah-question-progress {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.tajah-question-info-section {
    flex: 5;
    align-items: flex-start;
}

.tajah-question-info-section-row {
    width: 100%;
    margin-bottom: 15px;
}

.tajah-question-answer-panel {
    margin-bottom: 10px;
    border: rgba(0,0,0,0.2) 1px dashed;
    padding-top: 35px;
    padding-bottom: 35px;
}