.head_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;
}

.head_lable {
  font-size: 22px;
}

.head_view {
  font-size: 16px;
  display: flex;
}

.arrow_seeall {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}

body[dir="rtl"] .arrow_seeall {
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  margin-top: -2px;
}

.arrow_icon {
  width: 8px;
  margin: 3px 10px 0;
}

.courses_card_point {
  display: flex;
  align-items: center;
  margin: 0 10px;
}

.progress {
  display: flex;
  width: 90%;
  margin: 10px auto;
  height: 4px;
}

.progress-bar {
  background-color: #18b1b9;
}
.courses_card {
  width: 100%;
  height: 520px;
  margin: 10px auto;
  font-size: 18px;
  box-shadow: 0px 0px 20px #ccc;
}

.courses_name {
  width: 100%;
  background-color: rgba(3, 41, 43, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100px;
  transition: all 0.5s;
  padding-bottom: 20px;
  padding: 20px 10px;
  text-align: initial;
}

.courses_name:hover {
  height: 100%;
  /* background-image: url("../../image/logo.png"); */
  background-size: 100px;
  background-position: 10px 10px;
  background-repeat: no-repeat;
  width: 100%;
  float: right !important;
}

.courses_name:hover > .logo_hover {
  display: flex;
  width: 100%;
  margin-top: -70px;
  margin-bottom: 70px;
  justify-content: flex-start;
}

.logo_hover {
  display: none;
}

.logo_hover_width {
  width: 100px;
}

.courses_card_second_std {
  display: flex;
  justify-content: center;
  padding: 0 25px;
  align-items: center;
  height: 60px;
  background-color: #fff;
  font-size: 16px;
}

.courses_card_grid {
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.courses_card_std {
  width: 100%;
  height: 480px;
  margin: 10px auto;
  font-size: 18px;
  box-shadow: 0px 0px 20px #ccc;
}

.courses_card_first_std {
  /* background-image: url("../../image/doctor.png"); */
  background-color: #ccc;
  object-fit: cover;
  background-position: top;
  background-size: cover;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: #fff;
}

.courses_card_category {
  font-size: 16px;
}

.courses_card_profile {
  display: flex;
  align-items: center;
}

.courses_card_icon {
  background-image: url("../../../../../assets//image/smallDoc.png");
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 10px;
  object-fit: cover;
  background-position: center;
  background-size: cover;
}

.courses_card_name {
  display: flex;
  align-items: center;
}

.courses_card_third {
  display: flex;
  justify-content: space-between;
  padding: 0 25px;
  align-items: center;
  height: 60px;
  background-color: #fff;
  font-size: 16px;
}

.img_hour {
  width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -3px 5px 0;
}

.courses_card_button {
  margin: 0 15px;
  background-color: #fff;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
}

.courses_card_button_in {
  outline: none;
  border: 2px solid #ccc;
  background-color: #fff;
  border-radius: 10px;
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.courses_card_button_in:hover {
  background-color: #006d77;
  color: #fff;
}

@media (max-width: 1199px) {
  .head_lable,
  .courses_card {
    font-size: 18px;
  }
  .head_view,
  .courses_card_category,
  .courses_card_third {
    font-size: 14px;
  }

  .courses_card_second {
    padding: 0 15px;
    font-size: 14px;
  }

  .courses_card_button {
    height: 50px;
  }
}

@media (max-width: 991px) {
  .head_lable {
    font-size: 16px;
  }

  .courses_card {
    height: 500px;
  }

  .courses_card {
    font-size: 14px;
  }

  .courses_card_grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    gap: 20px;
    /* margin: 40px 0; */
  }

  .courses_card_second {
    padding: 0 10px;
    font-size: 14px;
  }
  .courses_card_category,
  .courses_card_third {
    font-size: 12px;
  }

  .courses_card_button {
    height: 40px;
  }
}

@media (max-width: 767px) {
  .courses_card_grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
    gap: 20px;
    /* margin: 40px 0; */
  }

  .courses_card {
    font-size: 12px;
  }

  .courses_card {
    height: 500px;
  }

  .courses_card_second {
    padding: 0 10px;
    font-size: 12px;
  }
  .courses_card_button {
    height: 40px;
  }
}
