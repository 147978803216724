.progress-bar-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.progress-icon {
  width: 2.5rem;
  height: 2.5rem;
  min-width: 2.5rem;
  min-height: 2.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
}

.progress-icon img {
  width: 1.5rem;
  height: 1.5rem;
}

.progress-bar-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.progress-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}
.progress-text {
  font-size: 0.9rem;
  font-weight: 400;
  color: #fff;
}

.progress-percent {
  font-size: 0.9rem;
  font-weight: 500;
  color: #fff;
}

.progress-bar {
  width: 100%;
  min-width: 175px;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.progress-bar-progress {
  width: 100%;
  height: 0.4rem !important;
  margin-top: 0.4rem;
}
