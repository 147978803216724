.program_padding {
  padding: 0 20px;
}

.program_payment_label {
  font-size: 25px;
}

.program_radio {
  display: block;
}

.form-check-label {
  padding: 0 20px;
}

.payment_method {
  width: 20px;
  margin: 0 10px;
}

@media (max-width: 991px) {
  .form-check-label {
    font-size: 12px;
  }
}
