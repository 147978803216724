.personal_info_card_st {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 50px 0;
}
.personal_button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px 20px;
  background-color: #006d77;
  border-radius: 15px;
  color: #fff;
  font-size: 16px;
  border: none;
  outline: none;
}
.form-group {
  text-align: initial;
}
.personal_input_in {
  width: 100%;
  border: none;
  border-bottom: 1px solid #555;
  background-color: transparent;
  font-size: 17px;
  margin-bottom: 30px;
}
.auth_circle_icon_2 {
  position: absolute;
  right: 20px;
  margin-top: 24px;
  z-index: 2;
  width: 15px;
  cursor: pointer;
}
.auth_eye_2 {
  position: absolute;
  right: 20px;
  margin-top: 24px;
  z-index: 2;
  width: 15px;
  cursor: pointer;
}

body[dir="rtl"] .auth_eye_2,
body[dir="rtl"] .auth_circle_icon_2 {
  position: absolute;
  right: unset;
  left: 20px;
  margin-top: 24px;
  z-index: 2;
  cursor: pointer;
}

.personal_input {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.personal_form {
  margin-top: 50px;
}
.personal_info_card {
  display: flex;
  flex-direction: column;
  width: 70%;
  height: 100%;
  border: 2px solid #ccc;
  background-color: #fff;
  border-radius: 20px;
  padding: 50px 80px;
}

.personal_label {
  display: flex;
}

@media (max-width: 1199px) {
  .personal_info_card {
    width: 80%;
  }
}

@media (max-width: 991px) {
  .personal_info_card {
    width: 100%;
  }
  .personal_label {
    font-size: 10px;
  }
  .personal_button {
    font-size: 10px;
  }
}

@media (max-width: 767px) {
  .personal_info_card_st {
    margin: 20px 0;
  }
  .personal_info_card {
    padding: 10px 20px;
  }
  .personal_label {
    font-size: 12px;
  }
  .personal_button {
    font-size: 12px;
  }
}
