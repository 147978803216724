.input-field {
  width: 100%;
}

.input-field input {
  width: 100%;
  /* height: 50px; */
  padding: 0 1rem;
  border: 1px solid #ebebeb;
  background-color: #ffffff;
  border-radius: 6px 0px 0 6px;
  color: #777777;
}

.select-box {
  background: #26b3b9;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px 0 0;
  border-radius: 0px 6px 6px 0px;
}

.input-field input::placeholder {
  color: #b1b1b1;
}

.input-field label {
  color: #777777;
}

.input-field input::-webkit-outer-spin-button,
.input-field input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input-field input[type="number"] {
  -moz-appearance: textfield;
}

.input-field-error {
  margin: 0 !important;
  padding: 0 !important;
  height: 10px !important;
}

.select-input {
  display: flex;
}

.phone-select {
  width: 150px !important;
  min-width: 150px !important;
  background: #26b3b9 !important;
}

.phone-select .css-b62m3t-container {
  background: #26b3b9 !important;
}

.phone-select .css-1f43avz-a11yText-A11yText {
  background: #26b3b9 !important;
}

.phone-select .css-13cymwt-control {
  background: #26b3b9 !important;
  color: white;
}

.phone-select .css-1fdsijx-ValueContainer,
.phone-select .css-1dimb5e-singleValue,
.phone-select .css-olqui2-singleValue {
  color: white !important;
}

/* @media (max-width: 767px) {
  .phone-select {
    width: 75px;
    min-width: 75px;
  }
} */
