.stripe_label {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.img_card_pay {
  margin: 0px 10px 20px;
}

.top_text {
  font-size: 18px;
}

.bottom_text {
  font-size: 14px;
}

.form_label {
  display: block;
  margin-top: 15px;
}

.form_label::after {
  content: "*";
  margin: 0 5px;
  color: #dc3545;
  font-size: 20px;
}

.payment_button {
  margin-top: 15px;
}

.stripe_btn {
  width: 170px;
  margin: 10px;
  padding: 10px;
  font-size: 14px;
  background-color: #006d77;
  color: #fff;
  border-radius: 15px;
  outline: none;
  box-shadow: none;
  overflow: hidden;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 767px) {
  .form_label {
    font-size: 14px;
  }
}
