.sharePopup {
  position: absolute;
  top: 4rem;
  left: 35%;
  right: unset;
  /* transform: translateX(-50%); */
  background-color: #fff;
  border-radius: 10px;
  z-index: 100;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
body[dir="ltr"] .sharePopup {
  left: unset;
  right: 35%;
}

.sharePopupContent {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding: 1rem;
}
@media (max-width: 970px) {
  body[dir="ltr"] .sharePopup {
    left: unset;
    right: 8%;
  }
  .sharePopup {
    left: 6%;
  }
}
@media (max-width: 400px) {
  body[dir="ltr"] .sharePopup {
    left: unset;
    right: -2%;
  }
  .sharePopup {
    left: -2%;
  }
}
