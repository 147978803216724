.container-fluid {
  max-width: 1280px;
  padding: 0 0px;
  width: 100%;
}

.admin_add_input {
  display: flex;
  background-color: transparent;
  color: #000;
  backdrop-filter: blur(100px);
  position: relative;
  /* padding: 0 15px; */
  border: none;
  border-bottom: 1px solid #ccc;
  width: 100%;
  height: 50px;
}

@media (max-width: 1199px) {
  .personal_info_label {
    margin: 50px 80px;
    display: flex;
    flex-direction: column;
  }
  .personal_info_top {
    font-size: 25px;
  }

  .personal_info_card {
    width: 80%;
  }
}
@media (max-width: 991px) {
  .form-check-label {
    font-size: 12px;
  }
  .personal_info_label {
    margin: 0;
  }
  .personal_info_bottom {
    font-size: 14px;
  }

  .personal_info_top {
    font-size: 20px;
  }
  .personal_info_card {
    width: 100%;
  }
  .perosnal_text_img {
    font-size: 20px;
  }
  .personal_img_text {
    font-size: 10px;
  }
  .personal_label {
    font-size: 10px;
  }
  .personal_info_label {
    margin: 0 !important;
  }
  .personal_info_bottom {
    margin-top: 5px;
  }
  .personal_info_card {
    width: 100% !important;
    padding: 30px !important;
  }
}

@media (max-width: 768px) {
  .container-fluid {
    padding: 0 15px;
  }
  .personal_info_top {
    font-size: 14px;
  }
  .personal_info_bottom {
    font-size: 10px;
  }

  .personal_info_card_st {
    margin: 20px 0;
  }

  .personal_info_card {
    padding: 10px 20px;
  }
  .perosnal_text_img {
    font-size: 14px;
  }
  .course_dimage {
    width: 70px;
    height: 70px;
  }
  .course_browse {
    width: 30px;
    height: 30px;
    margin-left: 40px;
    margin-top: -20px;
  }
  .personal_img_text {
    font-size: 12px;
    margin: 0 9px;
  }
  .personal_label {
    font-size: 12px;
  }

  .admin_add_form__group {
    flex-wrap: wrap;
  }
  .admin_add_form__group > div {
    width: 100% !important;
  }
  .admin_add_form__group > div input {
    height: 50px !important;
  }
  .personal_info_card {
    padding: 15px !important;
  }
}
.personal_info_top {
  display: flex;
  font-size: 30px;
}
.min-hight-78 {
  min-height: calc(78vh - 20px);
}

.min-hight-78-200 {
  min-height: calc(78vh - 189px);
}

.personal_info_bottom {
  display: flex;
}

.training_first_label {
  display: flex;
  font-size: 16px;
  padding: 50px 0 15px 0;
}

.personal_info_label {
  margin: 50px 100px;
  display: flex;
  flex-direction: column;
}

.personal_info_card_st {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 50px 0;
}
.personal_info_card {
  display: flex;
  flex-direction: column;
  width: 70%;
  height: 100%;
  border: 1px solid #ccc;
  background-color: #fffbfb;
  border-radius: 20px;
  padding: 50px 80px;
}

.perosnal_text_img {
  display: flex;
  font-size: 25px;
}

.course_img {
  display: flex;
  margin: 30px 0 0;
}

.course_dimage {
  background-image: url("../../../../assets/image/team7.png");
  color: #000;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d4d4d4;
  border-radius: 50%;
  object-fit: cover;
  background-position: center;
  background-size: cover;
}

.course_browse {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #006d77;
  border-radius: 50%;
  float: left;
  margin-top: -30px;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

.personal_browse_img {
  width: 50%;
}

.personal_img_text {
  text-align: initial;
  margin: 20px;
}
.form-input-error-space {
  height: 18px;
  font-size: 12px;
  color: red;
}

.personal_form {
  margin-top: 50px;
}

.personal_label {
  display: flex;
}

.admin_add_form {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.personal_input_in {
  width: 100%;
  border: none;
  border-bottom: 1px solid #555;
  background-color: transparent;
  font-size: 17px;
  margin-bottom: 30px;
  padding: 10px 0px !important;
}
