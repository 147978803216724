.containerUsersList {
  background-color: #fbfbfb;
  padding: 20px;
  border-radius: 6px;
  height: 70vh;
  overflow-x: none;
  overflow-y: auto;
}

.usersListTitle {
  color: #056c77;
  font-size: 26px;
}

.card {
  background-color: #fff;
  display: flex;
  border-bottom: 1px solid #f1f1f1;
  flex-direction: row;
  padding: 2% 4% 4% 4%;
  cursor: pointer;
  justify-content: space-between;
  flex-direction: column;
  flex-direction: column;
  align-items: start;
  gap: 15px;
}

.selected {
  color: #fff !important;
  background-color: #24b3b9;
}

.selectedEmail {
  color: #fff !important;
}

.newmsg {
  border-left: 5px solid #056c77;
}

.card:first-of-type {
  border-radius: 6px 6px 0 0;
}

.card:last-of-type {
  border-radius: 0px 0px 6px 6px;
  border-bottom: none;
}

.active-indicator {
  position: absolute;
  bottom: 5px;
  right: 53px;
  width: 12px;
  height: 12px;
  background-color: #24b947;
  border-radius: 50%;
  border: 2px solid #fff;
}

.inactive-indicator {
  position: absolute;
  bottom: 5px;
  right: 53px;
  width: 12px;
  height: 12px;
  background-color: #a7a7a7;
  border-radius: 50%;
  border: 2px solid #fff;
}

.isAssignee {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  background: #f1f1f1;
  color: #8b8b8b;
  height: 100%;
  padding: 5px;
  border-radius: 5px;
  width: 60px;
}

.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 50%;
  width: 80px;
  height: 80px;
}

.admin_img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.iconCharacter {
  font-size: 17px;
  color: white;
}

.activeIcon {
  border-radius: 50%;
  padding: 5px;
  position: absolute;
  bottom: 0px;
  left: 5px;
}

.contentText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 10px;
  width: 100%;
}

.flexContainer {
  display: flex;
  align-items: center;
  width: 100%;
}

.relativePosition {
  position: relative;
}

.relativePositionAssign {
  position: relative;
  z-index: 10000;
}

.userName {
  font-size: 14px;
  color: #4b5155;
  padding: 0px;
}

.email {
  font-size: 12px;
  color: #24b3b9;
}

.typing {
  color: #afbbc6;
  font-size: 13px;
}

.containerCounter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.counter {
  background-color: #76c00d;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.counterText {
  font-size: 11px;
  color: white;
  padding-top: 20%;
}

.tooltip {
  position: absolute;
  top: 100%;
  /* left: 0; */
  /* transform: translateX(-50%); */
  background-color: #4a4a4a;
  padding: 10px;
  max-width: 200px;
  text-align: center;
  font-size: 12px;
  border-radius: 4px;
  color: #fff;
  z-index: 10000;
}

.tooltipAssign {
  position: absolute;
  top: 125%;
  left: 0;
  background-color: #4a4a4a;
  padding: 10px;
  opacity: 1;
  max-width: 200px;
  text-align: center;
  font-size: 12px;
  border-radius: 4px;
  color: #fff;
  z-index: 10000000 !important;
}

body[dir="ltr"] .tooltipAssign {
  left: unset;
  right: 0;
}

.hover-content div {
  border-bottom: 1px solid #8b8b8b;
  padding: 5px 10px;
}

.hover-content-assign div {
  border-bottom: 1px solid #8b8b8b;
  padding: 5px 10px;
}

.hover-content div:last-of-type {
  border-bottom: none;
}

.hover-content-assign div:last-of-type {
  border-bottom: none;
}

.arrow {
  position: absolute;
  top: -10px;
  transform: translateX(-230%);
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #4a4a4a transparent;
}

.arrowAssign {
  position: absolute;
  top: -10px;
  left: 25px;
  right: unset;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #4a4a4a transparent;
}

body[dir="ltr"] .arrow {
  transform: translateX(230%);
}

body[dir="ltr"] .arrowAssign {
  left: unset;
  right: 25px;
}

.btn-style {
  padding: 0 15px 3px 15px;
  font-size: 12px;
  cursor: pointer;
}

.btn-style-selected {
}

.chatName {
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.followUp {
  display: flex;
  justify-content: end;
}

.rolelabel {
  margin: 0 2px;
  width: 30%;
}

.rolelabel::after {
  content: "";
  border-left: 2px solid #afbbc6;
  padding: 0 2px;
}

.rolelabel:last-of-type::after {
  content: "";
  border-left: none;
}

@media (max-width: 1279px) {
  .iconContainer {
    width: 60px;
    height: 60px;
  }
  .admin_img {
    width: 50px;
    height: 50px;
  }

  .active-indicator {
    right: 35px;
  }

  .inactive-indicator {
    right: 35px;
  }

  .card {
    flex-direction: column;
    align-items: start;
    gap: 15px;
  }
}

@media (max-width: 1023px) {
  .iconContainer {
    width: 50px;
    height: 50px;
  }
  .admin_img {
    width: 40px;
    height: 40px;
  }

  .active-indicator {
    right: 30px;
  }

  .inactive-indicator {
    right: 30px;
  }

  .card {
    flex-direction: column;
    align-items: start;
    gap: 15px;
    padding: 2% 4% 2% 4%;
  }

  .email {
    width: 150px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .containerUsersList {
    height: 40vh;
    margin-bottom: 10px;
    padding: 10px;
  }

  .card {
    flex-direction: row;
    align-items: center;
  }

  .email {
    width: 130px;
  }

  .usersListTitle {
    font-size: 18px;
  }
}

@media (max-width: 767px) {
  .containerUsersList {
    height: 40vh;
    margin-bottom: 10px;
    padding: 10px;
  }

  .card {
    flex-direction: row;
    align-items: center;
  }

  .email {
    width: 130px;
  }

  .usersListTitle {
    font-size: 18px;
  }
}
