.table_bank_tran {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    margin-top: 40px;
    padding: 50px 0;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 2px 2px 10px #ddd;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.btn-bg-filter{
    background-color: #18B1B9
}

/* .tajah_table_vertical_movement {
    position: absolute;
    transition: top 0.5s;
} */

.tajah_table_fade_change {
    transition: opacity 0.5s;
}

.table_bank_tran .table {
    width: 100%;
}

.table_bank_tran td,
.table_bank_tran th {
    text-align: center;
}

.admin_filter_input {
    display: flex;
    background-color: transparent;
    color: #000;
    -webkit-backdrop-filter: blur(100px);
    backdrop-filter: blur(100px);
    position: relative;
    width: 100%;
    height: 38px;
    border-color: #cccccc;
    box-shadow: none;
    border-style: solid;
    border-width: 0.5px;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
}

.tajah_card_title_container_answers {
    width: 90%;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
}

.tajah_card_title_container_actions {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.tajah_filter_item_label_actionable {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
}

.tajah_filter_item_label_action {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
}

.tajah_filter_item_label_action_clear {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: red;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
