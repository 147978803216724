.system-users-list {
  padding: 2rem 0;
}

.system-users-list__container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.system-users-list__table__actions {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
}

.system-users-list__table__actions-btn {
  background: none;
  border: none;
  padding: 0;
}

.system-users-list__table__actions-btn[disabled] {
  opacity: 0.3;
  cursor: not-allowed;
}

.system-users-list__table-email__wrapper {
  direction: ltr;
}

.system-users-list__table-email {
  color: #24b3b9;
  cursor: pointer;
}

.system-users-list__table-email:hover {
  color: #24b3b9 !important;
}

.system-users-list__table-jobs {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: center;
  flex-wrap: wrap;
  width: 225px;
}
.system-users-list__table-jobs-item {
  color: #046c77;
  position: relative;
}

.system-users-list__table-jobs-item:not(:last-of-type)::after {
  content: "";
  position: absolute;
  top: 8px;
  left: -0.5rem;
  width: 1px;
  height: 60%;
  background: #046c77;
}

.system-users-list__table-register-type {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3rem;
  height: 2.5rem;
  width: 10rem;
  background-color: rgb(36 179 185 / 40%);
  color: #24b3b9;
}
.system-users-list__table-register-type--twitter {
  background-color: rgb(137 228 232 / 40%);
  color: #3dacce;
}
.system-users-list__table-register-type--facebook {
  background-color: rgb(126 153 226 / 40%);
  color: #7e99e2;
}

.system-users-list__table-register-type--linkedIn {
  background-color: rgb(11 51 183 / 40%);
  color: #0b33b7;
}
.system-users-list__table-register-type--google {
  background-color: rgb(185 36 36 / 40%);
  color: #b92424;
}

.reject-payment-modal__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem;
  border-bottom: 1px solid #ddd;
}

.reject-payment-modal__header h3 {
  font-size: 1.4rem;
  font-weight: 600;
  color: #046c77;
}

.reject-payment-modal__header img {
  cursor: pointer;
  width: 2rem;
  height: 2rem;
}

.reject-payment-modal__body {
  padding: 1.5rem;
}

.reject-payment-modal__submit {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.reject-payment-modal__submit-btn {
  padding: 1rem 6rem;
}
