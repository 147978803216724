.tajah-lesson-header {
    height:60px;
    background-color: #FFFFFF;
    width: 100%;
    margin-top: 47px;
    box-shadow: 2px 2px 10px #ddd;
    display: flex;
    flex-direction: row;
    padding: 15px;
}

.tajah-lesson-header-start {
    flex:2;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.tajah-lesson-header-end {
    flex:1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}
