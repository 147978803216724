.certificates__container {
  margin-top: 1rem;
}

.certificates__table__actions {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
}

.certificates__table__actions-btn {
  background: none;
  border: none;
  padding: 0;
  width: 1.6rem;
  height: 1.6rem;
}

.certificates__table__actions-btn img {
  width: 100%;
  height: 100%;
}

.certificates__table__actions-btn[disabled] {
  opacity: 0.3;
  cursor: not-allowed;
}
