.modal_head {
    width: 100%;
    padding: 50px 20px 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.modal_head_label {
    font-size: 30px;
    margin: 0 0 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.modal_head_label_bottom {
    font-size: 18px;
    margin: 0 0 20px 0;
    display: flex;
    align-items: center;
}

.modal_pay_head_card_after {
    display: flex;
    flex-direction: column;
}

.modal_flex {
    width: 80%;
    display: flex;
    align-items: center;
}

.modal_course_img_after {
    display: flex;
    width: 160px;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.modal_course_text_after {
    display: flex;
    padding: 10px 20px;
    width: 100%;
}

.overall_modal {
    display: flex;
    justify-content: space-between;
}

.overall_price {
    padding: 20px 0 0;
    font-size: 16px;
}

.overall_text {
    font-size: 14px;
}

.modal_coupon_video {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #006d77;
    padding: 20px 0 0;
    font-size: 16px;
}

.modal_coupon_video img {
    width: 40px;
    margin: 0 10px;
}

.modal-body {
    display: flex;
    flex-direction: column;
    padding: 35px;
}

.modal_body_label {
    font-size: 30px;
    margin-bottom: 20px;
}

.modal_card_after {
    display: flex;
    align-items: center;
}

.img_card_pay_after {
    margin: 0px 10px 20px;
}

.modal_top_text {
    font-size: 18px;
}

.modal_bottom_text {
    font-size: 14px;
}

.form_modal_label {
    display: block;
    margin-top: 15px;
}

.form_modal_label::after {
    content: "*";
    margin: 0 5px;
    color: #dc3545;
    font-size: 20px;
}

.payment_button {
    margin-top: 15px;
}

.modal_btn {
    border: none;
    background-color: #006d77;
    color: #fff;
    padding: 5px 20px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.submit_btn{
    cursor: pointer;
}

@media (max-width: 991px) {
    .modal_head_label,
    .modal_body_label {
        font-size: 22px;
    }

    .modal_head_label_bottom {
        font-size: 15px;
    }
}

@media (max-width: 767px) {
    .modal_flex {
        width: 100%;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
    }

    .modal_course_text_after {
        font-size: 12px;
        padding: 10px;
    }

    .overall_modal {
        flex-direction: column;
    }

    .img_card_pay_after {
        height: 60px;
    }

    .modal_top_text {
        font-size: 14px;
    }

    .modal_bottom_text {
        font-size: 12px;
        margin-bottom: 20px;
    }

    .form_modal_label {
        font-size: 14px;
    }
}
