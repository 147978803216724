.personal_info_label {
  margin: 50px 100px;
  display: flex;
  flex-direction: column;
}
.course_img {
  display: flex;
  margin: 30px 0 0;
}

.course_dimage {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.course_dimage {
  background-image: url("../../../../../assets/image/team7.png");
  color: #000;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d4d4d4;
  border-radius: 50%;
  object-fit: cover;
  background-position: center;
  background-size: cover;
}

.course_browse {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #006d77;
  border-radius: 50%;
  float: left;
  margin-top: -30px;
  position: relative;
  z-index: 2;
  cursor: pointer;
  margin-left: 0px;
}

.personal_browse_img {
  width: 25px;
}

.personal_info_top {
  display: flex;
  font-size: 30px;
}

.personal_info_bottom {
  display: flex;
  color: #707070;
}
.perosnal_text_img {
  display: flex;
  font-size: 25px;
}

.personal_img_text {
  display: flex;
  align-items: center;
  padding: 20px;
  color: #707070;
}

.personal_info_card_st {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 50px 0;
}

.personal_info_card {
  display: flex;
  flex-direction: column;
  width: 70%;
  height: 100%;
  border: 2px solid #ccc;
  background-color: #fffbfb;
  border-radius: 20px;
  padding: 50px 80px;
}

.personal_form {
  margin-top: 50px;
}

.admin_add_form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.personal_label {
  display: flex;
}

.personal_input_in {
  width: 100%;
  border: none;
  border-bottom: 1px solid #ccc;
  background-color: transparent;
  font-size: 17px;
}
.admin_coupon_radio_profile {
  display: flex;
  font-size: 14px;
  width: 100%;
}

.form-check-label-profile {
  padding: 0px;
}
.main_box .admin_coupon_radio_profile {
  justify-content: space-between;
}

.admin_add_input {
  display: flex;
  background-color: transparent;
  color: #000;
  backdrop-filter: blur(100px);
  position: relative;
  /* padding: 0 15px; */
  border: none;
  border-bottom: 1px solid #ccc;
  width: 100%;
}

.profiles hr {
  border: none;
  height: 3px;
  color: #ccc;
  background-color: #ccc;
  text-align: left;
  margin-top: -23px;
}
.profile {
  margin: 20px 0;
  display: flex;
}
.pri_profile {
  color: #555 !important;
}

.pri_profile a {
  color: #555 !important;
}
.margin_tabs {
  padding: 0 20px;
}

.links {
  display: flex;
  background-color: transparent;
}
.links.active {
  padding-bottom: 15px;
  border-bottom: 3px solid #18b1b9;
}

.shape_radio {
  display: none;
}

.shape_radio_question {
  display: none;
}

.shape_radio + label {
  -webkit-appearance: none;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #e1e1e1; */
  border: 1px solid #7e7e7e;
  border-radius: 50px;
  width: 100%;
  /* display: inline-block; */
  /* position: relative; */
  width: 23px;
  height: 23px;
  cursor: pointer;
  background-color: transparent;
}

.shape_radio_question + label {
  -webkit-appearance: none;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #e1e1e1; */
  border: 1px solid #7e7e7e;
  border-radius: 50px;
  width: 100%;
  /* display: inline-block; */
  /* position: relative; */
  width: 23px;
  height: 23px;
  cursor: pointer;
  background-color: transparent;
}

.shape_radio + label span {
  -webkit-appearance: none;
  /* background-color: #e1e1e1; */
  border: 1px solid transparent;
  border-radius: 50px;
  width: 100%;
  display: inline-block;
  position: relative;
  width: 17px;
  height: 17px;
  cursor: pointer;
  background-color: transparent;
}

.shape_radio_question + label span {
  -webkit-appearance: none;
  /* background-color: #e1e1e1; */
  border: 1px solid transparent;
  border-radius: 50px;
  width: 100%;
  display: inline-block;
  position: relative;
  width: 17px;
  height: 17px;
  cursor: pointer;
  background-color: transparent;
}

.shape_radio_question + label span {
  -webkit-appearance: none;
  /* background-color: #e1e1e1; */
  border: 1px solid transparent;
  border-radius: 50px;
  width: 100%;
  display: inline-block;
  position: relative;
  width: 17px;
  height: 17px;
  cursor: pointer;
  background-color: transparent;
}

.gender_name {
  margin-right: 10px;
  margin-left: 10px;
}

.shape_radio:checked + label span {
  background: #006d77;
  border: 1px solid #7e7e7e;
}

.shape_radio_question:checked + label span {
  background: #006d77;
  border: 1px solid #7e7e7e;
}

.input_radio_container {
  display: flex;
  flex-direction: row;
}

@media (max-width: 1199px) {
  .personal_info_label {
    margin: 50px 80px;
    display: flex;
    flex-direction: column;
  }
  .personal_info_top {
    font-size: 25px;
  }

  .personal_info_card {
    width: 80%;
  }
}

@media (max-width: 991px) {
  .personal_info_label {
    margin: 0;
  }
  .personal_info_top {
    font-size: 20px;
  }

  .personal_info_bottom {
    font-size: 14px;
  }
  .personal_info_card {
    width: 100%;
  }
  .perosnal_text_img {
    font-size: 20px;
  }
  .personal_label {
    font-size: 10px;
  }
  .links {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .personal_info_top {
    font-size: 14px;
  }
  .personal_info_bottom {
    font-size: 10px;
  }
  .personal_info_card_st {
    margin: 20px 0;
  }
  .personal_info_card {
    padding: 10px 20px;
  }
  .perosnal_text_img {
    font-size: 14px;
  }
  .personal_label {
    font-size: 12px;
  }
  .links {
    font-size: 10px;
  }

  .admin_add_input {
    padding: 15px;
  }
  .course_img {
    flex-direction: column;
  }
  .nav.nav-tabs.profile {
    overflow-x: auto;
    flex-wrap: nowrap;
  }
  .nav.nav-tabs.profile .links {
    white-space: nowrap;
  }
}
body[dir="rtl"] .PhoneInputCountryIcon {
  margin-right: 0;
  margin-left: 10px;
}
