.profile-title-container {
  margin-top: 1rem;
}

.profile-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #046c77;
}

.profile-warning {
  background-color: #df4b43;
  color: #fff;
  padding: 0.4rem 1rem;
  border-radius: 6px;
  margin: 1rem 0;
  width: fit-content;
  font-size: 0.9rem;
}

.personal-details-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}

.profile-save-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1rem;
}

.profile-save-container button {
  padding: 0.7rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  width: 225px;
}

.width-100 {
  width: 100%;
}

.width-65 {
  width: calc(65% - 0.5rem);
}

.width-35 {
  width: calc(35% - 0.5rem);
}

.width-50 {
  width: calc(50% - 0.5rem);
}

.email-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  border: 1px solid #ddd;
  border-radius: 6px;
  margin-bottom: 10px;
}

.email-actions-title {
  padding: 0.4rem 1rem;
  width: 100%;
  font-size: 0.7rem;
  color: #046c77;
  display: flex;
  align-items: center;
}

.email-actions button {
  padding: 0.7rem 1rem;
  width: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-image-input {
  display: none;
}

.profile-image-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
}

.profile-image {
  width: 14rem;
  height: 14rem;
  border-radius: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.profile-image::after {
  content: "";
  width: 110%;
  height: 110%;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid #24b3b9;
}

.profile-image img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.profile-image-plus {
  position: absolute;
  bottom: -2rem;
  z-index: 1;
  width: 3.2rem;
  height: 3.2rem;
}

.profile-image-plus img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-image-text {
  text-align: center;
  font-size: 0.8rem;
  color: #969696;
}

.email-modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem;
  border-bottom: 1px solid #ddd;
}

.email-modal-title {
  font-size: 1.4rem;
  font-weight: 600;
  color: #046c77;
}

.email-modal-exit {
  cursor: pointer;
  width: 2rem;
  height: 2rem;
}

.email-modal-body {
  padding: 1.5rem;
}

.email-modal-form {
  display: flex;
  flex-direction: column;
}

.email-modal-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4rem;
}

.email-modal-button-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.email-modal-button-text {
  font-size: 0.8rem;
  color: #969696;
}

@media (max-width: 767px) {
  .width-100 {
    width: 100%;
  }

  .width-65 {
    width: 100%;
  }

  .width-35 {
    width: 100%;
  }

  .width-50 {
    width: 100%;
  }

  .email-actions {
    flex-direction: column;
    margin-top: 0;
  }
}
