.left,
.right {
  display: block;
  position: relative;
  border-radius: 10px;
  display: inline-block;
  margin: 5px;
  padding: 10px;
  /*clear: both;*/
  word-break: break-word;
}

.left {
  float: left;
  margin-left: 20px;
  background-color: white;
  color: #707070;
}
.right {
  float: right;
  margin-right: 20px;
  background-color: #316d66;
  color: white;
}

.Toastify__toast--warning {
  /* it's a bad behavior, we need to change it in the future  */
  background: #8999b3;
}
