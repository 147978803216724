.back {
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
}
.title {
  font-size: 22px;
  font-weight: 600;
  color: #000000;
}
.back-icon {
  width: 1.5rem;
  height: 1.5rem;
}

.back-icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.back-text {
  font-size: 1.2rem;
  font-weight: 600;
  color: #707070;
  padding-bottom: 0.4rem;
}
.mainCont {
  padding: 2rem 0;
}
.cardsCont {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.CardCont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2.5rem;
  border-radius: 12px;
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  border: 1px solid #dcdcdc;
}
@media (max-width: 768px) {
  .CardCont {
    flex-wrap: wrap;
    gap: 1rem;
  }
}
.surveyName {
  font-size: 16px;
  font-weight: bold;
  color: #5c5c5c;
}
.btnCont {
  display: flex;
  gap: 1rem;
}
.btnOne {
  color: #00a2b0;
  border: 1px solid #00a2b0;
  border-radius: 10px;
  padding: 12px 25px;
  font-size: 12px;
  cursor: pointer;
}
.btnTwo {
  font-size: 12px;
  background-color: #00a2b0;
  color: #ffffff;
  border-radius: 10px;
  padding: 12px 25px;
  cursor: pointer;
}
/* :disabled */
.btnOne:disabled {
  background-color: #cccccc;
  border: 2px solid #cccccc;
  color: #ffffff;
  cursor: not-allowed;
}
.btnTwo:disabled {
  background-color: #cccccc;
  border: 2px solid #cccccc;
  color: #ffffff;
  cursor: not-allowed;
}
.maintext {
  font-size: 16px;
  font-weight: 600;
  color: #979797;
  text-align: center;
  margin-top: 100px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-direction: column;
}
.maintext img {
  width: 120px;
}
