.manage-departments {
	padding: 2rem 0;
}

.manage-departments__container,
.manage-departments__form {
	display: flex;
	flex-direction: column;
	gap: 2rem;
}

.manage-departments__form__fields {
	margin: 0 -15px;
}

.manage-departments__form__field {
	margin-bottom: 2rem;
}

.manage-departments__form__field label {
	color: #777777;
}

.manage-departments__form-error {
	margin: 0 !important;
	padding: 0 !important;
}
