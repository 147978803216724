.mainSectionContainer {
  display: flex;
  align-items: stretch;
  gap: 20px;
  height: 450px;
}

.mainSectionContent {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 75px;
  padding-bottom: 75px;
  padding-inline-start: 100px;
}

.mainSectionSubtitle {
  font-size: 1.5rem;
  font-weight: 600;
}

.mainSectionTitle {
  font-size: 2.5rem;
  font-weight: 700;
}

.mainSectionDescription {
  font-size: 0.9rem;
  line-height: 1.6;
  margin-bottom: 30px;
}

.mainSectionImage {
  width: 50%;
}

.mainSectionAction {
  margin-top: auto;
  margin-bottom: 75px;
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 20px 30px;
}

.mainSectionImage img {
  height: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 992px) {
  .mainSectionContainer {
    flex-direction: column;
    gap: 20px;
    padding: 50px 0;
    align-items: center;
    height: auto;
  }

  .mainSectionContent {
    width: 100%;
    padding-inline-start: 0;
    padding: 0 20px;
  }

  .mainSectionTitle {
    font-size: 2rem;
  }

  .mainSectionDescription {
    font-size: 0.8rem;
  }

  .mainSectionImage {
    width: 100%;
  }

  .mainSectionAction {
    padding: 20px 50px;
    margin-bottom: 0;
  }
}
