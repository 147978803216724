.training-course-syllabus-loading-section-container {
  width: 100%;
  height: auto;
  z-index: 10;
  position: absolute;
  top: 0;
  transition: opacity 0.5s;
}

.training-course-syllabus-loading-section-container.unactive {
  display: none;
}

.training-course-syllabus-section-container {
  padding: 20px;
  width: 100%;
  height: 100%;
}

.training-course-syllabus-section-container-border {
  background: white;
  width: 100%;
  height: 100%;
  box-shadow: 2px 3px 6px #cfcfcf;
  border-radius: 8px;
  border: 1px solid #e7e7e7;
}

.training-course-syllabus-section-container-row-header {
  padding: 2% !important;
  display: flex;
  align-items: center;
  font-size: 22px;
  border-bottom-color: #e7e7e7;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.skeleton-box {
  display: flex;
  height: 1em;
  position: relative;
  overflow: hidden;
  background-color: #e2e2e2;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.skeleton-box::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    to right,
    #e2e2e2 30%,
    #eff1f3 60%,
    #e2e2e2 100%
  );
  animation: shimmer 2s infinite;
  content: "";
}

.skeleton-margin-top-bottom {
  margin: 25px 0;
}

.training-course-syllabus-section-container-row-lesson-header {
  height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
  border-bottom-color: #e7e7e7;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: row;
  cursor: pointer;
}

.training-course-syllabus-section-container-row:last-child
  .training-course-syllabus-section-container-row-lesson:last-child
  .training-course-syllabus-section-container-row-lesson-header.closed:first-child {
  border-radius: 13px;
  border-bottom-width: 0px;
}
