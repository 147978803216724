.statistics_panel_wrapper {
    margin-top: 20px;
    margin-bottom: 20px;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.statistics_panel {
    width:100%;
    height: 100%;
    background-color: #FFFFFF;
    box-shadow: 0px 5px 10px #0000000A;
    border-radius: 10px;
    min-height: 700px;
}

.statistics_panel_body_grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    grid-auto-rows: minmax(100px, auto);
}

.statistics_panel_body_grid_item_level_one {
    height: 310px;
}

.statistics_panel_body_grid_item_level_two {
    height: 250px;
}

.statistics_panel_body_grid_item_card_board {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 25px;
    height: 100%;
}

.statistics_card_item {
    box-shadow: 0px 3px 6px #272D3B33;
    border-radius: 20px;
    height: 100%;
    padding: 15px;
}

.statistics_card_item_value {
    font-weight: bold;
    font-size: 15px;
    margin-top: 20px;
    color:#FFFFFF;
}

.statistics_card_item_label {
    font-size: 13px;
    color:#FFFFFF;
}

.statistics_card_item.centered {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.statistics_panel_header {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: row;
}

.statistics_panel_header_label {
    flex:1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.statistics_panel_header_action {
    flex: 2;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.statistics_panel_body {
    width: 100%;
    padding: 20px;
}

#pie-chart-holder {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(180deg, #FFFFFF, #FBFBFB);
    box-shadow: 0px 3px 6px #A4A4A429;
    border: 1px solid #F1F1F1;
    border-radius: 10px;
}

.statistics_rating_summary_row {
    box-shadow: 0px 3px 6px #A4A4A429;
    border: 1px solid #F1F1F1;
    border-radius: 10px;
    height: 30%;
    background-image: linear-gradient(180deg, #FFFFFF, #F1FDFF);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
}

.statistics_rating_summary_row.final {
    height: 40%;
    display: flex;
    justify-content: center;
}

.statistics_rating_summary_row_text{
    font-size: 14px;
}

.statistics_rating_summary_row_grade{
    font-size: 18px;
    font-weight: bold;
}