.mainClass {
  margin: 20px;
  border-radius: 20px;
  display: flex;
  justify-content: space-around;
  padding: 70px 20px;
  gap: 20px;
  background-color: #0e4d85;
  background-image: url("../../../assets/image/newContainerBackground.png");
  background-size: cover;
}
.dreams {
  font-size: 32px;
  font-weight: 600;
  color: #fff;
}
.btnRegister {
  padding: 10px 30px;
  border-radius: 15px;
}

@media (max-width: 768px) {
  .mainClass {
    padding: 20px;
    flex-direction: column;
    align-items: center;
  }

  .dreams {
    font-size: 20px;
  }
}
