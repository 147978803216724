.question-canvas-for-question-bank-in-exam {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.tajah-question-progress {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.tajah-pre-table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding-top: 20px;
    padding-bottom: 20px;
}

.tajah-question-bank-submission-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 30px;
}
