.trainers_grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  grid-auto-rows: minmax(100px, auto);
}

.trainers_card {
  width: 210px;
  background-color: #011e20;
  font-size: 18px;
  height: 375px;
}
.no-record {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
}

.thumb-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1199px) {
  .trainers_grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    grid-auto-rows: minmax(100px, auto);
  }
  .trainers_card {
    font-size: 18px;
    width: 200px;
    height: 350px;
  }
}

@media (max-width: 991px) {
  .trainers_grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    grid-auto-rows: minmax(100px, auto);
  }
  .trainers_card {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .trainers_grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
    grid-auto-rows: minmax(100px, auto);
  }
  .trainers_card {
    font-size: 12px;
  }
}
