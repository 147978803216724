.tajah-lesson-zoomSession-list-canvas {
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.tajah-lesson-zoomSession-list-item.opened {
  border-radius: 10px;
  border: 1px solid #ccc;
}

.tajah-activity-details-item {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}

.tajah-activity-details-item-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.tajah-activity-details-item-box-one {
  display: flex;
  align-items: center;
  gap: 0.4rem;
}

.tajah-activity-details-item-box-one-title {
  font-size: 1.2rem;
  font-weight: 600;
  color: #046c77;
}

.tajah-activity-details-item-box-one-value {
  font-size: 1.2rem;
  font-weight: 600;
  color: #707070;
}

.tajah-activity-details-item-box-two {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  width: 350px;
}

.tajah-activity-details-item-box-two-row {
  display: flex;
  align-items: center;
  gap: 0.4rem;
}

.tajah-activity-details-item-box-two-title {
  font-size: 1.2rem;
  font-weight: 600;
  color: #555;
}

.tajah-activity-details-item-box-two-value {
  font-size: 1rem;
  font-weight: 600;
  color: #707070;
}

.tajah-activity-details-item-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.tajah-activity-details-item-actions-btn {
  padding: 1rem 4rem;
}
@media (max-width: 640px) {
  .tajah-activity-details-item-box {
    flex-direction: column;
  }
  .tajah-activity-details-item-box-two-row {
    flex-direction: column;
  }
  .tajah-activity-details-item-actions-btn {
    padding: 1rem;
  }
}

.header_zoom {
  font-size: 26px;
  margin-bottom: 30px;
}
.zoom_container {
  border: 1px solid #ddd;
  border-radius: 15px;
  padding: 10px 20px;
  margin-top: 20px;
  padding-bottom: 20px;
}
.zoom_container .btn-header-com {
  padding-bottom: 40px;
  padding-left: 0;
  padding-right: 0;
}
.zoom_container h4 {
  display: inline-block;
}
body[dir="rtl"] .zoom_container a.invoice_prev {
  left: 10px;
  right: unset;
}
.zoom_container a.invoice_prev,
.zoom_container a.invoice_prev:hover {
  text-align: center;
  display: inline-block;
  position: absolute;
  right: 10px;
  top: 4px;
  background-color: rgb(0, 109, 119);
  border-radius: 10px;
  color: white !important;
  font-size: 13px;
  height: 30px;
  width: 110px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.zoom_container .col-md-6 {
  padding: 0;
}
.zoom_container ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.zoom_container ul li {
  padding: 20px 0;
  border-bottom: 1px solid #ddd;
}
.zoom_container ul li:last-child {
  border-bottom: none;
}
.session_link,
.session_link:hover,
.session_link:active {
  background: #126e77;
  color: #fff !important;
  padding: 6px 20px;
  border-radius: 8px;
  margin-top: 22px;
  display: block;
  width: 125px;
  text-align: center;
}

.session_link_disabled {
  background: gray;
  color: #fff !important;
  padding: 6px 20px;
  border-radius: 8px;
  margin-top: 22px;
  display: block;
  width: 125px;
  text-align: center;
}
