.add_admin {
  padding: 30px;
  border-radius: 20px;
  display: flex;
  width: 100%;
  background-color: #fff;
  box-shadow: 2px 2px 10px #ddd;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  margin-bottom: 50px;
}

.table_header.titleAddFile {
  color: #046C77;
  font-size: 34px;
  margin-bottom: 0;
}


.admin_add_form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.input-flex {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.input-label {
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
}

.admin_add_label {
  font-size: 14px;
}

.admin_add_input_file {
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
  padding: 12px 18px;
  color: #858585;

}

.form-input-error-space {
  height: 18px;
  font-size: 12px;
  color: red;
}

.delete_payment_section {
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.course_dimage {
  background-image: url("../../../../assets/image/team7.png");
  color: #000;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d4d4d4;
  border-radius: 50%;
  object-fit: cover;
  background-position: center;
  background-size: cover;
}

.course_img {
  display: flex;
  margin: 30px 0 0;
}

.course_browse {
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #006d77;
  border-radius: 50%;
  float: right;
  margin-left: 60px;
  margin-top: -30px;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

.personal_browse_img {
  width: 50%;
}

.perosnal_text_img {
  display: flex;
  font-size: 25px;
}

.personal_img_text {
  text-align: initial;
  margin: 20px;
}

@media (max-width: 991px) {
  .perosnal_text_img {
    font-size: 20px;
  }

  .personal_img_text {
    font-size: 10px;
  }
}


@media (max-width: 768px) {
  .personal_img_text {
    font-size: 12px;
    margin: 0 9px;
  }

  .perosnal_text_img {
    font-size: 14px;
  }

  .course_dimage {
    width: 70px;
    height: 70px;
  }

  .course_browse {
    width: 30px;
    height: 30px;
    margin-left: 40px;
    margin-top: -20px;
  }
}

.py-30 {
  padding: 30px 0 !important;
}