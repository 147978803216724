.tajah-trainee-lesson-training_first_label {
  display: flex;
  font-size: 16px;
  padding: 30px 15px 15px 15px;
}

.zoom_trainee {
  display: flex;
  margin-bottom: 20px;
}

.zoom_trainee .zoom_text {
  flex: 1;
  text-align: left;
}

body[dir="rtl"] .zoom_trainee .zoom_text {
  text-align: right;
}

.zoom_trainee .zoom_btn {
  flex: 1 1 0%;
  justify-content: flex-end;
  display: flex;
}

.zoom_trainee .session_link_zoom {
  background: #126e77;
  color: #fff !important;
  padding: 6px 20px;
  border-radius: 8px;
  display: block;
  width: 215px;
  text-align: center;
}

.tajah_lesson_content_file_text {
  cursor: pointer;
  transition: color 0.25s;
}

.tajah_lesson_content_file_text:hover {
  color: blue;
}

.tajah-trainee-lesson-profile_dname {
  margin: 0 20px;
}

.tajah-trainee-lesson-profile_grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 50px;
  margin: 20px 0;
  padding-top: 50px;
}

.tajah-trainee-lesson-header_profile {
  padding: 50px;
  background: #011e20;
  padding: 15px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
}

.tajah-trainee-lesson-profile_dtop {
  font-size: 25px;
  text-align: initial;
}

.tajah-trainee-lesson-profile_dname {
  margin: 0 20px;
}

.tajah-trainee-lesson-profile_dtop {
  font-size: 25px;
  text-align: initial;
}
@media only screen and (max-width: 768px) {
  .lesson-wrapper {
    flex-direction: column;
  }

  .lesson-wrapper > div {
    flex: 1 1 100% !important;
    width: 100% !important;
  }
}
