.exhibition-details__audios-item {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 50%;
}

.exhibition-details__audios-item-title {
  font-size: 16px;
  color: #777777;
}
.downloadFile {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
}

.file-download {
  height: 100px;
  width: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.downloadicon {
  height: 100px;
  width: 100px;
}
