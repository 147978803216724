.my25 {
  margin: 25px 0;
}

.flexheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn {
  width: 100%;
  justify-content: center;
  margin-top: 35px;
}

@media (max-width: 767px) {
  .flexheader {
    flex-direction: column;
    gap: 15px;
  }
}
.search_container {
  display: flex;
  gap: 20px;
  flex: 3 1 auto;
  padding: 0 10px;
}
.title_search {
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.title {
  font-size: 22px;
  font-weight: 600;
  color: #000000;
}
.search-box {
  display: flex;
  gap: 20px;
  width: 100%;
  background-color: #ffffff;
  padding: 10px 45px;
  border-radius: 10px;
  box-shadow: 2px 2px 10px #ddd;
}
.searchbtn {
  width: 120px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
}
.courses_card_grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}
.loading__container {
  padding: 20px 0;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 991px) {
  .courses_card_grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 50px;
    gap: 5px;
  }
}
@media (max-width: 767px) {
  .courses_card_grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 50px;
    gap: 5px;
  }
  .title_search {
    flex-direction: column;
    gap: 20px;
  }
}
