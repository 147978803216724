.profile-container {
  width: 100%;
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: 2.5rem;
}
.titleCont {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.profile-item-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  /* padding: 1rem; */
  background-color: #fff;
  height: 220px;
  width: 220px;
  cursor: pointer;
  color: #555;
  border: 1px solid #025299;
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
}

.profile-item-box:hover {
  background-color: #025299;
  color: #fff;
}

.profile-icon img {
  width: 4.5rem !important;
  height: 4.5rem !important;
}

.profile-icon img {
  width: 100%;
  height: 100%;
}

.profile-name {
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  display: flex;
  gap: 0.5rem;
  /* justify-content: center; */
}

.imgCont img {
  width: 1rem;
  height: 1rem;
}

body[dir="rtl"] .imgCont img {
  transform: rotate(180deg);
}

.profile-description {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  font-size: 12px;
  /* margin-top: 0.7rem; */
}
.imgCont {
  background-color: #d4ebff;
  border-radius: 6px;
  padding: 5px;
}
.title {
  font-size: 22px;
  font-weight: 600;
  color: #000000;
}
.main-box {
  padding: 0 4rem;
  margin: 40px 0;
}
@media (max-width: 768px) {
  .main-box {
    padding: 1rem;
  }
}
