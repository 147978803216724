.titleAddFile {
  color: #046C77;
  font-size: 24px;
  margin-bottom: 0;
}

.admin_label {
  font: 18px;
  display: flex;
  margin: 30px 0;
}

.table_bank_tran {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 97%;
  margin: 0 auto;
  margin-top: 40px;
  padding: 50px 0;
  border-radius: 10px;
  border-width: 1px;
  background-color: #fff;
  box-shadow: 2px 2px 10px #ddd;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.table_bank_tran .table {
  width: 100%;
}

.table_bank_tran td,
.table_bank_tran th {
  text-align: center;
}

.table_bank_tran i {
  color: #006d77;
  font-size: 13px;
  cursor: pointer;
}

.table_bank_tran button i {
  font-size: 17px;
  color: #fff;
  padding: 0px 5px;
}

.table_bank_tran button {
  display: flex;
  /*width: 100%;*/
  /*text-align: center;*/
  /*margin: 0 auto;*/
  justify-content: center;
  align-items: center;
}

.admin_label_card {
  display: flex;
  width: 90% !important;
  margin-bottom: 30px;
  justify-content: space-between;
  align-items: center;
}

.release_icon {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-evenly;
}

.release_icon .fa-trash-alt {
  color: #d20101;
}

.font_awesome_edit {
  font-size: 20px !important;
  color: #000;
}

@media (max-width: 991px) {
  .admin_label {
    font-size: 16px !important;
  }

  .admin_label_card {
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .admin_label {
    font-size: 14px !important;
  }
}
