.breadcrumb {
  display: flex;
  align-items: center;
  gap: 1rem;
  list-style: none;
  padding: 0;
  margin: 0;
}

.breadcrumb__item {
  position: relative;
  display: flex;
  align-items: center;
}

.breadcrumb__item a {
  font-size: 16px;
  color: #7a7a7a;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-inline-end: 20px;
}

.breadcrumb__item a:hover {
  color: #7a7a7a;
}

.breadcrumb__item.active {
  color: #025299;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.breadcrumb__item-separator {
  width: 1px;
  height: 1rem;
  background-color: #7a7a7a;
  transform: rotate(20deg);
}

@media only screen and (max-width: 768px) {
  .breadcrumb {
    overflow-x: auto;

    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .breadcrumb::-webkit-scrollbar {
    display: none;
  }

  .breadcrumb__item {
    white-space: nowrap;
  }
}

@media only screen and (max-width: 480px) {
  .breadcrumb {
    gap: 10px;
  }
  .breadcrumb__item a {
    font-size: 14px;
    margin-inline-end: 10px;
  }
  .breadcrumb__item.active {
    font-size: 14px;
  }
  .breadcrumb__item img {
    width: 1.2rem;
  }
}
