
  .description{
    overflow: auto;
    resize: none;
    width: 100%;
    height: 100px;
    border: 1px solid #e7e7e7;
    background: transparent;
    padding: 10px;
    outline: none;
  }


  .rateStyle{
    transform: rotate(180deg) !important;
  }