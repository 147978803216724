.top-label {
  margin-top: 100px;
  font-size: 30px;
}

.hint-label {
  font-size: 14px;
}

.subject-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 50px;
  margin-bottom: 20px;
}

.subject-card {
  width: 60%;
  background-color: #fff;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  height: 100%;
  padding: 20px 50px;
}

.subject-form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.subject-input-label {
  font-size: 14px;
  margin: 0 10px;
}

.subject-input {
  display: flex;
  background-color: #fff;
  color: #000;
  backdrop-filter: blur(100px);
  position: relative;
  border: none;
  border-bottom: 1px solid #ccc;
  height: 50px;
  width: calc(100% - 20px);
  margin: 0 10px;
}

.form-input-error-space {
  height: 18px;
  font-size: 12px;
  color: red;
}

.subject-btn {
  display: flex;
  align-items: center;
}

.subject-button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 130px;
  padding: 10px;
  background-color: #006d77;
  border-radius: 15px;
  color: #fff;
  font-size: 16px;
  border: none;
  outline: none;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  cursor: pointer;
}

.subject-button:disabled {
  background-color: rgba(0, 53, 58, 0.6);
}

.card-info-email-name {
  width: 100%;
  margin-bottom: 30px;
  position: relative;
}

.add-new-user {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 130px;
  padding: 5px;
  background-color: #006d77;
  border-radius: 5px;
  color: #fff;
  font-size: 13px;
  border: none;
  outline: none;
  margin-top: -10px;
  cursor: pointer;
}

.add-file-type:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .top-label {
    font-size: 18px;
  }

  .hint-label {
    font-size: 10px;
  }

  .subject-container {
    flex-direction: column-reverse;
  }

  .subject-card {
    width: 100%;
  }

  .subject-card-info__fields {
    flex-direction: column;
  }
  .subject-card-info__fields > div {
    width: 100% !important;
  }
  .subject-card {
    padding: 20px !important;
  }
}
