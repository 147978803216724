.head_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;
}

.degree_grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
}

.degree_card {
  width: 100%;
  height: 520px;
  margin: 10px auto;
  font-size: 18px;
  box-shadow: 0px 0px 20px #ccc;
}

.degree_card_first {
  background-image: url("../../../../assets/image/ce.png");
  background-color: #ccc;
  object-fit: cover;
  background-position: center;
  background-size: cover;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: #fff;
}

.degree_card_second {
  display: flex;
  justify-content: space-between;
  padding: 0 25px;
  align-items: center;
  height: 70px;
  background-color: #f6f6f6;
  border-bottom: 1px solid #ccc;
  font-size: 16px;
}

.courses_card_category {
  font-size: 16px;
}

.courses_card_profile {
  display: flex;
  align-items: center;
}

.courses_card_icon {
  background-image: url("../../../../assets/image/smallDoc.png");
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 10px;
  object-fit: cover;
  background-position: center;
  background-size: cover;
}

.courses_card_name {
  display: flex;
  align-items: center;
}
.courses_card_second_std {
  display: flex;
  justify-content: center;
  padding: 0 25px;
  align-items: center;
  height: 60px;
  background-color: #fff;
  font-size: 16px;
}

.courses_card_point {
  display: flex;
  align-items: center;
  margin: 0 10px;
}

.courses_card_button {
  margin: 0 15px;
  background-color: #fff;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
}

.courses_card_button_in {
  outline: none;
  border: 2px solid #ccc;
  background-color: #fff;
  border-radius: 10px;
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.courses_card_button_in:hover {
  background-color: #006d77;
  color: #fff;
}
@media (max-width: 1199px) {
  .head_lable {
    font-size: 18px;
  }

  .courses_card_category {
    font-size: 14px;
  }

  .courses_card_button {
    height: 50px;
  }
}

@media (max-width: 991px) {
  .head_lable {
    font-size: 16px;
  }
  .degree_grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .courses_card_category {
    font-size: 12px;
  }

  .courses_card_button {
    height: 40px;
  }
}

@media (max-width: 767px) {
  .courses_card_button {
    height: 40px;
  }
}
