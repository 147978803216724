.courses_card {
    width: 100%;
    height: 520px;
    margin: 10px auto;
    font-size: 18px;
    box-shadow: 0px 0px 20px #ccc;
}

.skeleton-box {
    display: flex;
    height: 1em;
    position: relative;
    overflow: hidden;
    background-color: #e2e2e2;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.skeleton-box::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
            to right,
            #e2e2e2 30%,
            #eff1f3 60%,
            #e2e2e2 100%
    );
    animation: shimmer 2s infinite;
    content: "";
}

@media (max-width: 991px) {
    .courses_card {
        height: 500px;
    }
}

@media (max-width: 767px) {
    .courses_card {
        height: 500px;
    }
}
