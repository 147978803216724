.checkbox-field {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.checkbox-field input {
  cursor: pointer;
  width: auto;
}
.checkbox-field label {
  margin: 0;
  color: #777777;
  cursor: pointer;
}

.checkbox-field-error {
  margin: 0 !important;
  padding: 0 !important;
  height: 10px !important;
  font-size: 12px;
  color: red;
}
