.head_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;
}

.head_lable {
  font-size: 22px;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.table-responsive > .table-bordered {
  border: 0;
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}

.table th,
.table td {
  padding: 0.75rem;
  /* vertical-align: top; //////// BUG: remove it from all project it's effect on table  */
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table .table {
  background-color: #fff;
}

.no-record {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
}

@media (min-width: 576px) {
  .table {
    border-collapse: collapse !important;
  }
  /* .table td,
  .table th {
    background-color: #fff !important;
  } */
}

@media (max-width: 1199px) {
  .head_lable {
    font-size: 18px;
  }
}

@media (max-width: 991px) {
  .head_lable {
    font-size: 16px;
  }
}
