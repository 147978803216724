.manage-departments__form__field-actions {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 1rem;
}

.manage-departments__form__field-actions button {
	background-color: #046c77;
	border-radius: 6px;
	border: none;
	padding: 0 1rem;
	height: 50px;
	color: #ffffff;
	display: flex;
	align-items: center;
	justify-content: center;
}

.manage-departments__form__field-actions-submit {
	width: 250px;
}
.manage-departments__form__field-actions-submit[disabled] {
	opacity: 0.5;
	cursor: not-allowed;
}
.manage-departments__form__field-actions-view {
	width: 50px;
}

@media only screen and (max-width: 480px) {
	.manage-departments__form__field-actions-submit {
		width: 100%;
	}
}
