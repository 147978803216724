.head_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 0;
  }
  
  .head_lable {
    font-size: 22px;
  }
  
  .programs_card_grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 50px;
  }
  
  .no-record {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 50px;
  }
  
  .programs_card_std {
    width: 100%;
    height: 400px;
    margin: 10px auto;
    font-size: 18px;
    box-shadow: 0px 0px 20px #ccc;
  }

  .programs_link{
    margin-top: 30px;
    margin-bottom: -30px;
 }
  
  .programs_card_first_std {
    background-color: #ccc;
    object-fit: cover;
    background-position: top;
    background-size: cover;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    color: #fff;
  }
  
  .programs_name {
    width: 100%;
    background-color: rgba(3, 41, 43, 0.9);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100px;
    transition: all 0.5s;
    padding-bottom: 20px;
    padding: 20px 10px;
    text-align: initial;
  }
  
  .programs_name:hover {
    height: 100%;
    background-size: 100px;
    background-position: 10px 10px;
    background-repeat: no-repeat;
    width: 100%;
    float: right !important;
  }
  
  .programs_name:hover > .logo_hover {
    display: flex;
    width: 100%;
    margin-top: -70px;
    margin-bottom: 70px;
    justify-content: flex-start;
  }
  
  .logo_hover {
    display: none;
  }
  
  .logo_hover_width {
    width: 100px;
  }
  
  .programs_card_second_std {
    display: flex;
    justify-content: center;
    padding: 0 25px;
    align-items: center;
    height: 60px;
    background-color: #fff;
    font-size: 16px;
  }
  
  .programs_card_point {
    display: flex;
    align-items: center;
    margin: 0 10px;
  }
  
  .progress {
    display: flex;
    width: 90%;
    margin: 10px auto;
    height: 4px;
  }
  
  .training_head .progress {
    margin: 0 !important;
  }
  
  .progress-bar {
    background-color: #18b1b9;
  }
  
  .programs_card_button_in {
    outline: none;
    border: 2px solid #ccc;
    background-color: #fff;
    border-radius: 10px;
    width: 100%;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .programs_card_button_in:hover {
    background-color: #006d77;
    color: #fff;
  }
  
  .programs_card_button {
    margin: 0 15px;
    background-color: #fff;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
  }
  
  @media (max-width: 1199px) {
    .head_lable {
      font-size: 18px;
    }
  
    .programs_card_button {
      height: 50px;
    }
  }
  
  @media (max-width: 991px) {
    .head_lable {
      font-size: 16px;
    }
  
    .programs_card_grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 50px;
      gap: 50px;
    }
    .programs_card_button {
      height: 40px;
    }
  }
  
  @media (max-width: 767px) {
    .programs_card_grid {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 50px;
      gap: 50px;
    }
  
    .programs_card_button {
      height: 40px;
    }
  }
  