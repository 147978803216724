.publishContainer {
  margin: 20px;
  padding-top: 50px;
}

.publishContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  background-image: url("../../../assets/image/newContainerBackground.png");
  background-color: #29415e;
  background-size: cover;
  padding: 50px;
  border-radius: 15px;
}

.publishText {
  color: #fff;
}

.publishText p {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 10px;
}

.publishText span {
  font-size: 1rem;
  line-height: 1.6;
}

.publishButton {
  white-space: nowrap;
  border: 2px solid #fff !important;
  color: #fff;
  padding: 15px 30px;
  font-size: 1rem;
  border-radius: 15px;
}

@media (max-width: 768px) {
  .publishContent {
    flex-direction: column;
    align-items: start;
    padding: 20px;
  }
}
