.bank-transfer-card {
  width: 70%;
  padding: 20px 0;
}

.bank-transfer-head {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  margin-right: 5%;
}

.back-transfer-icon {
  font-size: 40px;
  color: #006d77;
}

.bank-transfer-label {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

body[dir="rtl"] .bank-transfer-label {
  margin-left: 0;
  margin-right: 10px;
}

.bank-transfer-label-small {
  margin-top: 10px;
  font-size: 14px;
  color: #474747;
  width: 70%;
}

.bank-transfer-name {
  margin-top: 20px;
  width: 90%;
  border-radius: 10px;
  border: 1px solid #c9c9c9;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.bank-transfer-form-input {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  flex-direction: row;
}

.bank-transfer-width-input {
  width: 90%;
  display: flex;
  flex-direction: row;
}

.bank-transfer-flex {
  flex: 1;
}

.bank-transfer-label-input {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.bank-transfer-red-star {
  color: red;
  font-size: 20px;
  margin: 0 5px;
}

.bank-transfer-input {
  border: none;
  border-bottom: 1px solid #c9c9c9;
  padding-bottom: 10px;
  font-size: 13px;
  width: 90%;
}

.bank-transfer-card-width {
  width: 90%;
}

.bank-transfer-card-upload {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.bank-transfer-flex-upload {
  flex: 1;
  height: 80px;
}

.bank-transfer-upload {
  height: 100%;
  width: 100%;
  border-radius: 10px;
  border-style: dashed;
  border-width: 1px;
  border-color: #8f8f8f;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.bank-transfer-upload-img {
  width: auto;
  max-height: 67px;
  max-width: 100%;
}

.logo-tebx {
  width: 97px;
}

.bank-trasnfer-upload-small-img {
  cursor: pointer;
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  background-color: #18b1b9;
  top: 84%;
  left: 4%;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
}

.bank-transfer-small-image {
  font-size: 13px;
  color: #fff;
}

.bank-transfer-upload-label {
  flex: 3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bank-transfer-btn {
  width: 170px;
  margin: 10px;
  padding: 10px;
  font-size: 14px;
  background-color: #006d77;
  color: #fff;
  border-radius: 15px;
  outline: none;
  box-shadow: none;
  overflow: hidden;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bank-transfer-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
