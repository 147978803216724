.add_lesson_file_modal {
  width: 100%;
  max-width: 700px;
  max-height: 700px;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow-y: scroll;
}

.add_lesson_file_modal_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.add_lesson_file_modal_title {
  font-size: 20px;
  font-weight: 600;
}

.add_lesson_file_modal_close {
  cursor: pointer;
}

.add_lesson_file_modal_form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 90%;
}

.add_lesson_file_modal_form_fields {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
}

.add_lesson_file_actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 30px;
}

.attachment_panel {
  display: flex;
  flex-direction: row;
}

.attachment_panel_start {
  flex: 5;
}

.attachment_panel_end {
  flex: 4;
  display: flex;
  align-items: flex-end;
  width: 100%;
  flex-direction: column;
}

.attachment_panel_input_label {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.attachment_panel_asterisk_input {
  color: red;
  fontsize: 20;
  margin-left: 5px;
}

body[dir="rtl"] .attachment_panel_asterisk_input {
  margin-left: 0;
  margin-right: 5px;
}

.attachment_panel_input {
  border: none;
  border-bottom-color: #c9c9c9;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  padding-bottom: 10px;
  font-size: 13px;
  width: 100%;
}

.attachment_panel_input_error {
  color: red;
  font-size: 12px;
}

.attachment_panel_input_holder_margin {
  margin-top: 20px;
}

.attachment_panel_submission_button {
  width: 130px;
  height: 30px;
  border-radius: 10px;
  background-color: #006d77;
  color: #fff;
  font-size: 13px;
}

.attachment_panel_submission_button_skeleton {
  width: 100%;
  height: 100%;
  margin: 0;
  background-color: #006d77;
}

.attachment_panel_file_drop_canvas {
  position: relative;
  width: 70%;
  height: 100%;
  border-style: dashed;
  border-width: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fbfbfb;
  cursor: pointer;
  padding-left: 5px;
  padding-right: 5px;
}

.attachment_panel_file_drop_hint_message {
  color: black;
  font-size: 14px;
}

.attachment_panel_file_drop_hint_message_file_graped {
  display: none;
  color: black;
  font-size: 14px;
}

.attachment-panel-dragging-activation .attachment_panel_file_drop_hint_message {
  display: none;
}

.attachment-panel-dragging-activation
  .attachment_panel_file_drop_hint_message_file_graped {
  display: block !important;
}

.attachment-panel-dragging-activation::after {
  content: "";
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
}

.attachment_panel_file_drop_file_holder {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.attachment_panel_file_drop_file_name {
  font-size: 14px;
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
}

.skeleton-box {
  display: flex;
  height: 1em;
  position: relative;
  overflow: hidden;
  background-color: #e2e2e2;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.skeleton-box::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    to right,
    #e2e2e2 30%,
    #eff1f3 60%,
    #e2e2e2 100%
  );
  animation: shimmer 2s infinite;
  content: "";
}

@media only screen and (max-width: 992px) {
  .attachment_panel {
    flex-direction: column;
  }
  .attachment_panel_end {
    order: -1;
    margin-bottom: 20px;
  }
  .attachment_panel_file_drop_canvas {
    width: 100%;
    height: 100px;
  }
}
