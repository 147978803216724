.select-field {
  width: 100%;
}

.select-field select {
  width: 100%;
  height: 50px;
  padding: 0 1rem;
  border: 1px solid #ebebeb;
  background-color: #ffffff;
  border-radius: 6px;
  color: #777777;
}

.select-field label {
  color: #777;
  font-weight: 400;
  /* line-height: 45px; */
}

.select-field-error {
  margin: 0 !important;
  padding: 0 !important;
  height: 10px !important;
}
