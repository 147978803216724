.departments-list {
	padding: 2rem 0;
}

.departments-list__container {
	display: flex;
	flex-direction: column;
	gap: 2rem;
}


.departments-list__table-name,
.departments-list__table-desc {
  width: 300px;
  white-space: pre-wrap;
}
