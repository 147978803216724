.icon-text {
  display: flex;
  align-items: center;
}

.text-size {
  font-size: 10px;
  padding: 2px;
}

.icon-w {
  width: 10px;
}
