.already-verified {
  margin-top: 35px;
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.already-verified h1 {
  font-size: 2rem;
  font-weight: 500;
  color: #777777;
}

.already-verified p {
  font-size: 1rem;
  margin-top: 1rem;
  font-weight: 400;
  color: #777777;
}

.already-verified button {
  margin-top: 2rem;
  width: 100%;
  max-width: 300px;
  height: 50px;
  background-color: #26b3b9;
  border-radius: 6px;
  border: none;
  color: #ffffff;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
}
