.training-course-syllabus-section-container {
    padding: 20px;
    width: 100%;
    height: 100%;
}

.training-course-syllabus-section-container-border {
    background: white;
    width: 100%;
    height: 100%;
    box-shadow: 2px 3px 6px #cfcfcf;
    border-radius: 8px;
    transition: opacity 0.5s;
}
