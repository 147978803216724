@media only screen and (max-width: 768px) {
  .tajah-form-row-qa {
    flex-direction: column;
    gap: 10px;
  }

  .tajah-form-row-qa .tajah-form-row-element-holder {
    width: 100%;
  }

  .MuiBox-root {
    padding: 40px 60px;
  }
}

@media only screen and (max-width: 480px) {
  .MuiBox-root {
    padding: 5px;
    width: 100%;
  }
}
