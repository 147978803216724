.tax-list {
  padding: 2rem 0;
}

.tax-list__container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.tax-list__table-name {
  width: 150px;
  white-space: pre-wrap;
}

.statics_container {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 1rem;
}

@media (min-width: 640px) {
  .statics_container {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (min-width: 1024px) {
  .statics_container {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
