.admin_img {
  width: 40px;
  height: 40px;
  border-radius: 6px;
  margin: 0 5px;
  -webkit-border-radius: 6px;
}
.new_header_qassem {
  display: flex;
  align-items: center;
}
.profile_name_qassem {
  margin-right: 20px;
  margin-left: 5px;
}
body[dir="rtl"] .profile_name_qassem {
  margin-left: 20px;
  margin-right: 5px;
}
.notification.dropdown-toggle::after {
  display: none;
}
.lang_btn_qassem {
  display: flex;
  align-items: center;
  border-radius: 6px;
  padding: 10px 10px;
  /* background-color: #03a2b2; */
  /* color: #fff; */
}
@media (max-width: 768px) {
  .lang_style {
    margin-left: 0px;
    margin-right: 10px;
  }

  .lang_btn_qassem {
    display: flex;
    align-items: center;
    border-radius: 6px;
    padding: 10px 2px;
    /* background-color: #03a2b2; */
    /* color: #fff; */
  }
}
.lang_name {
  color: #025299;
}
@media (max-width: 468px) {
  .lang_name {
    display: none;
  }
}
.header-dropdown.notification {
  font-size: 25px;
}
.notification-recently {
  position: absolute;
  width: 15px;
  height: 15px;
  background: #cc0000;
  border-radius: 50%;
  right: 7px;
  border: 2px solid #fff;
  top: 16px;
}
.dropdown-menu.notifications-box {
  position: absolute;

  z-index: 1000;
  min-width: 31.5rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: inherit;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  padding: 10px 0;
  border: none;
  box-shadow: 0px 1px 5px #d0d0d0;
  padding-bottom: 0;
  max-height: 400px;
  right: 0px;
  left: unset;
}
body[dir="rtl"] .dropdown-menu.notifications-box {
  left: 10px;
  right: unset;
}
.notifications-box.scroll-y {
  overflow-y: scroll;
}
.notifications-box h4 {
  padding: 0px 20px 10px 20px;
}
.notifications-box .box-notify {
  padding: 10px 20px;
  border-bottom: 1px solid #ddd;
}
.notifications-box .box-notify.new,
.notifications-box .box-notify.new:hover {
  background-color: rgb(0 255 255 / 38%);
}
.notifications-box .box-notify:hover {
  background-color: #f2f2f2;
}
.notifications-box .type-time {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}

@media only screen and (max-width: 767px) {
  .admin_img {
    width: 30px;
    height: 30px;
  }

  .dropdown-menu.notifications-box {
    position: fixed;
    left: 15px !important;
    right: 15px !important;
    top: 0;
  }
  body[dir="ltr"] .dropdown-menu {
    left: unset !important;
    right: 0 !important;
  }
}
