.container-fluid {
  max-width: 1280px;
  padding: 0 20px;
}

.auth_btn {
  width: 100%;
  border-radius: 20px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  color: #fff;
  background-color: #515f60;
}

@media (max-width: 768px) {
  .container-fluid {
    padding: 0 15px;
  }
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.training-course-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0;
}

.training-course-header-container .training-course-header-title {
  font-size: 30px;
  font-weight: 700;
  color: #24b3b9;
}

.training-course-header-container .training-course-header-date {
  font-size: 16px;
  font-weight: 700;
  color: #777;
}
.training-course-syllabus-section-container {
  padding: 20px;
  width: 100%;
  height: 100%;
}

.new_chapter {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 20px #ccc;
  background-color: #fff;
  border-radius: 10px;
  padding: 50px;
  margin: 0 0 20px;
}

.header_new_chapter {
  font-size: 30px;
  opacity: 47%;
}

@media (max-width: 1199px) {
  .header_new_chapter {
    font-size: 20px;
  }
  .box_new_chapter {
    width: 100px;
  }
}

@media (max-width: 991px) {
  .header_new_chapter {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .header_new_chapter {
    font-size: 14px;
  }
}

.box_new_chapter {
  width: 150px;
  margin: 30px 0;
}

.training-course-syllabus-section-container-border {
  background: white;
  width: 100%;
  height: 100%;
  box-shadow: 2px 3px 6px #cfcfcf;
  border-radius: 8px;
  transition: opacity 0.5s;
  padding: 20px;
}
/* FAQ */
.FAQ-from {
  margin-bottom: 25px;
}

.FAQ-from select {
  height: 44px;
}
.FAQ-from input[type="text"] {
  border: none;
  background: #dddddd;
  height: 34px;
  border-radius: 10px;
  padding: 0 10px;
}
.FAQ-from input[type="text"]::after {
  content: "\f002";
}
.FAQ-from form {
  padding-top: 10px;
}
.FAQ-from input[type="submit"] {
  padding: 5px 35px;
  color: #fff;
  background: #006d77;
  border: none;
  margin: 0 12px;
  border-radius: 10px;
  cursor: pointer;
}
.box_faq {
  display: block;
  width: 100%;
  padding: 30px 25px;
  padding-top: 0;
  max-width: 100%;
  word-break: break-word;
}
.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: transparent;
  border-bottom: none;
}
.FAQ-box .btn.btn-link::after {
  content: "\f078";
  font-family: "FontAwesome";
  font-size: 11px;
  position: relative !important;
  right: 0;
  margin-top: 9px;
  padding: 0 9px;
}
body[dir="rtl"] .btn.btn-link::after {
  left: 0;
  right: unset;
}
.box_faq .card-header .btn.btn-link {
  font-size: 16px;
  width: 100%;
  justify-content: flex-start;
  color: #333;
  padding-left: 0;
  padding-right: 0;
}
.box_faq .card-header .btn.btn-link:hover {
  text-decoration: none;
}
.box_faq .card {
  border: none;
  border-bottom: 1px solid #d3d2d2;
  border-radius: 0;
  padding-bottom: 15px;
  flex-direction: column;
}
.box_faq .card-header {
  padding-left: 0;
  padding-right: 0;
  position: relative;
}
.box_faq .card-body {
  font-size: 14px;
  color: #727272;
}
.table_bank_tran.faq_box_white {
  width: 100% !important;
  box-shadow: none;
}
.btn_add_question,
.btn_add_question:hover {
  display: flex;
  position: absolute;
  right: 15px;
  padding: 5px 35px;
  color: #fff !important;
  background: #006d77;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 15px;
  cursor: pointer;
  position: absolute;
  top: 0;
}
body[dir="rtl"] .btn_add_question {
  right: unset;
  left: 15px;
}

.btn_add_question,
.btn_add_question:hover {
  display: flex;
  position: absolute;
  right: 15px;
  padding: 5px 35px;
  color: #fff !important;
  background: #006d77;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 15px;
  cursor: pointer;
  top: 10px;
}
body[dir="rtl"] .btn_add_question {
  right: unset;
  left: 15px;
}
.btn_edit,
.btn_edit:hover {
  top: 22px;
  font-size: 13px;
  padding: 2px 15px;
}
.btn_add_question.btn_trush {
  background-color: transparent;
  right: 75px;
  top: 20px;
  cursor: pointer;
}
.btn_add_question.btn_trush i {
  color: #b92727;
  font-size: 12px;
  padding: 4px;
  background: #e3e3e3;
  border-radius: 5px;
}
body[dir="rtl"] .btn_add_question.btn_trush {
  right: unset;
  left: 75px;
}
.btn_edit i {
  color: #fff;
  padding: 0 5px;
  line-height: 2;
  font-size: 10px;
}
.FAQ-from .container-fluid.profile_wrapper {
  padding: 0;
}

.course-add-question-btn {
  width: 50%;
  padding: 7px 12px;
  display: inline-block;
  cursor: pointer;
}
.course-add-question-btn:hover {
  color: #ffffff !important;
}
@media (max-width: 767px) {
  .btn_add_question.btn_edit,
  .btn_add_question.btn_trush {
    position: relative;
    display: inline-block;
    top: 0;
    left: unset !important;
    right: unset !important;
    padding: 2px 14px;
  }
  .box_faq .card-header .btn.btn-link {
    white-space: normal;
  }
  .box_faq {
    padding: 30px 0px;
    max-width: 100%;
    word-break: break-word;
  }
  .FAQ-box .container-fluid.profile_wrapper {
    padding: 0;
  }
  .FAQ-from form {
    padding-top: 56px;
  }
  .FAQ-from input[type="text"] {
    margin-bottom: 10px;
  }
  .table_header {
    padding: 0;
  }
  .course-add-question-btn {
    width: 100% !important;
  }

  .training-course-header-container {
    flex-direction: column;
    gap: 1rem;
  }
}

.course-details-form {
  border: 1px solid #cfcfcf;
  border-radius: 6px;
  padding: 1rem 2rem;
}

.course-details-actions {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
}
