.headerContainer {
  width: 100%;
  /* background: #03a2b2; */
  background-image: url("../../../assets/image/newContainerBackground.png");
  background-size: cover;
  background-color: #29415e;
  background-position: center;
  display: flex;
  padding: 40px;
  padding-bottom: 100px;
  border-radius: 0 0 40px 40px;
  /* position: relative; */
}
.headerContent {
  display: flex;
  gap: 20px;
  width: 100%;
  padding: 50px 90px;
}
.title {
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  gap: 10px;
}
.back-share {
  display: flex;
  justify-content: end;
  gap: 5px;
}
.shareBackBtn {
  padding: 15px;
  border-radius: 50%;
  border: 1px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.arrowIcon {
  width: 20px;
  height: 20px;
  transform: rotateY(180deg);
}
@media (max-width: 1279px) {
  .type {
    width: 100%;
    min-width: fit-content;
  }

  .type-container {
    bottom: 90px;
  }

  .headerContainer {
    padding: 20px;
    padding-bottom: 80px;
  }

  .title {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (max-width: 991px) {
  .headerContent {
    flex-direction: column;
    align-items: unset;
    justify-content: unset;
  }
  .headerContainer {
    background-image: url("../../../assets/image/newContainerBackground.png");
    background-size: cover;
    background-color: #29415e;

    background-position: center;
    padding-top: 20px;
    margin-bottom: 20px;
    flex-direction: column;
    gap: 10px;
    border-radius: 0 0 30px 30px;
  }

  .title {
    justify-content: unset;
  }
}
