.main-table {
  overflow-x: auto;
}

.sort-icon {
  margin: 0 0.5rem;
  display: inline;
}

.main-table thead th {
  cursor: pointer;
  border: none;
}

.main-table table thead,
.main-table thead th {
  /* background: none !important; */
  color: #9b9a9a;
  font-weight: 600;
  /* border: none !important; */
  text-align: center;
}

.main-table {
  min-height: auto;
  margin: 0 4px;
}
.main-table_row {
  border: none !important;
  /* border-right: 5px solid #fff !important; */
  font-size: 14px;
}
/* .main-table_row:hover {
  background-color: rgb(39, 149, 233, 20%) !important;
  border-right: 5px solid #2795e9 !important;
} */
/* .main-table tr:hover{
	background-color: rgb(39, 149, 233, 30%) !important;
  border-right: 5px solid #2795e9 !important;
} */

.main-table tbody td {
  text-align: center;
  white-space: nowrap;
  color: #000;
  border: none;
}

.main-table td,
.main-table th {
  border: none;
}

.loading__container {
  padding: 20px 0;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.not-data-container {
  padding: 20px 0;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.main-table__column--start {
  text-align: start !important;
}

.photo_inside_table {
  width: 70px;
  border-radius: 50%;
  height: 70px;
  object-fit: cover;
}
