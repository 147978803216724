.modal_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.modal_header_row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 10px;
}

.modal_header_row h3 {
  text-align: center;
  font-family: dinNextRegular, sans-serif;
  letter-spacing: 0;
  color: #046c77;
  opacity: 1;
}

.modal_close_icon {
  width: 45px;
  height: 45px;
  cursor: pointer;
}

.customButton {
  width: 150px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #025299 !important;
  border-radius: 10px !important;
  opacity: 1;
  font-size: 18px !important;
  font-family: "dinNextRegular" !important;
}

.done_btn_container {
  display: flex;
  justify-content: flex-end;
  font-family: dinNextRegular, sans-serif;
  align-items: center;
  width: 100%;
  padding-top: 10px;
}

.d_flex_input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin: 12px 0; */
  margin-bottom: 20px;
}

.modalContainer {
  display: block;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  /* overflow-y: auto; */
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  padding: 16px;
}

.modalContainer::-webkit-scrollbar {
  width: 10px;
}

.modalContainer::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.modalContainer::-webkit-scrollbar-thumb {
  background: #dadada;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .modalContainer {
    width: 90%;
  }
}

@media (max-width: 576px) {
  .modalContainer {
    width: 100%;
  }
}
.select-field {
  width: 100%;
}

.select-field select {
  width: 100%;
  height: 50px;
  padding: 0 1rem;
  border: 1px solid #ebebeb;
  background-color: #ffffff;
  border-radius: 6px;
  color: #777777;
}

.select-field label {
  color: #777;
  font-weight: 400;
  /* line-height: 45px; */
}

.select-field-error {
  margin: 0 !important;
  padding: 0 !important;
  height: 10px !important;
}
