/* sidebar */

body[dir="rtl"] .sidebar {
  right: 0;
  animation: open 0.3s normal linear;
}

.sidebar {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: 0;
  /* height: calc(100% - 111px); */
  background-color: #fff;
  width: 400px;
  /* box-shadow: -2px 1px 20px 0px #1b1b1b66; */
  z-index: 3000;
  animation: ltr 0.3s normal linear;
  -webkit-animation: ltr 0.3s normal linear;
  overflow-y: scroll;
  height: 100%;
  padding-bottom: 70px;
  transition: all 0.3s ease-in-out;
}

body::-webkit-scrollbar {
  width: 10px;
}

.inner-scrollbar::-webkit-scrollbar-track-piece {
  background: rgba(3, 22, 23, 0.85);
}

.inner-scrollbar::-webkit-scrollbar-thumb {
  background: #025299;
}

.inner-scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.inner-scrollbar::-webkit-scrollbar-track-piece {
  background: rgba(3, 22, 23, 0.85);
}

.inner-scrollbar::-webkit-scrollbar-thumb {
  background: rgba(212, 212, 212, 0.85);
  border-radius: 2rem;
}

.inner-scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.inner-scrollbar::-webkit-scrollbar-track-piece {
  background: #ffffff;
  border: 1px solid rgba(222, 222, 222, 0.85);
  border-radius: 2rem;
}

.inner-scrollbar::-webkit-scrollbar-thumb {
  background: #025299;
}

/* 
.scroll::-webkit-scrollbar {
  width: 5px;
}

.scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

.scroll::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px red;
} */

.logo-sidebar {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 25px 14px;
}

.logo-sidebar a {
  width: 80%;
}

.logo-sidebar img {
  width: 100%;
}

.logo-sidebar svg {
  font-size: 28px;
}
.icon-sidebar_qassim {
  margin-inline-end: 10px;
}

.sidebar-header {
  display: flex;
  align-items: center;
  padding: 35px 50px;
  background-color: #025299;
  background-image: url("../../../../../assets/icons/Waves.svg");
  background-position: center;
  color: #fff;
  margin-bottom: 25px;
}

/* #6dc1b4 #25b2b8 */

.sidebar-header .user-image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.user-image-Qassim {
  padding: 3px;
  border: 2px solid #fff;
  border-radius: 50%;
  margin-inline-end: 20px;
}
.side-navigation-panel-select-option {
  border-left: none !important;
}
.side-navigation-panel-select-option-text {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
  margin-inline-end: 1rem !important;
  color: #000 !important;
  font-weight: 600 !important;
  font-size: 17px !important;
}

.side-navigation-panel-select-inner-option-text {
  font-size: 15px !important;
}

.backdrop_admin {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1001;
  background: rgba(0, 0, 0, 0.5);
}

.side-navigation-panel-select-wrap {
  border: 0;
  border-bottom: 1px solid #f3f2f2;
  padding: 10px 0px 0;
}

.side-navigation-panel-select-option-wrap {
  color: #025299 !important;
  font-weight: 600;
}

.side-navigation-panel-select-inner {
  background: #f8f8f8;
}

@keyframes open {
  from {
    width: 400px;
    right: -400px;
  }

  to {
    width: 400px;
    right: 0px;
  }
}

@keyframes ltr {
  from {
    width: 400px;
    left: -400px;
  }

  to {
    width: 400px;
    left: 0px;
  }
}

.side-navigation-panel-select-inner-option {
  border-bottom: 1px solid #e6e6e6;
  padding: 13px 3rem !important;
}

.sidebar nav {
  width: 100%;
}

.side-navigation-panel
  .side-navigation-panel-select
  .side-navigation-panel-select-wrap
  .side-navigation-panel-select-option:hover {
  --tw-border-opacity: 0 !important;
}

.side-navigation-panel
  .side-navigation-panel-select
  .side-navigation-panel-select-wrap
  .side-navigation-panel-select-option-selected {
  --tw-border-opacity: 0 !important;
}

.side-navigation-panel
  .side-navigation-panel-select
  .side-navigation-panel-select-inner
  .side-navigation-panel-select-inner-wrap
  .side-navigation-panel-select-inner-option:hover {
  --tw-border-opacity: 0 !important;
}

.side-navigation-panel
  .side-navigation-panel-select
  .side-navigation-panel-select-inner
  .side-navigation-panel-select-inner-wrap
  .side-navigation-panel-select-inner-option-selected {
  --tw-border-opacity: 0 !important;
}

.side-navigation-panel
  .side-navigation-panel-select
  .side-navigation-panel-select-inner
  .side-navigation-panel-select-inner-wrap
  .side-navigation-panel-select-inner-option:hover {
  border-color: none !important;
}

.side-navigation-panel
  .side-navigation-panel-select
  .side-navigation-panel-select-wrap
  .side-navigation-panel-select-option:hover {
  --tw-border-opacity: 0 !important;
}

.side-navigation-panel
  .side-navigation-panel-select
  .side-navigation-panel-select-wrap
  .side-navigation-panel-select-option-selected {
  --tw-border-opacity: 0 !important;
}

.side-navigation-panel
  .side-navigation-panel-select
  .side-navigation-panel-select-inner
  .side-navigation-panel-select-inner-wrap
  .side-navigation-panel-select-inner-option:hover {
  --tw-border-opacity: 0 !important;
}

.side-navigation-panel
  .side-navigation-panel-select
  .side-navigation-panel-select-inner
  .side-navigation-panel-select-inner-wrap
  .side-navigation-panel-select-inner-option-selected {
  --tw-border-opacity: 0 !important;
}

@media (max-width: 991px) {
  .sidebar {
    display: flex;
    position: absolute;
    right: 0;
    top: 0;
    /* height: calc(100vh - 110px); */
    background-color: #fff;
    width: 300px;
    /* box-shadow: -12px 12px 10px #ddd; */
    z-index: 3000;
    animation: open 0.3s normal linear;
    -webkit-animation: open 0.3s normal linear;
  }

  @keyframes open {
    from {
      width: 300px;
      right: -300px;
    }

    to {
      width: 300px;
      right: 0px;
    }
  }
  @keyframes ltr {
    from {
      width: 300px;
      left: -300px;
    }

    to {
      width: 300px;
      left: 0px;
    }
  }
}

.animationAr {
  transform: translateX(100%);
}
.animationEn {
  transform: translateX(-100%);
}
