.Categories {
  background-color: #fafafa;
  margin-top: 50px;
  padding-top: 30px;
}

.CategoriesCont {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  width: 100%;
}

.titleCont {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.showMoreBtn {
  position: absolute;
  inset-inline-end: 0;
  top: 0;
  padding: 10px 20px;
  border-radius: 15px;
}

.catTitle {
  font-size: 35px;
  font-weight: 600;
}
.CategoriesCont span {
  color: #979797;
}
.cardsCont {
  width: 100%;
  margin-top: 20px;
}

.cardsContMobile {
  display: none;
}

@media screen and (max-width: 992px) {
  .cardsCont {
    display: none;
  }

  .cardsContMobile {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 20px 0;
  }

  .cardCont {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
