.custom-btn {
  display: flex !important;
  padding: 7px 14px 10px;
  border-radius: 8px;
  gap: 10px;
  white-space: nowrap;
}
@media (max-width: 1024px) {
  .custom-btn {
    white-space: break-spaces;
  }
}

.read-only {
  cursor: not-allowed;
}

.lds-dual-ring {
  display: inline-block;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 6px solid #036c77;
  border-color: #036c77 #036c77 #036c77 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
