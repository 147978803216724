.terms-and-conditions {
  padding: 10%;
  max-width: 90%;
  word-break: break-word;
  margin-left: auto;
  margin-right: auto;
}
.title {
  font-size: 26px;
  font-weight: 600;
  color: #000000;
}
