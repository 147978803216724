.text-dark {
	color: #343a40 !important;
}

.program-card-clickable {
	cursor: pointer;
}

.program-card-locked {
	cursor: not-allowed;
}

a.text-dark:hover,
a.text-dark:focus {
	color: #1d2124 !important;
}

.program_card {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	background-color: #fff;
	box-shadow: 1px 1px 10px #cccccc;
	margin-top: 40px;
	border-radius: 10px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	-ms-border-radius: 10px;
	-o-border-radius: 10px;
	padding: 10px;
}

.program_card:hover {
	background-color: rgb(241, 241, 241);
}

.flex_card_course {
	display: flex;
	align-items: center;
}

.program_img {
	min-width: 150px;
	height: 150px;
	border-radius: 10px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	-ms-border-radius: 10px;
	-o-border-radius: 10px;
	background-position: center;
	background-size: cover;
}

.backdrop_img {
	width: 100%;
	background-color: rgba(95, 95, 95, 0.75);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	transition: all 0.5s;
	padding-bottom: 20px;
	padding: 20px 10px;
	text-align: initial;
	border-radius: 10px;
}

.progress {
	display: flex;
	width: 90%;
	margin: 10px auto;
	height: 4px;
}

.progress-bar {
	background-color: #18b1b9;
}

.program_title {
	margin: 0 15px;
}

.program_info {
	margin: 0 15px;
	min-width: 130px;
}

.program_font {
	font-size: 25px;
}

.card_center {
	display: flex;
	align-items: center;
}

.img_hour {
	width: 18px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: -3px 5px 0;
}

@media (max-width: 991px) {
	.program_card,
	.flex_card_course {
		flex-direction: column;
	}

	.program_title {
		margin-top: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}

	.program_info {
		margin-top: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}

	.program_font {
		font-size: 18px;
	}
}

@media only screen and (max-width: 767px) {
	.program__header-container {
		padding: 0 15px !important;
	}
}
