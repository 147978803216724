.tajah-date-restriction-row {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2rem;
}

.tajah-date-restriction-row-field {
  flex-wrap: wrap;
  display: flex;
  flex-direction: column;
}

.tajah-date-restriction-row-field-pull-end {
}

.tajah-date-restriction-row-field-picker {
  width: 35vh;
}

@media (max-width: 1120px) {
  .tajah-date-restriction-row-field-picker {
    width: 30vh;
  }
}

@media (max-width: 991px) {
  .tajah-date-restriction-row-field-picker {
    width: 25vh;
  }
}

@media (max-width: 780px) {
  .tajah-date-restriction-row-field-picker {
    width: 20vh;
  }
}
