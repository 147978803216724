.specialties-details {
	padding: 2rem 0;
}

.specialties-details__container {
	display: flex;
	flex-direction: column;
	gap: 2rem;
}

.specialties-details__table {
  padding-bottom: 2rem;
}

.specialties-details__table-name,
.specialties-details__table-desc {
	width: 300px;
	white-space: pre-wrap;
}

.specialties-details__preview {
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
	position: relative;
  padding-bottom: 1.5rem;
  border-bottom: 0.5px dashed #ededed;
}
/* .specialties-details__preview::after {
	content: "";
	position: absolute;
	bottom: -20px;
	background-color: #ededed;
	width: 100%;
	height: 0.5px;
} */

.specialties-details__preview-title {
	color: #046c77;
	font-size: 24px;
	font-weight: bold;
}

.specialties-details__preview-desc {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  color: #333333;
}
.specialties-details__preview-desc-text {
	color: #7b7b7b;
	font-size: 18px;
}
