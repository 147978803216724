.container-fluid {
  max-width: 1280px;
  padding: 0 20px;
}

.training_first_label {
  display: flex;
  font-size: 16px;
  padding: 30px 0 15px 0;
}

.training_youtube_title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 10px;
}

.training_second_label {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  padding: 15px 0 30px 0;
}

.trainer_head_social {
  font-size: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media (max-width: 991px) {
  .training_second_label {
    font-size: 14px;
    flex-direction: column;
  }
}

@media (max-width: 767px) {
  .trainer_head_social {
    margin: 0px 0 5px;
  }
}

@media (max-width: 768px) {
  .container-fluid {
    padding: 0 15px;
  }
}
