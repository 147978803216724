.training-course-syllabus-section-container-row-lesson-header {
  height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
  border-bottom-color: #cfcfcf;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: row;
  cursor: pointer;
}

.training-course-syllabus-section-container-row:last-child
  .training-course-syllabus-section-container-row-lesson:last-child
  .training-course-syllabus-section-container-row-lesson-header.closed:first-child {
  border-radius: 13px;
  border-bottom-width: 0px;
}

.training-course-syllabus-section-container-row-lesson-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
  border-bottom-color: #e7e7e7;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  padding-left: 40px;
  padding-right: 20px;
  flex-direction: row;
  transition: height 0.2s ease, opacity 0.2s ease;
}

body[dir="rtl"] .training-course-syllabus-section-container-row-lesson-content {
  padding-left: 20px;
  padding-right: 40px;
}

.training-course-syllabus-section-container-row-lesson-content.closed {
  height: 0px;
  opacity: 0;
}

.training-course-syllabus-section-container-row-lesson-content.opened {
  height: 50px;
  opacity: 1;
}

.training-course-syllabus-section-container-row-lesson-icon {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.training-course-lesson-icons {
  margin-left: 5px;
  margin-right: 5px;
}

.course-check-mark-circle {
  color: green;
  cursor: pointer;
}

.course-check-mark-circle-default {
  color: #e7e7e7;
}

.course-users-group {
  color: #10b0b9;
  cursor: pointer;
}

.course-play-content {
  color: #10b0b9;
  cursor: pointer;
}

.course-play-content-default {
  color: #e7e7e7;
}
