.popup-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.admin_add_button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 130px;
  padding: 10px;
  background-color: #006d77;
  border-radius: 15px;
  color: #fff;
  font-size: 16px;
  border: none;
  outline: none;
  margin-top: 10px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  cursor: pointer;
}

.admin_add_button:disabled {
  background-color: rgba(0, 53, 58, 0.6);
}

.admin_add_button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 130px;
  padding: 10px;
  background-color: #006d77;
  border-radius: 15px;
  color: #fff;
  font-size: 16px;
  border: none;
  outline: none;
  margin-top: 10px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  cursor: pointer;
}

.admin_add_button:disabled {
  background-color: rgba(0, 53, 58, 0.6);
}
