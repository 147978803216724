.tajah-modal-background-tint {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  transition: opacity 0.5s;
  display: none;
  opacity: 0;
  overflow-y: scroll;
}
