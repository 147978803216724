.overflowY {
  overflow-y: scroll;
}

.header-label-cost {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cost {
  display: flex;
  padding: 5px 18px;
  border-radius: 5px;
  background-color: rgb(4, 108, 119);
  color: rgb(255, 255, 255);
  align-items: center;
  justify-content: center;
}

.cost-label {
  display: flex;
  align-items: center;
  gap: 10px;
  color: rgb(4, 108, 119);
}

.payment-way {
  font-size: 14px;
  font-weight: bold;
  color: #777777;
  margin-top: 50px;
  margin-bottom: 15px;
}

.payment-way-wallet {
  font-size: 14px;
  font-weight: bold;
  color: #777777;
  margin-bottom: 15px;
}

.payment-type {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-height: 1px;
  display: flex;
  gap: 25px;
  /* justify-content: space-between; */
  height: auto;
}

.modalContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  max-width: 450px;
  height: auto;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 24px;
  padding: 40px 20px;
}

.confirmPaymentTxt {
  text-align: center;
  font-size: 24px;
  margin-top: 30px;
  margin-bottom: 30px;
  color: #555555;
  font-weight: 400;
}

.buttonsRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 30px;
}

.yesBtn {
  background-color: #24b3b9;
  color: #ffffff;
  border-radius: 6px;
  border-color: #ebebeb;
  width: 130px;
  padding: 12px 0;
}

.noBtn {
  background-color: #cfcfcf;
  color: #858585;
  border-radius: 6px;
  border-color: #ebebeb;
  width: 130px;
  padding: 12px 0;
}

@media (max-width: 767px) {
  .header-label-cost {
    flex-direction: column;
  }

  .cost {
    padding: 5px;
    font-size: 14px;
    margin-bottom: 0;
  }

  .cost-label span {
    font-size: 14px;
  }

  .payment-type {
    gap: 8px;
  }

  .payment-way {
    margin-top: 20px;
  }

  .modalContainer {
    /* width: 80%; */
    /* padding: 20px 10px; */
  }

  .confirmPaymentTxt {
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .buttonsRow {
    gap: 15px;
  }

  .yesBtn {
    width: 80px;
    padding: 8px 0;
  }

  .noBtn {
    width: 80px;
    padding: 8px 0;
  }
}
