
  .survey-card-container{
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 5px 5px 5px #CFCFCF;
    margin-top:20px;
    background-color: #F8F8F8;
    margin-bottom: 3%;
  }

  .survey-card-title{
    font-size:17;
    color:black;
    font-weight: bold;
  }

  .label-container{
    display: flex;
    flex-direction: row;
  }


  .label-name  {
    margin-right: 10px;
    margin-left: 10px;
    max-width: 100%;
    word-wrap: break-word;
  }

  .title-answers{
    text-align: center;
    padding-bottom:2%;
    padding-top:2%;
    text-decoration: underline;
    
  }

  