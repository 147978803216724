.breadcrumbs {
  padding-bottom: 30px;
}

.chat_list {
  padding: 2rem 0;
}

.chat_list__container {
  display: flex;
  flex-direction: column;
  /* gap: 2rem; */
}

.chat_list__actions {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.chat_list__actions-btn {
  background: none;
  border: none;
  padding: 0;
}

.chat_list__actions-btn[disabled] {
  opacity: 0.3;
  cursor: not-allowed;
}

.img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.chat-list__table-name {
  width: 150px;
  white-space: pre-wrap;
}

.m-50 {
  margin: 50px 0;
}

.days {
  width: 60%;
}

.hour {
  width: 50%;
}

.hours {
  width: 100%;
  gap: 15px;
}

.container-hours {
  /* width: 30%; */
}

.selected-time {
  width: 40%;
  background: #eee;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  border-radius: 4px;
  margin: 20px 0;
}

.icon {
  width: 50px !important;
  align-items: center;
  justify-content: center;
  height: 50px;
}

.icon-m {
  margin-top: 2px;
}

.modal-button {
  display: flex !important;
  justify-content: end !important;
}

.max-h-30vh {
  max-height: 30vh;
}

.time-input {
  align-items: center;
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: 50px;
  outline: 0 !important;
  position: relative;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  padding: 0 20px;
  width: 120px;
}

.clock {
  position: absolute;
  /* width: 100%; */
  /* display: flex; */
  /* justify-content: end; */
  /* align-items: end; */
  z-index: 1;
  top: 16px;
  right: 10px;
  left: unset;
  margin: 0 -2px;
}

body[dir="rtl"] .clock {
  position: absolute;
  z-index: 1;
  top: 16px;
  left: 10px;
  right: unset;
  margin: 0 -2px;
}

.mx-20 {
  /* margin: 0 20px; */
}

.radioOption {
  display: flex;
}

.timeform {
  display: flex;
  width: 100%;
  align-items: end;
  gap: 15px;
}

@media (max-width: 991px) {
  .selected-time {
    width: 50%;
  }

  .timeform {
    flex-direction: column;
  }

  .days {
    width: 100%;
  }

  .hour,
  .container-hours,
  .time-input,
  .hours {
    width: 100%;
  }

  .mx-20 {
    margin: 0;
  }
}

@media (max-width: 767px) {
  .radioOption {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

  .selected-time {
    width: 100%;
  }
}
