.admin_label {
    font: 18px;
    display: flex;
    margin: 30px 0;
}

.admin_card {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  padding: 50px 0;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 2px 2px 10px #ddd;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.overview_button_div {
    display: flex; 
    width: 100%;
}
.overview_button {
    margin-top: 20px;
    background-color: #006d77;
    color: #fff;
    padding: 8px 0px;
    border: none;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  figure img {
    width: 500px;
  }

@media (max-width: 991px) {
    .admin_label,
  .important_actions,
  .text_num_b {
    font-size: 16px !important;
  }
}
@media (max-width: 768px) {
    .admin_label,
    .important_actions,
    .text_num_b {
      font-size: 14px !important;
    }
    .overview_button {
        width: 100%;
    }
}