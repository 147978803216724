.add-new-item__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 4rem 0;
}

.add-new-item__container--active {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
  margin: 2rem 0;
  border: 1px solid #d9d9d9;
  /* background-color: #fdfdfd; */
  border-radius: 0.4rem;
}

.add-new-item__container__content {
  position: relative;
}

.add-new-item__container__content__hr {
  border-style: dashed;
}

.add-new-item__container__content__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  /* background-color: white !important; */
  padding: 0 1rem;
  color: #24b3b9;
}

.add-new-item__container--active .add-new-item__container__content__btn {
  /* background-color: #fdfdfd !important; */
}

.add-new-item__container__content__btn__icon {
  height: 1.5rem;
  width: 1.5rem;
}

.add-new-item__container__content__actions {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 2rem;
}

.add-new-item__container__content__actions__btn {
  border: 1px solid #24b3b9;
  border-radius: 0.4rem;
  padding: 1rem 4rem;
  color: #24b3b9;
  font-weight: 600;
  font-size: 1.2rem;
}

@media (max-width: 768px) {
  .add-new-item__container {
    margin: 2rem 0;
  }

  .add-new-item__container--active {
    margin: 1rem 0;
  }

  .add-new-item__container__content__btn {
    padding: 0 0.5rem;
    width: 100%;
    gap: 1rem;
  }

  .add-new-item__container__content__btn__icon {
    height: 1rem;
    width: 1rem;
  }

  .add-new-item__container__content__actions__btn {
    padding: 1rem 2rem;
    font-size: 1rem;
  }
}
