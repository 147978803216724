.trainer_edit textarea {
  width: 100%;
  height: 130px;
  margin: 20px 0;
}

.head_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;
}
.overview_add {
  display: flex;
  flex-direction: column;
}

.overview_add_top {
  font-size: 30px;
}
.overview_add_bottom {
  font-size: 15px;
  color: #474747;
}
.overview_add_bottom {
  font-size: 16px;
}

.overview_list {
  font-size: 18px;
  max-width: 100%;
  word-break: break-word;
}

.overview_list ul {
  padding-inline-start: 18px;
}

.overview_button_div_edit_aboutme {
  display: flex;
  /* width: 30%; */
  width: 100%;
  flex-direction: row-reverse;
  gap: 20px;
}

.overview_button {
  margin-top: 20px;
  background-color: #006d77;
  color: #fff;
  padding: 8px 0px;
  border: none;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  width: 25%;
}

.soical_edit {
  display: flex;
  align-items: center;
  width: 100%;
}

.profile_soical_img {
  width: 30px;
  padding: 0 5px;
}

.edit_trainer_input {
  display: flex;
  background-color: transparent;
  color: #000;
  -webkit-backdrop-filter: blur(100px);
  backdrop-filter: blur(100px);
  position: relative;
  /* padding: 0 15px; */
  border: none;
  border-bottom: 1px solid #ccc;
  width: 50%;
  height: 50px;
}

.form-input-error-space {
  height: 18px;
  font-size: 12px;
  color: red;
}

.inputfile {
  /* visibility: hidden etc. wont work */
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.inputfile:focus + label {
  /* keyboard navigation */
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}

.inputfile + label * {
  pointer-events: none;
}

.labelFile {
  padding: 1% 3% 1% 3%;
  background-color: white;
  border: 2px solid #006d77;
  border-radius: 10px;
  color: #006d77;
  font-weight: bold;
  font-size: 15px;
  cursor: pointer;
}

.container_buttons_edit_aboutme {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

@media (max-width: 1199px) {
  .overview_add_top {
    font-size: 25px;
  }

  .overview_list {
    font-size: 16px;
    max-width: 100%;
    word-break: break-word;
  }
}

@media (max-width: 991px) {
  .overview_add_top {
    font-size: 18px;
  }
  .overview_add_bottom {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .overview_add_top {
    font-size: 16px;
  }
  .overview_add_bottom {
    font-size: 12px;
  }
  .overview_list {
    font-size: 14px;
    max-width: 100%;
    word-break: break-word;
  }
  .overview_button {
    width: 100%;
  }
  .edit_trainer_input {
    width: 100%;
  }
}
