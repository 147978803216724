/* .tajah-draggable-item {
  position: relative;
  display: flex;
  flex-direction: row;
  max-height: 70px;
  padding: 15px;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0px 0px 1px #ddd;
  background-color: #ffffff;
}

.tajah-draggable-item-additives {
  width: 100%;
  justify-content: space-between !important;
  background-color: #006d77 !important;
}

.tajah-draggable-item-additives-center {
  width: 100%;
  justify-content: center !important;
  background-color: #006d77 !important;
}

.tajah-accordion-holder-drag-mask-block {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 0;
  display: block;
  left: 0;
  right: 0;
}

.tajah-accordion-holder-drag-mask-block.tajah-drag-over {
  background-color: rgba(199, 75, 80, 0.5);
}

.tajah-accordion-holder-drag-mask {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  left: 0;
  right: 0;
  flex-direction: column;
}

.tajah-accordion-holder-drag-mask-upper {
  flex: 1;
}

.tajah-accordion-holder-drag-mask-lower {
  flex: 1;
}

.tajah-accordion-holder-drag-mask-upper.tajah-drag-over {
  background-color: #d39e00;
  opacity: 0.7;
}

.tajah-accordion-holder-drag-mask-lower.tajah-drag-over {
  background-color: #d39e00;
  opacity: 0.7;
} */

.tajah-accordion-holder-drag-mask {
  position: absolute;
  width: 100%;
  height: 100%;
  top:0;
  background-color: rgba(0,0,0,0.5);
  z-index: 0;
  display: none;
  left: 0;
  right:0;
  flex-direction: column;
}

.tajah-accordion-holder-drag-mask-upper {
  flex: 1;
}

.tajah-accordion-holder-drag-mask-lower {
  flex: 1;
}

.tajah-accordion-holder-drag-mask-upper.tajah-drag-over {
  border-top: #d39e00 3px dashed;
}

.tajah-accordion-holder-drag-mask-lower.tajah-drag-over {
  border-bottom: #d39e00 3px dashed;
}

.tajah-accordion-holder-drag-mask-block {
  position: absolute;
  width: 100%;
  height: 100%;
  top:0;
  background-color: rgba(0,0,0,0.5);
  z-index: 0;
  display: block;
  left: 0;
  right:0;
}

.tajah-accordion-holder-drag-mask-block.tajah-drag-over {
  background-color: rgba(199, 75, 80,0.5);
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded {
  margin: 16px 0 !important;
}
