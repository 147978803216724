.training_btn {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.training_inside_btn {
    width: 100%;
    height: 50px;
    font-size: 18px;
    background-color: #006d77;
    color: #fff;
    border-radius: 15px;
    outline: none;
    box-shadow: none;
    overflow: hidden;
    border: none;
}

@media (max-width: 767px) {
    .training_inside_btn {
        font-size: 14px;
    }
}
