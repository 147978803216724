.registered_panel_wrapper {
    flex:2;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.registered_panel {
    width:95%;
    height: 100%;
    background-color: #FFFFFF;
    box-shadow: 0px 5px 10px #0000000A;
    border-radius: 10px;
}

.registered_panel_header {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: row;
}

.registered_panel_header_label {
    flex:1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.registered_panel_header_label_report {
    margin-left: 10px;
    color: #3B86FF;
    font-size: 12px;
    margin-bottom: 0 !important;
    cursor: pointer;
}

body[dir='rtl'] .registered_panel_header_label_report {
    margin-left: 0;
    margin-right: 10px;
}

.registered_panel_header_action {
    flex: 2;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.registered_panel_body {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 20px;
}

.registered_panel_body_list {
    flex: 1
}

.registered_panel_body_list_item {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 40px;
    margin-bottom: 10px;
}

.registered_panel_body_list_item_data {
    flex: 1;
    display: flex;
    flex-direction: row;
}

.registered_panel_body_list_item_data_label {
    flex: 2;
}

.registered_panel_body_list_item_data_count {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.registered_panel_body_list_item_progress {
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.registered_panel_body_list_item_progress_bar {
    width: 100%;
    border-radius: 5px;
    background-color: #F7F7F7;
    height: 10px;
}

.registered_panel_body_list_item_progress_bar_filling {
    position: absolute;
    border-radius: 5px;
    height: 10px;
    max-width: 100%;
}

.registered_panel_body_list_summary {
    display: flex;
    flex-direction: row;
}

.registered_panel_body_list_summary_count {
    margin-left: 10px;
}

body[dir='rtl'] .registered_panel_body_list_summary_count {
    margin-left: 0;
    margin-right: 10px;
}