.create_tax__form__field-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  margin-bottom: 20px;
}

.create_tax__form__field-actions button {
  background-color: #046c77;
  border-radius: 6px;
  border: none;
  padding: 0 1rem;
  height: 50px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.create_tax__form__field-actions-submit {
  width: 200px;
}
.create_tax__form__field-actions-submit[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}
